import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import * as S from './ProductsForStocks.styles';
import { ReactComponent as FactoryIcon } from '@assets/icons/mobile/factory.svg';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import SearchConditionList from '@components/modules/common/mobile/SearchConditionList/SearchConditionList';
import ProductList from '@components/modules/mobile/products_for_stocks/ProductList/ProductList';
import SelectedTagList from '@components/modules/common/mobile/SelectedTagList/SelectedTagList';
import useTags from '@lib/mobile/common/hooks/useTags';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import TagsForStocksModal from '@components/modules/mobile/tags_for_stocks_modal/TagsForStocksModal/TagsForStocksModal';
import StoreroomsForStocksModal from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomsForStocksModal/StoreroomsForStocksModal';
import FloatingCameraButton from '@components/modules/common/mobile/FloatingCameraButton/FloatingCameraButton';
import {
  MATERIAL_PRODUCT_KIND,
  PRODUCT_KIND,
  type Product,
  type SearchState,
} from '@lib/common/type';
import { useWindowScroll } from 'react-use';
import type { Storeroom, Todo } from '@lib/common/type';
import BarcodeReader from '../RealtimeScanner/BarcodeReader';
import scanSound from '@assets/sounds/sound.mp3';
import moment from 'moment';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import InfiniteLoader from 'react-window-infinite-loader';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import useSWR, { useSWRConfig } from 'swr';
import humps from 'humps';
import { CircularProgress } from '@material-ui/core';
import { useSelectedStoreroom } from '@lib/common/hooks/useSelectedStoreroom';
import { useSearchChar } from '@lib/common/hooks/useSearchChar';
import { useMetaPagination } from '@lib/common/hooks/useMetaPagination';
import { useSelectedTagIds } from '@lib/common/hooks/useSelectedTagIds';

export const ProductsForStocksRoot = () => {
  const history = useHistory();
  const { selectedTagIds, setSelectedTagIds } = useSelectedTagIds();
  const {
    selectedStoreroomId,
    setSelectedStoreroomId,
    selectedStoreroomName,
    setSelectedStoreroomName,
  } = useSelectedStoreroom();
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [audio] = useState(new Audio(scanSound));
  // 在庫室検索文字列
  const [searchStoreroom, setSearchStoreroom] = useState('');
  const [filteredStoreroom, setFilteredStoreroom] = useState<Storeroom[]>([]);
  const { state } = useLocation<SearchState>();
  const { data: tags } = useTags(
    MATERIAL_PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT
  );
  const { data: storerooms } = useStorerooms(
    MATERIAL_PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT
  );
  const { data: products } = useProducts();

  // タグmodal画面open用
  const [tagsOpen, setTagsOpen] = useState(false);
  // 在庫室modal画面open用
  const [storeroomOpen, setStoreroomOpen] = useState(false);

  // 検索文字state
  const { searchChar, setSearchChar } = useSearchChar();
  // スクロール位置state
  const { y: currentScrollPosition } = useWindowScroll();
  const [stateProductsData] = useState<Product[]>([]);
  const [stateStocksData] = useState<Product[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  // 検索➜在庫入力➜検索結果状態を取得する

  const searchState = {
    stateSearchChar: searchChar,
    stateSelectedTagIds: selectedTagIds,
    stateSelectedStoreroom: selectedStoreroomName,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateScrollPosition: currentScrollPosition,
    stateProductsData: stateProductsData,
    stateStocksData: stateStocksData,
  };

  const {
    metaCurrentPage,
    setMetaCurrentPage,
    metaTotalPages,
    setMetaTotalPages,
  } = useMetaPagination();

  useEffect(() => {
    let combinedDataFromStocks: Todo[] = [];
    if (selectedStoreroomId && products) {
      const filteredFromStocks = products.filter((product) =>
        product.storeroomIds.includes(selectedStoreroomId)
      );
      combinedDataFromStocks = [...filteredFromStocks];
    }
    if (results.length !== 0) {
      const getData = [
        ...searchState.stateProductsData,
        ...combinedDataFromStocks,
      ].filter(
        (product: Todo) =>
          (product.janCode === results[0]?.codeResult?.code &&
            product.storeroomIds.includes(Number(selectedStoreroomId))) ||
          (product.productCode === results[0].codeResult.code &&
            product.storeroomIds.includes(Number(selectedStoreroomId)))
      );
      if (
        !!getData &&
        getData.length > 0 &&
        getData[0].productKind === PRODUCT_KIND.PRODUCT
      ) {
        // 外部バーコード読取時のみ、localStorageへtrueをSet
        localStorage.setItem('useScanning', 'true');
        history.push(
          `/mobile/products/${getData[0].id}/stocks/${
            PRODUCT_KIND.PRODUCT
          }/${moment().format('YYYY-MM-DD')}/${selectedStoreroomId}/edit`,
          searchState
        );
      } else if (
        !!getData &&
        getData.length > 0 &&
        getData[0].productKind === PRODUCT_KIND.SEMI_PRODUCT
      ) {
        history.push(
          `/mobile/products/${getData[0].id}/stocks/${
            PRODUCT_KIND.SEMI_PRODUCT
          }/${moment().format('YYYY-MM-DD')}/${selectedStoreroomId}/edit`,
          searchState
        );
      } else {
        // 該当のjanCodeがなかった場合
        setErrMsg('該当の商品が見つかりませんでした。');
      }
    }
  }, [results]);

  const selectedTags =
    tags?.filter((tag) => selectedTagIds.includes(tag.id)) || [];

  // 場所検索機能
  useEffect(() => {
    if (!storerooms) return;
    if (searchStoreroom) {
      setFilteredStoreroom(
        storerooms.filter((sr) => sr.name.match(searchStoreroom))
      );
    } else {
      setFilteredStoreroom(storerooms);
    }
  }, [searchStoreroom, storerooms]);

  // 初期の在庫室選択画面表示時、localStorageからuseScanning削除
  useEffect(() => {
    if (state || selectedStoreroomId) return;
    localStorage.removeItem('useScanning');
  }, [state]);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { mutate } = useSWRConfig();
  const { data: filteredProducts } = useSWR('filteredProducts');
  const isInitialLoadComplete = useRef(false);

  const fetchData = useCallback(
    async (currentPage: number) => {
      try {
        const response = await axiosInstance().get(`/api/v1/stocks`, {
          params: {
            date: moment().format('YYYYMMDD'),
            storeroom_id: selectedStoreroomId,
            page: currentPage + 1,
            search: searchChar,
            tag_ids: selectedTags.map((item: Todo) => item['id']),
            per_page: 30,
          },
        });
        const newItems = humps.camelizeKeys(response?.data?.products);
        setMetaCurrentPage(response?.data?.meta.current_page);
        setMetaTotalPages(response?.data?.meta.total_pages);

        mutate('filteredProducts', newItems, false);

        return newItems;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [searchChar, selectedTagIds, selectedStoreroomId]
  );

  const loadMoreItems = useCallback(async () => {
    if (isNextPageLoading || !hasNextPage) return;

    setIsNextPageLoading(true);
    if (metaCurrentPage < metaTotalPages) {
      const newProducts = await fetchData(metaCurrentPage);

      if (!newProducts?.length) {
        setHasNextPage(false);
      } else {
        mutate(
          'filteredProducts',
          [...(filteredProducts || []), ...newProducts],
          false
        );
      }
    }
    setIsNextPageLoading(false);
  }, [
    hasNextPage,
    isNextPageLoading,
    metaCurrentPage,
    metaTotalPages,
    filteredProducts,
    fetchData,
  ]);

  const isItemLoaded = (index: number) =>
    index < (filteredProducts || []).length;

  const clearAll = () => {
    setHasNextPage(true);
    setIsNextPageLoading(false);
    mutate('filteredProducts', [], false);
  };

  const fetchFilteredProducts = async () => {
    fetchData(0);
  };

  const prevStoreroomId = useRef(selectedStoreroomId);
  const prevTagIds = useRef(selectedTagIds);
  const prevSearchChar = useRef(searchChar);

  useEffect(() => {
    // 初回マウント時は前回の値と比較
    if (prevStoreroomId.current === selectedStoreroomId) {
      return;
    }

    clearAll();

    if (selectedStoreroomId) {
      fetchFilteredProducts();
      setTimeout(() => {
        isInitialLoadComplete.current = true;
      }, 1500);
    }

    prevStoreroomId.current = selectedStoreroomId;
  }, [selectedStoreroomId]);

  useEffect(() => {
    const areArraysEqual =
      prevTagIds.current?.length === selectedTagIds.length &&
      prevTagIds.current?.every((id, index) => id === selectedTagIds[index]);

    if (areArraysEqual) {
      return;
    }

    setIsLoading(true);
    clearAll();
    fetchFilteredProducts();
    prevTagIds.current = selectedTagIds;
  }, [selectedTagIds]);

  useEffect(() => {
    if (prevSearchChar.current === searchChar) {
      return;
    }

    setIsLoading(true);
    clearAll();
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      fetchFilteredProducts();
    }, 2000);
    prevSearchChar.current = searchChar;
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchChar]);

  const { data: scrollPositionData, mutate: mutateScrollPosition } = useSWR(
    'scrollPosition',
    null,
    {
      fallbackData: 0,
    }
  );

  // StyledFixedSizeListのonItemsRenderedを更新
  const handleItemsRendered = useCallback(
    (
      props: ListOnItemsRenderedProps,
      onItemsRendered: (props: ListOnItemsRenderedProps) => void
    ) => {
      const currentPosition = props.visibleStartIndex * 60;
      mutateScrollPosition(currentPosition, false);
      onItemsRendered(props);
    },
    [mutateScrollPosition]
  );

  // コンポーネントマウント時にスクロール位置を復元
  useEffect(() => {
    if (scrollPositionData && filteredProducts?.length > 0) {
      const listElement = document.querySelector('.StyledFixedSizeList');
      if (listElement) {
        listElement.scrollTo({
          top: scrollPositionData,
          behavior: 'instant',
        });
      }
    }
  }, []);

  const [listHeight, setListHeight] = useState(window.innerHeight - 240);

  useEffect(() => {
    const updateHeight = () => {
      setListHeight(window.innerHeight - 240);
    };

    // 初期設定
    updateHeight();

    // リサイズイベントの購読
    window.addEventListener('resize', updateHeight);

    // クリーンアップ
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  if (tagsOpen) {
    return (
      <TagsForStocksModal
        setTagsOpen={setTagsOpen}
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
        fromComponent={'productsForStocks'}
      />
    );
  }

  if (storeroomOpen) {
    return (
      <StoreroomsForStocksModal
        searchStoreroom={searchStoreroom}
        setSearchStoreroom={setSearchStoreroom}
        storerooms={filteredStoreroom}
        setStoreroomOpen={setStoreroomOpen}
        setSelectedStoreroomId={setSelectedStoreroomId}
        setSelectedStoreroomName={setSelectedStoreroomName}
      />
    );
  }

  return (
    <>
      <S.FixedHeader>
        <WithHeader />
        <S.Title>
          <S.TitleText>在庫の入力</S.TitleText>
          <S.StoreroomPanel onClick={() => setStoreroomOpen(true)}>
            <FactoryIcon />
            {selectedStoreroomName ? (
              <S.StorageText>{selectedStoreroomName}</S.StorageText>
            ) : (
              <S.StorageText>場所を選択</S.StorageText>
            )}
            <S.ExpandMore>
              <ExpandMore />
            </S.ExpandMore>
          </S.StoreroomPanel>
        </S.Title>
        {selectedStoreroomId && (
          <S.SearchConditionList>
            {selectedTags.length === 0 ? (
              <SearchConditionList
                setTagsOpen={setTagsOpen}
                searchChar={searchChar}
                setSearchChar={setSearchChar}
              />
            ) : (
              <SelectedTagList
                tags={selectedTags}
                clearAll={() => setSelectedTagIds([])}
                clear={(tagId) =>
                  setSelectedTagIds(selectedTagIds.filter((id) => id !== tagId))
                }
                tagsOpen={tagsOpen}
                setTagsOpen={setTagsOpen}
                clearTagSearch={() => {
                  setSelectedTagIds([]);
                }}
              />
            )}
          </S.SearchConditionList>
        )}
      </S.FixedHeader>
      <S.ProductList id="product-list" hasStoreroom={!!selectedStoreroomId}>
        {filteredProducts === undefined ? (
          <S.SelectStoreroomText>
            場所を選択してください。
          </S.SelectStoreroomText>
        ) : !isLoading &&
          (searchChar !== '' || selectedTagIds.length > 0) &&
          filteredProducts.length === 0 ? (
          <S.NoProductsToShow>
            製品が見つかりませんでした。
            <div>条件を変更して再度検索してください。</div>
          </S.NoProductsToShow>
        ) : filteredProducts?.length === 0 && selectedStoreroomId ? (
          <S.SelectStoreroomText>
            <S.CenteredCircularProgress>
              <CircularProgress />
            </S.CenteredCircularProgress>
          </S.SelectStoreroomText>
        ) : (
          <>
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={
                hasNextPage
                  ? (filteredProducts?.length || 0) + 1
                  : filteredProducts?.length || 0
              }
              loadMoreItems={loadMoreItems}
              threshold={15}
            >
              {({ onItemsRendered, ref }) => (
                <S.StyledFixedSizeList
                  height={listHeight}
                  itemCount={filteredProducts?.length || 0}
                  itemSize={60}
                  width="100%"
                  onItemsRendered={(props) =>
                    handleItemsRendered(props, onItemsRendered)
                  }
                  ref={ref}
                  className="StyledFixedSizeList"
                >
                  {({ index, style }) => (
                    <div style={style}>
                      <ProductList
                        products={[filteredProducts[index]]}
                        searchChar={searchChar}
                        selectedTagIds={selectedTagIds}
                        selectedStoreroom={selectedStoreroomName}
                        selectedStoreroomId={selectedStoreroomId}
                        scrollPosition={currentScrollPosition}
                      />
                    </div>
                  )}
                </S.StyledFixedSizeList>
              )}
            </InfiniteLoader>
            {scanning ? (
              <BarcodeReader
                onDetected={(result: Todo) => {
                  setScanning(false);
                  audio.play();
                  const newResult = {
                    codeResult: { code: result.codeResult.code },
                    inputText,
                  };
                  setResults([...results, newResult]);
                }}
              />
            ) : (
              <ErrorMsgPopUp
                fromPc={true}
                errMsg={errMsg}
                handleClose={() => {
                  setResults([]);
                  setScanning(true);
                  setErrMsg('');
                }}
              />
            )}
          </>
        )}
      </S.ProductList>
      {selectedStoreroomId && (
        <FloatingCameraButton
          handleClick={() =>
            history.push(`/mobile/live/${selectedStoreroomId}`, searchState)
          }
        />
      )}
    </>
  );
};
export default ProductsForStocksRoot;

import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useFactoryLine = (id: string | number) => {
  const { data, refetch } = useQuery('selected-factory-line', async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/factory_lines/${id}`
    );
    return data;
  });
  const factoryLine = humps.camelizeKeys(
    data?.data?.factory_line
  ) as unknown as Todo;
  return { factoryLine, refetch };
};

export default useFactoryLine;

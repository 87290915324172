import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 80%;
  height: 570px;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  z-index: 9999;
  padding: 24px 16px;
  background-color: #f3ede6;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  vertical-align: middle;
`;

export const StoreroomsName = styled.div`
  height: 40px;
  display: grid;
  place-items: center;
`;

export const ModalHeadCloseIcon = styled.div`
  cursor: pointer;
  padding: 8px;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const BlueBox = styled.span`
  height: 40px;
  display: grid;
  place-items: center;
  margin-right: 40px;
  width: 80px;
  height: 40px;
  background: #2ba9d1;
  color: #fff;
  font-size: 19px;
  border-radius: 2px;
  text-align: center;
`;

export const WhiteBox = styled.span`
  height: 40px;
  display: grid;
  place-items: center;
  margin-right: 40px;
  width: 80px;
  height: 40px;
  background: #fff;
  color: #2ba9d1;
  font-size: 19px;
  margin-right: 16px;
  border: 1px solid #2ba9d1;
  border-radius: 2px;
  text-align: center;
`;

export const StocksRow = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  width: 100%;
  padding: 16px 9px;
  margin-bottom: 2px;
`;

export const StockNamesWrapper = styled.div`
  height: 450px;
  overflow-y: scroll;
`;

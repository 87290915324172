import * as S from './SelectedTagList.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/mobile/close.svg';
import { ReactComponent as CircleCloseBlueIcon } from '@assets/icons/mobile/circle_close_blue.svg';
import { ReactComponent as PlusBlueIcon } from '@assets/icons/mobile/plus_blue.svg';
import { ReactComponent as TagBlueIcon } from '@assets/icons/mobile/tag_blue.svg';
import type { Tag } from '@lib/common/type';
import { useSelectedTagIds } from '@lib/common/hooks/useSelectedTagIds';

type Props = {
  tags: Tag[];
  setTagsOpen: (tagsOpen: boolean) => void;
  clearTagSearch: () => void;
};

const SelectedTagList = ({
  tags,
  setTagsOpen,
  clearTagSearch,
}: Omit<Props, 'clearAll' | 'clear'>) => {
  const { selectedTagIds, setSelectedTagIds } = useSelectedTagIds();

  const handleClose = () => {
    setSelectedTagIds([]);
    clearTagSearch();
  };

  const handleClear = (tagId: number) => {
    setSelectedTagIds(selectedTagIds.filter((id) => id !== tagId));
  };

  return (
    <>
      <S.ClearButton onClick={handleClose}>
        <CloseIcon />
      </S.ClearButton>
      {tags.map((tag) => (
        <S.TagItem key={tag.id}>
          <S.ListItemInner>
            <S.TagDeleteButton onClick={() => handleClear(tag.id)}>
              <CircleCloseBlueIcon />
            </S.TagDeleteButton>
            <S.TagName>{tag.name}</S.TagName>
          </S.ListItemInner>
        </S.TagItem>
      ))}
      <S.AddTagLink onClick={() => setTagsOpen(true)}>
        <S.ListItemInner isAddTag>
          <S.PlusIcon>
            <PlusBlueIcon />
          </S.PlusIcon>
          <S.TagIcon>
            <TagBlueIcon />
          </S.TagIcon>
        </S.ListItemInner>
      </S.AddTagLink>
    </>
  );
};
export default SelectedTagList;

import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import * as S from './ProductsStocksEdit.styles';
import useStocksForOneProduct from '@lib/mobile/products_stocks_edit/useStocksForOneProduct';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import StockFormWrapper from '@components/modules/mobile/products_stocks_edit/StockFormWrapper/StockFormWrapper';

export const ProductsStocksEditRoot = () => {
  const {
    id,
    stock_kind,
    storeroom_id,
    date: dateStr,
  } = useParams<{
    id: string;
    stock_kind: string;
    date: string;
    storeroom_id: string;
  }>();
  const [date] = useState(new Date(dateStr));
  const {
    stocksForOneProduct: stocks,
    refetch,
    productName,
  } = useStocksForOneProduct(id, stock_kind, storeroom_id);

  const product = {
    id: Number(id),
    name: productName ?? '',
    productKind: Number(stock_kind),
  };

  return (
    <>
      <ScrollToTop />
      <WithHeader>
        <S.Wrapper>
          <StockFormWrapper
            id={id}
            date={date}
            stock_kind={stock_kind}
            storeroom_id={storeroom_id}
            product={product}
            stocks={stocks}
            refetch={refetch}
          />
        </S.Wrapper>
      </WithHeader>
    </>
  );
};

import { FC, useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import {
  addComma,
  numberWithDecimalPlaces,
} from '@lib/pc/stock_status/functions';
import { generateDateArray } from '@lib/pc/materials_inventory/functions';
import {
  FunctionType,
  MATERIAL_TYPE,
  MATERIAL_PRODUCT_KIND,
  Todo,
} from '@lib/common/type';
import { CircularProgress } from '@material-ui/core';
import DisplaySelectModal from '@components/modules/common/DisplaySelectModal/DisplaySelectModal';
import type { selectedItem } from '@lib/pc/materials_inventory/type';
import { isIncludes } from '@lib/pc/production_schedule/functions';
import InfiniteLoader from 'react-window-infinite-loader';
import useSWR, { useSWRConfig } from 'swr';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import humps from 'humps';
import { FixedSizeList } from 'react-window';

type Props = {
  selectedDate: moment.Moment;
  _selected: boolean;
  handleSelectedMaterial: (d: selectedItem) => void;
  handleSelectEstimatedMaterial: (d: Todo) => void;
  handleOpenProductDetail: (item: Todo) => void;
  setTagListModalActive?: () => void;
  rightSlideSidebarActive: boolean;
  onClickCancel: () => void;
  selectedStoreroomId: number;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  _selected,
  handleSelectedMaterial,
  handleSelectEstimatedMaterial,
  handleOpenProductDetail,
  rightSlideSidebarActive,
  onClickCancel,
  selectedStoreroomId,
}: Props) => {
  const [searchWord, setSearchWord] = useState<string>('');
  const [tagMenuActive, setTagMenuActive] = useState<boolean>(false);
  const [tagListModalActive, setTagListModalActive] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  // 表示タイプの選択
  const [displaySelectModalActive, setDisplaySelectModalActive] =
    useState(false);
  const functionTypeObj = [
    { id: 0, type: '実在庫' },
    { id: 1, type: '予定在庫' },
    { id: 2, type: '納品済数' },
    { id: 3, type: '予定納品数' },
  ];
  const [selectedTypes, setSelectedTypes] =
    useState<FunctionType[]>(functionTypeObj);

  const selectedTypeIds = selectedTypes.map((item: FunctionType) => item['id']);

  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);

  // lastセルの判定に使用
  // 実在庫のlastセル判定
  const lastOfActualStock = selectedTypeIds.length === 1;
  // 予定在庫のlastセル判定
  const lastOfEstimatedStock =
    selectedTypeIds.includes(1) && selectedTypeIds.length == 2;
  // 納品済数のlastセル判定
  const lastOfActualDeliveries =
    (selectedTypeIds.includes(2) &&
      selectedTypeIds.length == 3 &&
      !isIncludes([3], selectedTypeIds)) ||
    (selectedTypeIds.includes(2) && selectedTypeIds.length == 2);
  // 予定済数のlastセル判定
  const lastOfEstimatedDeliveries =
    selectedTypeIds.length === 4 || selectedTypeIds.includes(3);

  // 表示名箇所lastセル判定
  const lastOfName = (id: number) => {
    if (id === 0 && lastOfActualStock) {
      return true;
    } else if (id === 1 && lastOfEstimatedStock) {
      return true;
    } else if (id === 2 && lastOfActualDeliveries) {
      return true;
    } else if (id === 3 && lastOfEstimatedDeliveries) {
      return true;
    } else {
      return false;
    }
  };

  const handleSearch = (e: Todo) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [metaCurrentPage, setMetaCurrentPage] = useState(0);
  const [metaTotalPages, setMetaTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { mutate } = useSWRConfig();
  const { data: filteredMaterials } = useSWR('filteredMaterials');
  const isInitialLoadComplete = useRef(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const listWrapperRef = useRef<HTMLDivElement>(null);
  const [listHeight, setListHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (listWrapperRef.current) {
        const viewportHeight = window.innerHeight;
        setListHeight(viewportHeight - 218);
      }
    };

    updateHeight();

    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const fetchData = useCallback(
    async (currentPage: number) => {
      try {
        const response = await axiosInstance().get(`/api/v1/material_stocks`, {
          params: {
            date: selectedDate.format('YYYYMMDD'),
            storeroom_id: selectedStoreroomId,
            page: currentPage + 1,
            search: searchWord,
            tag_ids: selectedTags.map((item: Todo) => item['id']),
            per_page: 30,
          },
        });
        const newItems = humps.camelizeKeys(response?.data?.material_stocks);
        setMetaCurrentPage(response?.data?.meta.current_page);
        setMetaTotalPages(response?.data?.meta.total_pages);

        mutate('filteredMaterials', newItems, false);
        return newItems;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      searchWord,
      selectedTags,
      selectedStoreroomId,
      selectedDate,
      selectedTypeIds,
    ]
  );

  const loadMoreItems = useCallback(async () => {
    if (!isInitialLoadComplete.current) return;

    if (isNextPageLoading || !hasNextPage) return;

    setIsNextPageLoading(true);
    if (metaCurrentPage < metaTotalPages) {
      const newProducts: Todo[] = (await fetchData(currentPage)) || [];

      if (newProducts?.length === 0) {
        setHasNextPage(false);
      } else {
        mutate(
          'filteredMaterials',
          [...(filteredMaterials || []), ...newProducts],
          false
        );
        setCurrentPage((prev) => prev + 1);
      }
    }

    setIsNextPageLoading(false);
  }, [
    currentPage,
    hasNextPage,
    isNextPageLoading,
    metaCurrentPage,
    metaTotalPages,
    filteredMaterials,
    fetchData,
  ]);

  const isItemLoaded = (index: number) =>
    index < (filteredMaterials || []).length;

  const clearAll = () => {
    setHasNextPage(true);
    setIsNextPageLoading(false);
    setCurrentPage(0);
    mutate('filteredMaterials', [], false);
  };

  const fetchFilteredMaterials = async () => {
    fetchData(0);
    setCurrentPage(1);
  };

  // Initial load
  useEffect(() => {
    if (isInitialLoadComplete.current) return;

    fetchFilteredMaterials();

    timerRef.current = setTimeout(() => {
      isInitialLoadComplete.current = true;
    }, 1500);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isInitialLoadComplete.current) return;

    setIsLoading(true);
    clearAll();
    fetchFilteredMaterials();
  }, [selectedTags, selectedStoreroomId, selectedDate]);

  // Handle search changes
  useEffect(() => {
    if (!isInitialLoadComplete.current) return;

    setIsLoading(true);
    clearAll();
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      fetchFilteredMaterials();
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchWord]);

  return (
    <>
      <S.ListWrapper ref={listWrapperRef}>
        <S.LabelRow>
          <S.ListLeftHeadCell
            invalid={rightSlideSidebarActive}
            onClick={() => setTagMenuActive(!tagMenuActive)}
            isBlue={searchWord !== '' || selectedTagIds.length > 0}
          >
            <span className="filter-product">
              {searchWord !== '' || selectedTagIds.length > 0 ? (
                <>
                  <FilteredIcon />
                  <div className="filter_text">資材・原材料絞込中</div>
                </>
              ) : (
                <>
                  <Filter
                    isBlue={searchWord !== '' || selectedTagIds.length > 0}
                  />
                  <div className="filter_text">資材・原材料絞込</div>
                </>
              )}
            </span>
          </S.ListLeftHeadCell>
          <S.DisplayButton
            onClick={() =>
              setDisplaySelectModalActive(!displaySelectModalActive)
            }
            isBlue={selectedTypeIds.length !== 4}
          >
            <span className="filter-product">
              {selectedTypeIds.length !== 4 ? (
                <>
                  <FilteredIcon />
                  <div className="filter_text">選択中</div>
                </>
              ) : (
                <>
                  <Filter />
                  <div className="filter_text">表示選択</div>
                </>
              )}
            </span>
          </S.DisplayButton>
          <S.ListDateRow>
            {generateDateArray(6, selectedDate).map((p, i) => {
              return (
                <S.ListHeadCell
                  key={`${i} - ${p.productionDate}`}
                  highLighten={i === 5}
                  dayOfWeek={moment(p.productionDate).format('ddd')}
                >
                  {moment(p.productionDate).format('MM/DD(ddd)')}
                </S.ListHeadCell>
              );
            })}
          </S.ListDateRow>
        </S.LabelRow>
        {!isLoading &&
        (searchWord !== '' || selectedTagIds.length > 0) &&
        filteredMaterials.length === 0 ? (
          <S.NoProductsToShow>
            資材・原材料が見つかりませんでした。
            <div>条件を変更して再度検索してください。</div>
          </S.NoProductsToShow>
        ) : filteredMaterials === undefined ||
          filteredMaterials.length === 0 ? (
          <S.CenteredCircularProgress>
            <CircularProgress />
          </S.CenteredCircularProgress>
        ) : (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={
              hasNextPage
                ? filteredMaterials.length + 1
                : filteredMaterials.length
            }
            loadMoreItems={loadMoreItems}
            threshold={15}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={listHeight}
                itemCount={filteredMaterials.length}
                itemSize={selectedTypeIds.length * 37}
                width="100%"
                onItemsRendered={onItemsRendered}
                ref={ref}
              >
                {({ index, style }) => (
                  <S.MatrixWrapper style={style}>
                    <S.ListRow>
                      <S.MaterialInfoWrapper
                        index={index}
                        variableHeight={selectedTypeIds.length * 37}
                        onClick={() =>
                          handleOpenProductDetail(filteredMaterials[index])
                        }
                      >
                        <S.ListLeftHeadName className="list-left-product-cell">
                          <S.MaterialName>
                            {filteredMaterials[index].name}
                          </S.MaterialName>
                          <S.MaterialTypeDiv
                            materialKind={filteredMaterials[index].materialType}
                          >
                            {filteredMaterials[index].materialType ===
                            MATERIAL_TYPE.ROW_MATERIAL
                              ? '原'
                              : '資'}
                          </S.MaterialTypeDiv>
                        </S.ListLeftHeadName>
                        {selectedTypeIds.length > 1 && (
                          <S.ListLeftHeadMeta>
                            <S.ListLeftMaterialCode>
                              {filteredMaterials[index].materialCode}
                            </S.ListLeftMaterialCode>
                            <div>
                              実在庫最終入力日
                              <div>
                                {filteredMaterials[index].stockLastInputAt &&
                                  moment(
                                    filteredMaterials[index].stockLastInputAt
                                  ).format('YYYY/MM/DD')}
                              </div>
                            </div>
                          </S.ListLeftHeadMeta>
                        )}
                      </S.MaterialInfoWrapper>
                      <S.ListLeftHeadTypeNames>
                        {functionTypeObj
                          .filter((value) => selectedTypeIds.includes(value.id))
                          .map((value, j) => {
                            // 表示タイプ
                            return (
                              <S.ListLeftHeadTypeName
                                key={`${index}-${j}-${value.id}`}
                                typeIndex={j}
                                productIndex={index}
                                notDisplay={
                                  !selectedTypeIds.includes(value.id)
                                    ? 'true'
                                    : ''
                                }
                                last={lastOfName(value.id)}
                              >
                                {value.type}
                              </S.ListLeftHeadTypeName>
                            );
                          })}
                      </S.ListLeftHeadTypeNames>
                    </S.ListRow>
                    <S.MainMatrix>
                      {/* 実在庫 */}
                      <S.ListRow>
                        {generateDateArray(6).map((d: Todo, idx: number) => (
                          <S.ListCellContainer
                            invalid={rightSlideSidebarActive}
                            key={idx}
                            highLighten={idx === 5}
                            withComment={
                              filteredMaterials[index].actualStocks[idx]
                                ?.comment
                            }
                            selectedStoreroomId={selectedStoreroomId !== 0}
                            rowIndex={selectedTypeIds.indexOf(0)}
                            colIndex={idx}
                            onClick={() => {
                              handleSelectedMaterial({
                                materialId: filteredMaterials[index].id,
                                materialName: filteredMaterials[index].name,
                                materialType:
                                  filteredMaterials[index].materialType,
                                type:
                                  filteredMaterials[index].materialType ===
                                  MATERIAL_TYPE.ROW_MATERIAL
                                    ? '原'
                                    : '資',
                                date: filteredMaterials[index].actualStocks[idx]
                                  .date,
                                amountDetail:
                                  filteredMaterials[index].actualStocks[idx]
                                    .amountDetail,
                                comment:
                                  filteredMaterials[index].actualStocks[idx]
                                    .comment,
                                piecesPerBox: filteredMaterials[index]
                                  .piecesPerBox
                                  ? filteredMaterials[index].piecesPerBox
                                  : 1,
                                piecesPerUnit: filteredMaterials[index]
                                  .piecesPerUnit
                                  ? filteredMaterials[index].piecesPerUnit
                                  : 1,
                                labelOfAmount:
                                  filteredMaterials[index].labelOfAmount,
                                labelOfUnit:
                                  filteredMaterials[index].labelOfUnit,
                                actualStocks:
                                  filteredMaterials[index].actualStocks,
                                estimatedStocks:
                                  filteredMaterials[index].estimatedStocks,
                                actualDeliveries:
                                  filteredMaterials[index].actualDeliveries,
                                estimatedDeliveries:
                                  filteredMaterials[index].estimatedDeliveries,
                              });
                            }}
                            last={lastOfActualStock}
                          >
                            {addComma(
                              filteredMaterials[index].actualStocks[idx]?.amount
                            )}
                          </S.ListCellContainer>
                        ))}
                      </S.ListRow>
                      {/* 予定在庫 */}
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(1) ? 'true' : ''}
                      >
                        {filteredMaterials[index].estimatedStocks.map(
                          (d: Todo, idx: number) => (
                            <S.ListCellContainer
                              key={`estimated-${index}-${idx}-${Math.random()}`}
                              highLighten={idx === 5}
                              amount={d?.amount || d?.amount === 0}
                              rowIndex={selectedTypeIds.indexOf(1)}
                              colIndex={idx}
                              onClick={() => {
                                if (d?.amount || d?.amount === 0) {
                                  handleSelectEstimatedMaterial({
                                    materialId: filteredMaterials[index].id,
                                    date: filteredMaterials[index]
                                      .estimatedStocks[idx].date,
                                  });
                                }
                              }}
                              last={lastOfEstimatedStock}
                            >
                              {numberWithDecimalPlaces(d?.amount, 2)}
                            </S.ListCellContainer>
                          )
                        )}
                      </S.ListRow>
                      {/* 納品済数 */}
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(2) ? 'true' : ''}
                      >
                        {filteredMaterials[index].actualDeliveries.map(
                          (d: Todo, idx: number) => (
                            <S.ListCellContainer
                              key={`delivered-${index}-${idx}-${Math.random()}`}
                              highLighten={idx === 5}
                              rowIndex={selectedTypeIds.indexOf(2)}
                              colIndex={idx}
                              last={lastOfActualDeliveries}
                            >
                              {addComma(d?.amount)}
                            </S.ListCellContainer>
                          )
                        )}
                      </S.ListRow>
                      {/* 予定納品数 */}
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(3) ? 'true' : ''}
                      >
                        {filteredMaterials[index].estimatedDeliveries.map(
                          (d: Todo, idx: number) => (
                            <S.ListCellContainer
                              key={`delivery-${index}-${idx}-${Math.random()}`}
                              highLighten={idx === 5}
                              rowIndex={selectedTypeIds.indexOf(3)}
                              colIndex={idx}
                              last={lastOfEstimatedDeliveries}
                            >
                              {addComma(d?.amount)}
                            </S.ListCellContainer>
                          )
                        )}
                      </S.ListRow>
                    </S.MainMatrix>
                  </S.MatrixWrapper>
                )}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </S.ListWrapper>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
          title="資材・原材料"
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={MATERIAL_PRODUCT_KIND.MATERIAL}
        />
      )}
      {displaySelectModalActive && (
        <DisplaySelectModal
          notSelectableObj={'実在庫'}
          selectedTypes={selectedTypes}
          functionTypeObj={functionTypeObj}
          setSelectedTypes={(type) => setSelectedTypes(type)}
          displaySelectModalActive={displaySelectModalActive}
          handleClose={() => setDisplaySelectModalActive(false)}
        />
      )}

      {(tagMenuActive || rightSlideSidebarActive) && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
            onClickCancel();
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;

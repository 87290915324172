import * as S from './FactoryLineModal.styles';
import { Button } from '@components/elements/buttons/Button';
import { Heading } from '@components/elements/typographies/Heading';
import ProcessTable from '@components/modules/pc/settings/manufacture_setting/ProcessTable/ProcessTable';
import useFactoryLine from '@lib/pc/settings/manufacture_setting/useFactoryLine';

type Props = {
  selectedFactoryName: string;
  selectedLineName: string;
  selectedLineId: number;
  setFactoryLineModalActive: (factoryLineModalActive: boolean) => void;
};

const FactoryLineModal = ({
  selectedFactoryName,
  selectedLineName,
  selectedLineId,
  setFactoryLineModalActive,
}: Props) => {
  const { factoryLine } = useFactoryLine(selectedLineId);

  type employee = {
    code: string;
    name: string;
    email: string;
  };

  const isBothProductsKindExist =
    factoryLine?.productsProcesses?.length > 0 &&
    factoryLine?.semiProductsProcesses?.length > 0;

  return (
    <S.Wrapper>
      <S.HeadContainer>
        <Heading tag="span" text="工場・ライン" fs="32" fw="lg" />
      </S.HeadContainer>
      <S.Content>
        <S.FactoryLineContent>
          <div>
            <dt>工場名</dt>
            <dd>{selectedFactoryName}</dd>
          </div>
          <div>
            <dt>ライン名</dt>
            <dd>{selectedLineName}</dd>
          </div>
        </S.FactoryLineContent>
        <div className="title">生産実績担当従業員</div>
        {factoryLine?.employees?.length > 0 ? (
          <S.EmployeeListContainer>
            <S.EmployeeList titleText={true}>
              <div className="code">コード</div>
              <div className="name">名前</div>
              <div className="email">メールアドレス</div>
            </S.EmployeeList>

            {factoryLine?.employees &&
              factoryLine?.employees.map((employee: employee) => (
                <S.EmployeeList key={employee.code + employee.name}>
                  <div className="code">{employee.code}</div>
                  <div className="name">{employee.name}</div>
                  <div className="email">{employee.email}</div>
                </S.EmployeeList>
              ))}
          </S.EmployeeListContainer>
        ) : (
          <div className="no-employee">設定されていません。</div>
        )}

        <S.ProductAndSemiProductWrapper
          isBothProductsKindExist={isBothProductsKindExist}
        >
          {factoryLine?.productsProcesses?.length > 0 ? (
            <ProcessTable
              processName="完成品"
              isBothProductsKindExist={isBothProductsKindExist}
              processes={factoryLine?.productsProcesses}
            />
          ) : (
            ''
          )}

          {factoryLine?.semiProductsProcesses?.length > 0 ? (
            <ProcessTable
              processName="半製品"
              isBothProductsKindExist={isBothProductsKindExist}
              processes={factoryLine?.semiProductsProcesses}
            />
          ) : (
            ''
          )}
        </S.ProductAndSemiProductWrapper>
      </S.Content>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button
            children={'戻る'}
            borderWidth={1}
            outlined
            onClick={() => {
              setFactoryLineModalActive(false);
            }}
          />
        </S.ButtonContainer>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default FactoryLineModal;

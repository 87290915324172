import * as S from './StoreroomsForStocksModal.styles';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import StorageSearchForm from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomSearchForm/StoreroomSearchForm';
import StoreroomList from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomList/StoreroomList';
import type { Storeroom } from '@lib/common/type';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { MaterialsSearchState } from '@lib/mobile/materials_for_stocks/type';
import { useSelectedStoreroom } from '@lib/common/hooks/useSelectedStoreroom';
import { useSelectedTagIds } from '@lib/common/hooks/useSelectedTagIds';

type Props = {
  searchStoreroom: string;
  setSearchStoreroom: (searchStoreroom: string) => void;
  storerooms: Storeroom[] | undefined;
  setStoreroomOpen: (storeroomOpen: boolean) => void;
  fromPicking?: string;
  pickingState?: PickingState;
  materialsSearchState?: MaterialsSearchState;
};

const StoreroomsForStocksModal = ({
  searchStoreroom,
  setSearchStoreroom,
  storerooms,
  setStoreroomOpen,
  fromPicking,
  pickingState,
  materialsSearchState,
}: Omit<Props, 'setSelectedStoreroomId' | 'setSelectedStoreroomName'>) => {
  const history = useHistory();
  const { setSelectedStoreroomId, setSelectedStoreroomName } =
    useSelectedStoreroom();
  const { selectedTagIds } = useSelectedTagIds();

  const handleClickBackButton = () => {
    setStoreroomOpen(false);
    if (fromPicking === 'picking') {
      history.push(`/mobile/picking`, pickingState);
    } else if (fromPicking === 'picking_by_shipping') {
      history.push(`/mobile/picking_by_shipping`, pickingState);
    } else if (fromPicking === 'materials_for_stocks') {
      history.push(`/mobile/materials/for_stocks`, materialsSearchState);
    } else {
      history.push(`/mobile/products/for_stocks`, {
        ...searchState,
        stateSelectedTagIds: selectedTagIds,
      });
    }
  };

  return (
    <>
      <ScrollToTop />
      <S.Wrapper>
        <S.Header>
          <S.TitleWrapper>
            <S.IconWrapper>
              <S.BackButton
                onClick={() => {
                  // setSelectedStoreroomId('');
                  handleClickBackButton();
                }}
              >
                <ChevronLeftIcon />
              </S.BackButton>
            </S.IconWrapper>
            <S.TitleText>
              {fromPicking === 'picking' ||
              fromPicking === 'picking_by_shipping'
                ? '在庫室'
                : '場所'}
              の選択
            </S.TitleText>
          </S.TitleWrapper>
          <StorageSearchForm
            searchStoreroom={searchStoreroom}
            setSearchStoreroom={setSearchStoreroom}
            fromPicking={
              fromPicking === 'picking' || fromPicking === 'picking_by_shipping'
            }
          />
        </S.Header>
        <StoreroomList
          storerooms={storerooms}
          setStoreroomOpen={setStoreroomOpen}
          setSelectedStoreroomId={setSelectedStoreroomId}
          setSelectedStoreroomName={setSelectedStoreroomName}
          fromPicking={fromPicking}
          pickingState={pickingState}
          materialsSearchState={materialsSearchState}
        />
      </S.Wrapper>
    </>
  );
};

export default StoreroomsForStocksModal;

import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Storeroom } from './type';

const useStorerooms = () => {
  const { data, refetch } = useQuery('settings-storerooms', async () => {
    const data = await axiosInstance().get(`/api/v1/settings/storerooms`);
    return data;
  });
  const storerooms = humps.camelizeKeys(
    data?.data?.storerooms
  ) as unknown as Storeroom[];
  return { storerooms, refetch };
};

export default useStorerooms;

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { ReactComponent as MenuIcon } from '@assets/icons/menu_alt.svg';
import * as S from './Header.styles';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { ReactComponent as ArrowUpOutlined } from '@assets/icons/arrow_up_outlined.svg';
import { ReactComponent as Camera } from '@assets/icons/mobile/camera_black.svg';
import { ReactComponent as CardboardBox } from '@assets/icons/mobile/cardboard_box.svg';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import { ReactComponent as InputIcon } from '@assets/icons/mobile/input.svg';
import LogoutMenu from '@components/modules/common/mobile/LogoutMenu/LogoutMenu';
import LogoutDialog from '@components/modules/common/LogoutDialog/LogoutDialog';
import useMenuFunctions from '@lib/mobile/common/hooks/useMenuFunctions';
import { useSelectedTagIds } from '@lib/common/hooks/useSelectedTagIds';
import { useSearchChar } from '@lib/common/hooks/useSearchChar';
import { useSelectedStoreroom } from '@lib/common/hooks/useSelectedStoreroom';
import { useMetaPagination } from '@lib/common/hooks/useMetaPagination';

export const Header = () => {
  const [active, setActive] = useState(false);
  const [logoutMenuActive, setLogoutMenuActive] = useState(false);
  const [logoutDialogActive, setLogoutDialogActive] = useState(false);
  // const [selected, setSelected] = useState(true);
  const email = localStorage.getItem('email');
  const history = useHistory();
  // これがないと、志岐蒲鉾様などは一度ログアウトする必要がある
  const { data: mobileFunctions } = useMenuFunctions();

  const { mutate } = useSWRConfig();
  const { setSelectedTagIds } = useSelectedTagIds();
  const { setSearchChar } = useSearchChar();
  const { setSelectedStoreroomId, setSelectedStoreroomName } =
    useSelectedStoreroom();
  const { setMetaCurrentPage, setMetaTotalPages } = useMetaPagination();

  const logout = () => {
    localStorage.removeItem('pickingSelectedStoreroomName');
    localStorage.removeItem('pickingSelectedStoreroomId');
    localStorage.removeItem('mobile_functions');
    localStorage.removeItem('mobile_token');
    localStorage.removeItem('email');
    history.push('/mobile/login');
  };

  const handleOnClickLogout = () => {
    setLogoutMenuActive(false);
    setLogoutDialogActive(true);
  };

  const handleCloseDialog = () => {
    setLogoutDialogActive(false);
  };

  const updateServiceWorker = () => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => registrations.forEach((reg) => reg.update()));
    }
  };

  useEffect(() => {
    localStorage.setItem('mobile_functions', mobileFunctions?.join(',') || '');
  }, [mobileFunctions]);

  useEffect(() => {
    // コンポーネントがマウントされたときに実行される
    updateServiceWorker();
  }, []);

  const resetAll = () => {
    setSelectedTagIds([]);
    setSearchChar('');
    setSelectedStoreroomId('');
    setSelectedStoreroomName('');
    setMetaCurrentPage(1);
    setMetaTotalPages(1);

    mutate('filteredMaterials', undefined, false);
    mutate('filteredProducts', undefined, false);
  };

  const Sidebar = ({ hideSidebar }: { hideSidebar: () => void }) => {
    const handleMenuClick = (path: string) => {
      resetAll();
      hideSidebar();
      history.push(path);
    };

    return (
      <S.Sidebar>
        <S.MenuList>
          {mobileFunctions?.includes('stockStatus') && (
            <S.MenuItem>
              <S.MenuLink
                to={'/mobile/products/for_stocks'}
                onClick={() => handleMenuClick('/mobile/products/for_stocks')}
              >
                <InputIcon />
                <S.MenuText>在庫の入力</S.MenuText>
              </S.MenuLink>
            </S.MenuItem>
          )}
          {mobileFunctions?.includes('materialInventory') && (
            <S.MenuItem>
              <S.MenuLink
                to={'/mobile/materials/for_stocks'}
                onClick={() => handleMenuClick('/mobile/materials/for_stocks')}
              >
                <InputIcon />
                <S.MenuText>資材・原材料在庫の入力</S.MenuText>
              </S.MenuLink>
            </S.MenuItem>
          )}
          {mobileFunctions?.includes('picking') && (
            <S.MenuItem>
              <S.MenuLink
                to={'/mobile/picking_by_shipping'}
                onClick={() => handleMenuClick('/mobile/picking_by_shipping')}
              >
                <CardboardBox />
                <S.MenuText>ピッキング（出荷先別）</S.MenuText>
              </S.MenuLink>
            </S.MenuItem>
          )}
          {mobileFunctions?.includes('captureOfProduct') && (
            <S.MenuItem>
              <S.MenuLink
                to={'/mobile/products_photograph'}
                onClick={() => handleMenuClick('/mobile/products_photograph')}
              >
                <Camera />
                <S.MenuText>商品撮影</S.MenuText>
              </S.MenuLink>
            </S.MenuItem>
          )}
        </S.MenuList>
      </S.Sidebar>
    );
  };

  return (
    <S.Wrapper>
      <S.NavWrapper>
        <S.NavButton onClick={() => setActive(true)}>
          <MenuIcon />
        </S.NavButton>
        <S.Title>
          Regnio
          <br />
          Factory
        </S.Title>
      </S.NavWrapper>
      <S.UserInfo
        onClick={() => {
          setLogoutMenuActive(!logoutMenuActive);
        }}
      >
        <S.UserName>{email}</S.UserName>
        {logoutMenuActive ? <ArrowUpOutlined /> : <ExpandMore />}
      </S.UserInfo>

      {logoutDialogActive && (
        <Overlay dark handleClick={() => setLogoutDialogActive(false)} />
      )}
      {logoutMenuActive && (
        <Overlay handleClick={() => setLogoutMenuActive(false)} />
      )}
      {active && (
        <>
          <Sidebar hideSidebar={() => setActive(false)} />
          <Overlay dark handleClick={() => setActive(false)} />
        </>
      )}
      <S.LogoutMenu logoutMenuActive={logoutMenuActive}>
        <LogoutMenu handleOnClickLogout={handleOnClickLogout} />
      </S.LogoutMenu>
      <S.logoutDialog logoutDialogActive={logoutDialogActive}>
        <LogoutDialog logout={logout} handleCloseDialog={handleCloseDialog} />
      </S.logoutDialog>
    </S.Wrapper>
  );
};

import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useLocation } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './ManufactureEditWrapper.styles';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import EmployeeInfo from '@components/modules/pc/settings/manufacture_setting/EmployeeInfo/EmployeeInfo';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import ManufactureInfo from '@components/modules/pc/settings/manufacture_setting/ManufactureInfo/ManufactureInfo';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import { ProductMasterSettingState } from '@lib/pc/settings/product_master_setting/type';
import {
  EmployeesInfo,
  ManufactureInfoType,
} from '@lib/pc/settings/manufacture_setting/type';
import { FactoryLine } from '@lib/pc/settings/product_master_edit/type';
import FactoryFactoryLineInfo from '../FactoryFactoryLineInfo/FactoryFactoryLineInfo';
import { Factory } from '@lib/common/type';

type Props = {
  manufactureInfo: ManufactureInfoType;
  employeesInfo: EmployeesInfo;
  allFactories: Factory[] | undefined;
  allFactoryLines: FactoryLine[] | undefined;
  refetch: () => void;
};

export type InputParams = {
  time: string;
};

const ManufactureEditWrapper = ({
  manufactureInfo,
  employeesInfo,
  allFactories,
  allFactoryLines,
  refetch,
}: Props) => {
  const { state } = useLocation<ProductMasterSettingState>();
  const [updatePopUp, setUpdatePopUp] = useState(false);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [selectedTabId, setSelectedTabId] = useState(0);

  const ProductSettingTabs = [
    '会社情報',
    '工場・ライン',
    // '在庫室',
    '従業員',
    // 'ログインユーザ',
  ];

  const handleClickTab = (i: number) => {
    if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    setSelectedTabIndex(selectedTabId);
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    if (state?.stateSelectedTabId) {
      setSelectedTabIndex(state?.stateSelectedTabId);
    } else {
      setSelectedTabIndex(0);
    }
  }, [state]);

  return (
    <div>
      <S.TabWrapper>
        {ProductSettingTabs.map((d: string, i: number) => (
          <div key={d}>
            <S.TabArea>
              <S.Tab
                key={d}
                tabCount={ProductSettingTabs.length}
                active={i == selectedTabIndex}
                onClick={() => {
                  handleClickTab(i);
                }}
              >
                {d}
              </S.Tab>
            </S.TabArea>
          </div>
        ))}
      </S.TabWrapper>

      <DndProvider backend={HTML5Backend}>
        {/* 会社情報 */}
        {selectedTabIndex === 0 && (
          <ManufactureInfo manufactureInfo={manufactureInfo} />
        )}
        {/* 工場・ライン */}
        {selectedTabIndex === 1 && (
          <FactoryFactoryLineInfo
            manufactureName={employeesInfo?.manufactureName}
            allFactories={allFactories}
            allFactoryLines={allFactoryLines}
          />
        )}
        {/* 在庫室 */}
        {/* 従業員 */}
        {selectedTabIndex === 2 && (
          <EmployeeInfo employeesInfo={employeesInfo} refetch={refetch} />
        )}
        {/* ログインユーザ */}
      </DndProvider>
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'update'}
        handleClose={() => setUpdatePopUp(false)}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </div>
  );
};

export default ManufactureEditWrapper;

import React, { useEffect, useState } from 'react';
import * as S from './ProcessInfo.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import ProductImg from '@assets/images/product-img.jpg';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { ReactComponent as PlusBlueIcon } from '@assets/icons/mobile/plus_blue.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { Button } from '@components/elements/buttons/Button';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import CommonConstituentPartsModal from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/CommonConstituentPartsModal/CommonConstituentPartsModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import FactoryLineModal from '@components/modules/pc/settings/common/FactoryLineModal/FactoryLineModal';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import ProcessSelect from '@components/modules/pc/settings/common/ProcessSelect/ProcessSelect';
import ProcessesMaterialList from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/ProcessesMaterialList/ProcessesMaterialList';
import ProcessNameChangeModal from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/ProcessNameChangeModal/ProcessNameChangeModal';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import {
  Factory,
  FactoryLine,
  MATERIAL_TYPE_LABEL,
  Todo,
} from '@lib/common/type';
import {
  Product,
  SemiProduct,
  Process,
  Material,
} from '@lib/pc/settings/product_master_setting/type';
import useCreateProductsProcessMutation from '@lib/pc/settings/product_master_setting/useCreateProductsProcess';
import useUpdateProductsProcessMutation from '@lib/pc/settings/product_master_setting/useUpdateProductsProcess';
import useProcessRelations from '@lib/pc/settings/product_master_edit/useProcessRelations';
import {
  MATERIAL,
  PRODUCT,
  SEMI_PRODUCT,
} from '@lib/pc/settings/product_master_edit/type';
import useUpdateProductsProcessName from '@lib/pc/settings/product_master_edit/useUpdateProductsProcessName';

type Props = {
  product: Todo;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  onClickGoBack: () => void;
  setIsChanged: (isChanged: boolean) => void;
  handleProductUpdateSuccess: () => void;
  allFactories: Factory[] | undefined;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  selectableProcess: boolean;
  setSelectableProcess: (selectableProcess: boolean) => void;
};

const ProcessInfo = ({
  product,
  updating,
  setUpdating,
  onClickGoBack,
  setIsChanged,
  handleProductUpdateSuccess,
  allFactories,
  enableToSave,
  setEnableToSave,
  selectableProcess,
  setSelectableProcess,
}: Props) => {
  // 新規ボタンor編集ボタン
  const [newMode, setNewMode] = useState(true);
  const [errMsg, setErrMsg] = useState('');

  const initProcessData = {
    id: 0,
    name: '',
    productId: product?.id || product?.product?.id,
  };

  // 工程更新内容
  const [newProcessData, setNewProcessData] = useState(initProcessData);

  const initProcess = {
    processId: 0,
    processName: '',
    factories: [],
    factoryLines: [],
    semiProducts: [],
  };

  // 工程id
  const [selectedProcessId, setSelectedProcessId] = useState(0);
  // 工程名
  const [selectedProcessName, setSelectedProcessName] = useState('');

  // 半製品id、名前、最小単位
  const [selectedSemiProduct, setSelectedSemiProduct] = useState({
    id: 0,
    name: '',
    labelOfAmount: '',
  });

  // 完成品id、名前、最小単位
  const [selectedProduct, setSelectedProduct] = useState({
    id: 0,
    name: '',
    labelOfAmount: '',
  });

  // 資材id、名前、最小単位
  const [selectedMaterial, setSelectedMaterial] = useState({
    id: 0,
    name: '',
    labelOfAmount: '',
  });

  // 原材料id、名前、最小単位
  const [selectedRawMaterial, setSelectedRawMaterial] = useState({
    id: 0,
    name: '',
    labelOfAmount: '',
  });

  // 工場ライン編集
  const [selectedLines, setSelectedLines] = useState<FactoryLine[]>([]);
  // 商品に紐づく工場
  const [factories, setFactories] = useState(product?.factories);
  const [factoryLineModalActive, setFactoryLineModalActive] = useState(false);
  // 紐づけ項目選択modal画面
  const [modalActiveFrom, setModalActiveFrom] = useState('');
  // 工程名変更modal画面
  const [processNameChangeModalActive, setProcessNameChangeModalActive] =
    useState(false);
  const [processNameAtModal, setProcessNameAtModal] =
    useState(selectedProcessName);

  // 変更前工場ライン
  const [beforeLineIds, setBeforeLineIds] = useState<number[]>([]);

  // 半製品AutoComplete用
  const [editItem, setEditItem] = useState<SemiProduct>({
    id: 0,
    name: '',
    pieces: 0,
    imageUrl: '',
    labelOfAmount: '',
  });
  // 半製品入力の数
  const [pieces, setPieces] = useState<Todo>(null);
  // 半製品配列➜表示に使用
  const [semiProductsArray, setSemiProductsArray] = useState<SemiProduct[]>([]);
  // 完成品AutoComplete用
  const [productEditItem, setProductEditItem] = useState<Product>({
    id: 0,
    name: '',
    pieces: 0,
    imageUrl: '',
    labelOfAmount: '',
  });

  // 完成品入力の数
  const [productPieces, setProductPieces] = useState<Todo>(null);
  // 完成品配列➜表示に使用
  const [productsArray, setProductsArray] = useState<Product[]>([]);
  // 資材入力の数
  const [materialUsageAmount, setMaterialUsageAmount] = useState<Todo>(null);
  // 原材料入力の数
  const [rawMaterialUsageAmount, setRawMaterialUsageAmount] =
    useState<Todo>(null);
  // 資材配列➜表示に使用
  const [materialsArray, setMaterialsArray] = useState<Material[]>([]);
  // 原材料配列➜表示に使用
  const [rawMaterialsArray, setRawMaterialsArray] = useState<Material[]>([]);
  // 資材AutoComplete用
  const [materialEditItem, setMaterialEditItem] = useState<Material>({
    id: 0,
    name: '',
    usageAmount: 0,
    imageUrl: '',
    labelOfAmount: '',
  });
  // 原材料AutoComplete用
  const [rawMaterialEditItem, setRawMaterialEditItem] = useState<Material>({
    id: 0,
    name: '',
    usageAmount: 0,
    imageUrl: '',
    labelOfAmount: '',
  });

  const {
    products,
    semiProducts: allSemiProducts,
    rawMaterials,
    supplyMaterials: materials,
  } = useProcessRelations(selectedProcessId);

  const semiProductsArrayIds = semiProductsArray.map((item) => item['id']);
  const flitteredSemiProduct = allSemiProducts?.filter(
    (sp: Todo) => !semiProductsArrayIds.includes(sp.id)
  );

  const productsArrayIds = productsArray.map((item) => item['id']);
  const filteredProducts = products?.filter(
    (p) => p.id !== product?.product?.id && !productsArrayIds.includes(p.id)
  );

  const materialsArrayIds = materialsArray.map((item) => item['id']);
  const filteredMaterials = materials?.filter(
    (m: { id: number }) => !materialsArrayIds.includes(m.id)
  );

  const rawMaterialsArrayIds = rawMaterialsArray.map((item) => item['id']);
  const filteredRawMaterials = rawMaterials?.filter(
    (m: { id: number }) => !rawMaterialsArrayIds.includes(m.id)
  );

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  // 削除No
  const [deleteNo, setDeleteNo] = useState(0);
  // 削除対象
  const [deleteItem, setDeleteItem] = useState('');

  // 工程名の設定
  const handleChangeProcessName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChanged(true);
    setEnableToSave(true);
    const data = { ...newProcessData, name: e.target.value };
    setNewProcessData(data);
  };

  // 半製品の数の設定
  const handleChangeSemiProductPieces = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsChanged(true);
    setPieces(e.target.value);
  };

  // 半製品追加ボタン
  const onClickAddButton = (editItem: SemiProduct) => {
    if (pieces > 0) {
      setIsChanged(true);
      setEnableToSave(true);
      // 表示用
      semiProductsArray.push({
        id: editItem.id,
        name: editItem.name,
        imageUrl: editItem.imageUrl,
        pieces: pieces,
        labelOfAmount: editItem.labelOfAmount,
      });
      setPieces(null);
      setEditItem({
        id: 0,
        name: '',
        pieces: 0,
        imageUrl: '',
        labelOfAmount: '',
      });
      setSelectedSemiProduct({ id: 0, name: '', labelOfAmount: '' });
    } else {
      setErrMsg('0より大きい数を入力してください。');
    }
  };

  // 半製品削除ボタン
  const onClickDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteItem(SEMI_PRODUCT);
    setDeleteNo(no);
  };

  // 完成品の数の設定
  const handleChangeProductPieces = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsChanged(true);
    setProductPieces(e.target.value);
  };

  // 完成品追加ボタン
  const onClickProductAddButton = (productEditItem: Product) => {
    if (productPieces > 0) {
      setIsChanged(true);
      setEnableToSave(true);
      // 表示用
      productsArray.push({
        id: productEditItem.id,
        name: productEditItem.name,
        imageUrl: productEditItem.imageUrl,
        pieces: productPieces,
        labelOfAmount: productEditItem.labelOfAmount,
      });
      setProductPieces(null);
      setProductEditItem({
        id: 0,
        name: '',
        pieces: 0,
        imageUrl: '',
        labelOfAmount: '',
      });
      setSelectedProduct({ id: 0, name: '', labelOfAmount: '' });
    } else {
      setErrMsg('0より大きい数を入力してください。');
    }
  };

  // 完成品削除ボタン
  const onClickProductDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteItem(PRODUCT);
    setDeleteNo(no);
  };

  const deleteFunction = () => {
    setIsChanged(true);
    setEnableToSave(true);
    if (deleteItem === SEMI_PRODUCT) {
      semiProductsArray.splice(deleteNo, 1);
      setSemiProductsArray([...semiProductsArray]);
    } else if (deleteItem === PRODUCT) {
      productsArray.splice(deleteNo, 1);
      setProductsArray([...productsArray]);
    } else if (deleteItem === MATERIAL) {
      materialsArray.splice(deleteNo, 1);
      setMaterialsArray([...materialsArray]);
    } else {
      rawMaterialsArray.splice(deleteNo, 1);
      setRawMaterialsArray([...rawMaterialsArray]);
    }
    setDeleteItem('');
    setConfirmMsg('');
  };

  // 資材の数の設定
  const handleChangeMaterialUsageAmount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsChanged(true);
    setMaterialUsageAmount(e.target.value);
  };

  // 原材料の数の設定
  const handleChangeRawMaterialUsageAmount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsChanged(true);
    setRawMaterialUsageAmount(e.target.value);
  };

  // 資材追加ボタン
  const onClickMaterialAddButton = (materialEditItem: Material) => {
    if (materialUsageAmount > 0) {
      setIsChanged(true);
      setEnableToSave(true);
      materialsArray.push({
        id: materialEditItem.id,
        name: materialEditItem.name,
        imageUrl: materialEditItem.imageUrl,
        usageAmount: materialUsageAmount,
        labelOfAmount: selectedMaterial.labelOfAmount,
      });
      setMaterialUsageAmount(null);
      setMaterialEditItem({
        id: 0,
        name: '',
        usageAmount: 0,
        imageUrl: '',
        labelOfAmount: '',
      });
      setSelectedMaterial({ id: 0, name: '', labelOfAmount: '' });
    } else {
      setErrMsg('0より大きい数を入力してください。');
    }
  };

  // 原材料追加ボタン
  const onClickRawMaterialAddButton = (materialEditItem: Material) => {
    if (rawMaterialUsageAmount > 0) {
      setIsChanged(true);
      setEnableToSave(true);
      rawMaterialsArray.push({
        id: materialEditItem.id,
        name: materialEditItem.name,
        imageUrl: materialEditItem.imageUrl,
        usageAmount: rawMaterialUsageAmount,
        labelOfAmount: selectedRawMaterial.labelOfAmount,
      });
      setRawMaterialUsageAmount(null);
      setRawMaterialEditItem({
        id: 0,
        name: '',
        usageAmount: 0,
        imageUrl: '',
        labelOfAmount: '',
      });
      setSelectedRawMaterial({ id: 0, name: '', labelOfAmount: '' });
    } else {
      setErrMsg('0より大きい数を入力してください。');
    }
  };

  const createProductsProcessMutation = useCreateProductsProcessMutation(
    setSelectedProcessId,
    handleProductUpdateSuccess,
    setErrMsg
  );
  const updateProductsProcessMutation = useUpdateProductsProcessMutation(
    selectedProcessId,
    handleProductUpdateSuccess,
    setErrMsg
  );

  const updateProductsProcessNameSuccess = () => {
    setSelectedProcessName(processNameAtModal);
    handleProductUpdateSuccess();
    setProcessNameChangeModalActive(false);
  };

  // 工程名更新
  const updateProductsProcessName = useUpdateProductsProcessName(
    selectedProcessId,
    updateProductsProcessNameSuccess,
    setErrMsg
  );

  const handleUpdateProcessName = () => {
    updateProductsProcessName.mutate({ name: processNameAtModal });
  };

  const handleClickNew = () => {
    setNewMode(true);
    setSelectedLines([]);
    setSemiProductsArray([]);
    setProductsArray([]);
    setMaterialsArray([]);
    setRawMaterialsArray([]);
    setBeforeLineIds([]);
    setSelectableProcess(false);
  };

  const handleClickEdit = () => {
    if (product?.processes?.length > 0) {
      // 1つめの工程設定
      setSelectedProcessId(product.processes[0].processId);
      setSelectedProcessName(product.processes[0].processName);
      setSelectedProcessInfo();
      setSelectableProcess(true);
      setNewMode(false);
    }
  };

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    const selectedLinesIdsForUpdate = selectedLines.map((item) => item['id']);
    const data = {
      ...newProcessData,
      productId: product.product.id,
      factoryLineIds: selectedLinesIdsForUpdate,
    };
    setNewProcessData(data);
    const semiProductsForUpdate = semiProductsArray.map(
      (item: SemiProduct) => ({
        id: item.id,
        pieces: item.pieces,
      })
    );
    const productsForUpdate = productsArray.map((item: Product) => ({
      id: item.id,
      pieces: item.pieces,
    }));
    // 資材と原材料追加データのまとめ
    const mergedArray = materialsArray.concat(rawMaterialsArray);
    const materialsForUpdate = mergedArray.map((item: Material) => ({
      id: item.id,
      usageAmount: item.usageAmount,
    }));

    if (newMode) {
      // 作成処理
      createProductsProcessMutation.mutate({
        name: newProcessData.name,
        productId: product.product.id,
        semiProducts: semiProductsForUpdate,
        products: productsForUpdate,
        materials: materialsForUpdate,
        factoryLineIds: selectedLinesIdsForUpdate,
      });
      setSelectedLines([]);
      setSemiProductsArray([]);
      setProductsArray([]);
      setMaterialsArray([]);
      setRawMaterialsArray([]);
      setBeforeLineIds([]);
      setNewProcessData(initProcessData);
    } else {
      // 更新処理
      updateProductsProcessMutation.mutate({
        productsProcess: newProcessData,
        semiProducts: semiProductsForUpdate,
        products: productsForUpdate,
        materials: materialsForUpdate,
        factoryLineIds: selectedLinesIdsForUpdate,
      });
    }
  };

  // 選択された工程の内容を設定する
  const setSelectedProcessInfo = () => {
    const selectedProcess = product?.processes
      ? product?.processes.find(
          (p: Process) => selectedProcessId === p.processId
        )
      : initProcess;

    setSelectedLines(
      selectedProcess?.factoryLines ? selectedProcess?.factoryLines : []
    );
    setSemiProductsArray(
      selectedProcess?.semiProducts ? selectedProcess?.semiProducts : []
    );
    setProductsArray(
      selectedProcess?.products ? selectedProcess?.products : []
    );
    setMaterialsArray(
      selectedProcess?.materials ? selectedProcess?.materials : []
    );
    setRawMaterialsArray(
      selectedProcess?.rawMaterials ? selectedProcess?.rawMaterials : []
    );
    if (selectedProcess?.factoryLines) {
      const beforeIds = selectedProcess?.factoryLines.map(
        (item: FactoryLine) => item['id']
      );
      setBeforeLineIds(beforeIds);
    } else {
      setBeforeLineIds([]);
    }
  };

  // modal画面のselectで設定する項目
  const handleChangeSelect = (e: number, s: string, labelOfAmount: string) => {
    if (modalActiveFrom === PRODUCT) {
      setSelectedProduct({ id: e, name: s, labelOfAmount: labelOfAmount });
    } else if (modalActiveFrom === SEMI_PRODUCT) {
      setSelectedSemiProduct({ id: e, name: s, labelOfAmount: labelOfAmount });
    } else if (modalActiveFrom === MATERIAL) {
      setSelectedMaterial({ id: e, name: s, labelOfAmount: labelOfAmount });
    } else {
      setSelectedRawMaterial({ id: e, name: s, labelOfAmount: labelOfAmount });
    }
  };

  // modal画面の数量で設定する項目
  const handleChangePieces = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (modalActiveFrom === PRODUCT) {
      handleChangeProductPieces(e);
    } else if (modalActiveFrom === SEMI_PRODUCT) {
      handleChangeSemiProductPieces(e);
    } else if (modalActiveFrom === MATERIAL) {
      handleChangeMaterialUsageAmount(e);
    } else {
      handleChangeRawMaterialUsageAmount(e);
    }
  };

  const handleChangeProcessNameAtModal = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProcessNameAtModal(e.target.value);
  };

  useEffect(() => {
    const newIds = selectedLines.map((item: FactoryLine) => item['id']);
    if (newIds.toString() !== beforeLineIds.toString()) {
      setIsChanged(true);
      setEnableToSave(true);
    } else {
      setIsChanged(false);
    }
  }, [selectedLines]);

  useEffect(() => {
    setEditItem({
      id: selectedSemiProduct.id,
      name: selectedSemiProduct.name,
      pieces: 0,
      imageUrl: '',
      labelOfAmount: selectedSemiProduct.labelOfAmount,
    });
  }, [selectedSemiProduct]);

  useEffect(() => {
    setProductEditItem({
      id: selectedProduct.id,
      name: selectedProduct.name,
      pieces: 0,
      imageUrl: '',
      labelOfAmount: selectedProduct.labelOfAmount,
    });
  }, [selectedProduct]);

  useEffect(() => {
    setMaterialEditItem({
      id: selectedMaterial.id,
      name: selectedMaterial.name,
      usageAmount: 0,
      imageUrl: '',
      labelOfAmount: selectedMaterial.labelOfAmount,
    });
  }, [selectedMaterial]);

  useEffect(() => {
    setRawMaterialEditItem({
      id: selectedRawMaterial.id,
      name: selectedRawMaterial.name,
      usageAmount: 0,
      imageUrl: '',
      labelOfAmount: selectedRawMaterial.labelOfAmount,
    });
  }, [selectedRawMaterial]);

  // 工程が変更されたら工場・ラインと半製品を該当工程のものへ設定する
  useEffect(() => {
    if (selectedProcessId !== initProcess.processId) {
      setNewMode(false);
    } else {
      setNewMode(true);
    }
    setSelectedProcessInfo();
  }, [selectedProcessId]);

  // 工程登録済みの場合、編集ボタンをactiveにする
  useEffect(() => {
    if (selectedProcessId === 0 && product?.processes?.length > 0) {
      setSelectableProcess(true);
      // 1つめの工程設定
      setSelectedProcessId(product.processes[0].processId);
      setSelectedProcessName(product.processes[0].processName);
    } else {
      setSelectedProcessInfo();
    }
  }, [product?.processes?.length]);

  const isAddButtonDisabled =
    (newMode &&
      (newProcessData?.name === undefined || newProcessData?.name === '')) ||
    (!newMode && newProcessData.productId === 0);

  return (
    <>
      {product && (
        <S.Wrapper>
          <div className="go-back" onClick={onClickGoBack}>
            <ChevronLeft isBlue={true} />
            <span>一覧画面へ戻る</span>
          </div>

          <S.ProductMasterEditContents>
            <div className="product-info">
              <div className="product-img">
                <div className="img">
                  <img
                    src={
                      product?.imageUrl ||
                      product?.product?.imageUrl ||
                      ProductImg
                    }
                  />
                </div>
              </div>
              <div className="name">
                {product?.name || product?.product?.name}
              </div>
            </div>
            <div className="associated-number-of-pieces">
              {/* 工程名 */}
              <div className="process-breakdown-list-header">
                <dl className="process-breakdown-list-header__inputs">
                  <div className="process-name">
                    <dt>工程名</dt>
                    <dd>
                      {selectableProcess ? (
                        // 工程選択
                        <ProcessSelect
                          processes={product?.processes}
                          value={selectedProcessId}
                          setSelectedProcessId={setSelectedProcessId}
                          setSelectedProcessName={setSelectedProcessName}
                        />
                      ) : (
                        <S.Input
                          type="text"
                          inputMode="numeric"
                          name="process"
                          id="process"
                          autoComplete="off"
                          align="left"
                          padding="0 5px"
                          value={newProcessData?.name}
                          onChange={handleChangeProcessName}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                <div className="new-btn">
                  <S.ModeButton
                    newMode={selectableProcess ? true : false}
                    onClick={() => {
                      handleClickNew();
                    }}
                  >
                    新規
                  </S.ModeButton>
                </div>
                <div className="edit-btn">
                  <S.ModeButton
                    newMode={selectableProcess ? false : true}
                    onClick={() => {
                      handleClickEdit();
                    }}
                  >
                    編集
                  </S.ModeButton>
                </div>
                <div className="rename-btn">
                  <S.ModeButton
                    newMode={selectableProcess ? false : true}
                    onClick={() => {
                      setProcessNameAtModal(selectedProcessName);
                      setProcessNameChangeModalActive(true);
                    }}
                  >
                    工程名変更
                  </S.ModeButton>
                </div>
              </div>
              {/* 工場・ライン */}
              <div className="plant-line-container">
                <div className="plant-line-container__title">工場・ライン</div>
                <ul className="plant-line-list">
                  {/* 製造者の工場全てから検索する */}
                  {allFactories &&
                    allFactories.map((factory) => {
                      const selectedLinesForFactory = selectedLines?.filter(
                        (line) => line.factoryId === factory.id
                      );
                      const hasSelectedLines =
                        selectedLinesForFactory.length > 0;

                      return (
                        <React.Fragment key={factory.id}>
                          {hasSelectedLines && (
                            <S.FactoryList
                              className="factory-list"
                              factoryPresence={hasSelectedLines}
                            >
                              {hasSelectedLines && (
                                <div className="plant-name">{factory.name}</div>
                              )}
                              <div className="line-wrapper">
                                {selectedLinesForFactory.map((line) => (
                                  <div key={line.id}>
                                    <ul className="plant-line-list__line line-list">
                                      <li className="line-list__line">
                                        {line.name}
                                      </li>
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            </S.FactoryList>
                          )}
                        </React.Fragment>
                      );
                    })}
                </ul>
                <div
                  className={
                    isAddButtonDisabled ? 'disabled-add-button' : 'add-button'
                  }
                  onClick={
                    !isAddButtonDisabled
                      ? () => setFactoryLineModalActive(true)
                      : undefined
                  }
                >
                  <S.PlusIcon>
                    {isAddButtonDisabled ? <PlusIcon /> : <PlusBlueIcon />}
                  </S.PlusIcon>
                  <span className="label">工場・ラインを選択</span>
                </div>
              </div>
              {/* 半製品タイトル */}
              <S.ConstituentTitle>
                <S.ConstituentTitleLi>
                  <div className="close-btn"></div>
                  <div className="product-name">半製品名</div>
                  <div className="pieces-num">数量</div>
                  <div className="label-of-amount">単位</div>
                </S.ConstituentTitleLi>
              </S.ConstituentTitle>
              {/* 半製品個数内訳リスト */}
              <div className="piece-breakdown-list-contents">
                <ul className="piece-breakdown-list-contents__pieces">
                  {semiProductsArray &&
                    semiProductsArray.map((sp: SemiProduct, index: number) => {
                      return (
                        <li
                          className="piece-breakdown-list-contents__piece"
                          key={sp?.id + index + Math.random()}
                        >
                          <div
                            className="close-btn"
                            onClick={() => onClickDelButton(index, sp?.name)}
                            key={sp?.name + index}
                          >
                            <CircleCloseIcon />
                          </div>
                          <div
                            className="product-name"
                            key={sp.name + Math.random()}
                          >
                            {sp?.name}
                          </div>
                          <div
                            className="product-pieces-num"
                            key={sp.name + sp.pieces + Math.random()}
                          >
                            {sp?.pieces}
                          </div>
                          <div className="label-of-amount">
                            {sp?.labelOfAmount
                              ? LABEL_OF_AMOUNT_MAP[sp?.labelOfAmount]
                              : '個'}
                          </div>
                        </li>
                      );
                    })}
                </ul>
                {/* 半製品追加modal画面表示箇所 */}
                <div
                  className="add-button"
                  onClick={() => setModalActiveFrom(SEMI_PRODUCT)}
                >
                  <S.PlusIcon>
                    <PlusBlueIcon />
                  </S.PlusIcon>
                  <span className="label">半製品を追加</span>
                </div>
              </div>
              {/* 完成品タイトル */}
              <S.ConstituentTitle>
                <S.ConstituentTitleLi>
                  <div className="close-btn"></div>
                  <div className="product-name">完成品名</div>
                  <div className="pieces-num">数量</div>
                  <div className="label-of-amount">単位</div>
                </S.ConstituentTitleLi>
              </S.ConstituentTitle>
              {/* 完成品個数内訳リスト */}
              <div className="piece-breakdown-list-contents">
                <ul className="piece-breakdown-list-contents__pieces">
                  {productsArray &&
                    productsArray.map((p: Product, index: number) => {
                      return (
                        <li
                          className="piece-breakdown-list-contents__piece"
                          key={p?.id + index + Math.random()}
                        >
                          <div
                            className="close-btn"
                            onClick={() =>
                              onClickProductDelButton(index, p?.name)
                            }
                            key={p?.name + index}
                          >
                            <CircleCloseIcon />
                          </div>
                          <div
                            className="product-name"
                            key={p?.name + Math.random()}
                          >
                            {p?.name}
                          </div>
                          <div
                            className="product-pieces-num"
                            key={p?.name + p.pieces}
                          >
                            {p?.pieces}
                          </div>
                          <div className="label-of-amount">
                            {p?.labelOfAmount
                              ? LABEL_OF_AMOUNT_MAP[p?.labelOfAmount]
                              : '個'}
                          </div>
                        </li>
                      );
                    })}
                </ul>
                {/* 完成品追加modal画面表示箇所 */}
                <div
                  className="add-button"
                  onClick={() => setModalActiveFrom(PRODUCT)}
                >
                  <S.PlusIcon>
                    <PlusBlueIcon />
                  </S.PlusIcon>
                  <span className="label">完成品を追加</span>
                </div>
              </div>
              {/* 資材追加箇所 */}
              <ProcessesMaterialList
                label={MATERIAL_TYPE_LABEL.MATERIAL}
                materialsArray={materialsArray}
                setConfirmMsg={setConfirmMsg}
                setDeleteItem={setDeleteItem}
                setDeleteNo={setDeleteNo}
                setModalActiveFrom={setModalActiveFrom}
              />
              {/* 原材料追加箇所 */}
              <ProcessesMaterialList
                label={MATERIAL_TYPE_LABEL.ROW_MATERIAL}
                materialsArray={rawMaterialsArray}
                setConfirmMsg={setConfirmMsg}
                setDeleteItem={setDeleteItem}
                setDeleteNo={setDeleteNo}
                setModalActiveFrom={setModalActiveFrom}
              />
            </div>
          </S.ProductMasterEditContents>
        </S.Wrapper>
      )}
      <S.ButtonContainer>
        {enableToSave ? (
          <Button
            children={updating ? '保存中...' : '保存'}
            onClick={handleSubmit}
          />
        ) : (
          <Button disabled children={'保存'} />
        )}
      </S.ButtonContainer>
      {factoryLineModalActive && (
        <FactoryLineModal
          selectedLines={selectedLines}
          setSelectedLines={setSelectedLines}
          factories={factories}
          setFactories={setFactories}
          handleClose={() => setFactoryLineModalActive(false)}
        />
      )}
      {/* 共通のmodal画面にする：何から表示されているかの判定が必要 */}
      {modalActiveFrom && (
        <CommonConstituentPartsModal
          modalActiveFrom={modalActiveFrom}
          processName={
            newProcessData?.name ? newProcessData?.name : selectedProcessName
          }
          pieces={
            modalActiveFrom === PRODUCT
              ? productPieces
              : modalActiveFrom === SEMI_PRODUCT
              ? pieces
              : modalActiveFrom === MATERIAL
              ? materialUsageAmount
              : rawMaterialUsageAmount
          }
          flitteredOptions={
            modalActiveFrom === PRODUCT
              ? filteredProducts
              : modalActiveFrom === SEMI_PRODUCT
              ? flitteredSemiProduct
              : modalActiveFrom === MATERIAL
              ? filteredMaterials
              : filteredRawMaterials
          }
          selectedId={
            modalActiveFrom === PRODUCT
              ? selectedProduct.id
              : modalActiveFrom === SEMI_PRODUCT
              ? selectedSemiProduct.id
              : modalActiveFrom === MATERIAL
              ? selectedMaterial.id
              : selectedRawMaterial.id
          }
          selectedLabelOfAmount={
            modalActiveFrom === PRODUCT
              ? selectedProduct.labelOfAmount
              : modalActiveFrom === SEMI_PRODUCT
              ? selectedSemiProduct.labelOfAmount
              : modalActiveFrom === MATERIAL
              ? selectedMaterial.labelOfAmount
              : selectedRawMaterial.labelOfAmount
          }
          newProcessData={newProcessData}
          selectedProcessId={selectedProcessId}
          editItem={editItem}
          onClickAddButton={() =>
            modalActiveFrom === PRODUCT
              ? onClickProductAddButton(productEditItem)
              : modalActiveFrom === SEMI_PRODUCT
              ? onClickAddButton(editItem)
              : modalActiveFrom === MATERIAL
              ? onClickMaterialAddButton(materialEditItem)
              : onClickRawMaterialAddButton(rawMaterialEditItem)
          }
          handleCancel={() => setModalActiveFrom('')}
          handleChangeSelect={handleChangeSelect}
          handleChangePieces={handleChangePieces}
        />
      )}
      {processNameChangeModalActive && (
        <ProcessNameChangeModal
          processNameAtModal={processNameAtModal}
          handleChangeProcessNameAtModal={handleChangeProcessNameAtModal}
          handleUpdateProcessName={handleUpdateProcessName}
          handleCancel={() => setProcessNameChangeModalActive(false)}
        />
      )}
      {confirmMsg && (
        <DeleteConfirmModal
          confirmMsg={confirmMsg}
          onClickCancel={() => setConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {(modalActiveFrom || processNameChangeModalActive || errMsg) && (
        <Overlay
          dark
          handleClick={() => {
            setModalActiveFrom('');
            setProcessNameChangeModalActive(false);
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default ProcessInfo;

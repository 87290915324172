import React, { useState } from 'react';
import * as S from './FactoryFactoryLineInfo.styles';
import FactoryLineModal from '@components/modules/pc/settings/manufacture_setting/FactoryLineModal/FactoryLineModal';
import { Factory, Todo } from '@lib/common/type';
import { FactoryLine } from '@lib/pc/settings/product_master_edit/type';

const FactoryFactoryLineInfo = ({
  manufactureName,
  allFactories,
  allFactoryLines,
}: {
  manufactureName: string;
  allFactories: Factory[] | undefined;
  allFactoryLines: FactoryLine[] | undefined;
}) => {
  const [factoryLineModalActive, setFactoryLineModalActive] = useState(false);
  const [selectedFactoryName, setSelectedFactoryName] = useState('');
  const [selectedLineId, setSelectedLineId] = useState(0);
  const [selectedLineName, setSelectedLineName] = useState('');

  return (
    <S.Wrapper>
      <S.HeadContainer className="head-container">
        <S.CompanyName>
          <span>会社名</span>
          {manufactureName}
        </S.CompanyName>
      </S.HeadContainer>

      <S.TRow>
        <S.TCell className="factory-name">工場名</S.TCell>
        <S.TCell>ライン名</S.TCell>
      </S.TRow>

      <S.TableContents className="table-contents" id="table-contents">
        {allFactories &&
          allFactories.map((factory: Factory, index: number) => {
            return (
              <React.Fragment key={index + factory.id}>
                <S.FactoryName key={index + factory.id + factory.name}>
                  {factory.name}
                </S.FactoryName>
                <S.ListUl key={index}>
                  {allFactoryLines &&
                    allFactoryLines.map((allLines: Todo) =>
                      allLines
                        .filter((al: Todo) => al.factoryId === factory.id)
                        .map((l: FactoryLine) => (
                          <S.LineNameRow
                            key={l.id}
                            className="hover-effect"
                            onClick={() => {
                              setFactoryLineModalActive(true);
                              setSelectedFactoryName(factory.name);
                              setSelectedLineId(l.id);
                              setSelectedLineName(l.name);
                            }}
                          >
                            <S.LineName>{l.name}</S.LineName>
                          </S.LineNameRow>
                        ))
                    )}
                </S.ListUl>
              </React.Fragment>
            );
          })}
        {factoryLineModalActive && (
          <FactoryLineModal
            selectedFactoryName={selectedFactoryName}
            selectedLineName={selectedLineName}
            selectedLineId={selectedLineId}
            setFactoryLineModalActive={setFactoryLineModalActive}
          />
        )}
      </S.TableContents>
    </S.Wrapper>
  );
};

export default FactoryFactoryLineInfo;

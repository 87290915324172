import { useState } from 'react';
import * as S from './StoreroomInfo.styles';
import { STOREROOM_TYPE, Todo } from '@lib/common/type';
import {
  initStoreroomData,
  Storeroom,
} from '@lib/pc/settings/manufacture_setting/type';
import StoreroomCreateEditModal from '../StoreroomCreateEditModal/StoreroomCreateEditModal';
import UpdatePopUp from '../../common/UpdatePopUp/UpdatePopUp';

type Props = {
  storerooms: Storeroom[] | undefined;
  manufactureName: string;
  storeroomsRefetch: () => void;
};

const StoreroomInfo = ({
  storerooms,
  manufactureName,
  storeroomsRefetch,
}: Props) => {
  const [storeroomCreateEditModalActive, setStoreroomCreateEditModalActive] =
    useState(false);
  const [selectedStoreroom, setSelectedStoreroom] = useState(initStoreroomData);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [msgKind, setMsgKind] = useState('');

  const handleClickCell = (e: Todo) => {
    return new Promise<void>((resolve) => {
      setSelectedStoreroom(e);
      resolve();
    }).then(() => {
      setStoreroomCreateEditModalActive(true);
    });
  };

  return (
    <S.Wrapper>
      <S.HeadContainer className="head-container">
        <S.CompanyName>
          <span>会社名</span>
          {manufactureName}
        </S.CompanyName>

        <S.ControlArea className="control-area">
          {/* 新規登録ボタン */}
          <S.ButtonWrapper>
            <S.ButtonContainer
              onClick={() => {
                setUpdatePopUp(false);
                setStoreroomCreateEditModalActive(true);
                setSelectedStoreroom(initStoreroomData);
              }}
            >
              ＋ 新規登録
            </S.ButtonContainer>
          </S.ButtonWrapper>
        </S.ControlArea>
      </S.HeadContainer>

      <S.TRow>
        <S.TCell className="storeroom_code">コード</S.TCell>
        <S.TCell className="storeroom_name">在庫室名</S.TCell>
        <S.TCell>在庫室タイプ</S.TCell>
      </S.TRow>

      <S.TableContents className="table-contents" id="table-contents">
        {storerooms &&
          storerooms.map((storeroom: Storeroom, index: number) => {
            return (
              <S.StoreroomRow
                key={storeroom.id + index}
                className="hover-effect"
                onClick={() => {
                  handleClickCell(storeroom);
                  setUpdatePopUp(false);
                  setStoreroomCreateEditModalActive(true);
                }}
              >
                <S.StoreroomInfo className="storeroom_code">
                  {storeroom.code}
                </S.StoreroomInfo>
                <S.StoreroomInfo className="storeroom_name">
                  {storeroom.name}
                </S.StoreroomInfo>
                <S.StoreroomInfo>
                  {storeroom.storeroomType === STOREROOM_TYPE.OWN_STOREROOM
                    ? '自社倉庫'
                    : storeroom.storeroomType ===
                      STOREROOM_TYPE.THIRD_PARTY_STOREROOM
                    ? '社外倉庫'
                    : '店舗'}
                </S.StoreroomInfo>
              </S.StoreroomRow>
            );
          })}
      </S.TableContents>

      {storeroomCreateEditModalActive && (
        <StoreroomCreateEditModal
          selectedStoreroom={selectedStoreroom}
          setStoreroomCreateEditModalActive={setStoreroomCreateEditModalActive}
          storeroomsRefetch={storeroomsRefetch}
          setUpdatePopUp={setUpdatePopUp}
          setMsgKind={setMsgKind}
        />
      )}
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={msgKind}
        handleClose={() => setUpdatePopUp(false)}
      />
    </S.Wrapper>
  );
};

export default StoreroomInfo;

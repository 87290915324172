import React from 'react';
import * as S from './ProcessTable.styles';
import { Todo } from '@lib/common/type';

type Props = {
  processName: string;
  isBothProductsKindExist: boolean;
  processes: Todo;
};

const ProcessTable = ({
  processName,
  isBothProductsKindExist,
  processes,
}: Props) => {
  return (
    <S.ProductListContainer isBothProductsKindExist={isBothProductsKindExist}>
      <S.ProductListWrapper>
        {processes?.length > 0 ? (
          <S.ProcessNameWrapper
            processName={processName === '完成品' ? true : false}
            key={processName}
          >
            {processName}
          </S.ProcessNameWrapper>
        ) : (
          <>{}</>
        )}
        <S.TRow>
          <S.TCell className="factory-name">{processName}名</S.TCell>
          <S.TCell>工程名</S.TCell>
        </S.TRow>

        <S.TableContents>
          {processes?.length > 0 &&
            processes?.map((process: Todo, index: number) => {
              return (
                <React.Fragment key={process?.processName + index}>
                  <S.ProcessName key={process?.name + index}>
                    {process?.name}
                  </S.ProcessName>
                  <S.ListUl>
                    {process?.processName?.map((processName: string) => {
                      return (
                        <S.LineNameRow key={processName + index}>
                          {processName}
                        </S.LineNameRow>
                      );
                    })}
                  </S.ListUl>
                </React.Fragment>
              );
            })}
        </S.TableContents>
      </S.ProductListWrapper>
    </S.ProductListContainer>
  );
};

export default ProcessTable;

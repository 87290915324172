export type FactoryLine = {
  id: number;
  name: string;
  factoryId: number;
  factoryName: string;
};

export type StoreroomWithDisplayOrder = {
  id: number;
  name: string;
  displayOrder: number | string;
  editMode: boolean;
};

export type InputParam = {
  name: string;
};

export const PRODUCT = 'product';
export const SEMI_PRODUCT = 'semiProduct';
export const MATERIAL = 'material';
export const ROW_MATERIAL = 'rowMaterial';

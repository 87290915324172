import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useMutation, useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import {
  AllStoreroomStocks,
  InputParams,
  UpdatedProduct,
} from '@lib/pc/stock_status/type';
import type { DetailItem, Stocks, Product, Todo } from '@lib/common/type';

export const useStocks = (date: Date, storeroomId: string) => {
  const { data, refetch } = useQuery(
    ['stocks', date, storeroomId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/stocks?date=${moment(date).format(
          'YYYYMMDD'
        )}&storeroom_id=${storeroomId}`
      );

      return data;
    }
  );

  // const stocks = (data && humps.camelizeKeys(data.data.products)) as Stocks[];
  const stocksInProducts = (data &&
    humps.camelizeKeys(data.data.products)) as Product[];

  return { stocksInProducts, refetch };
};

export const useStocksForOneProduct = (
  productId: number | string | undefined,
  stockKind: number | string,
  storeroomId: number | string
) => {
  return useQuery(['stocks', productId, stockKind, storeroomId], async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/stocks/${productId}?date=${moment().format(
        'YYYYMMDD'
      )}&stock_kind=${stockKind}&storeroom_id=${storeroomId}`
    );
    return humps.camelizeKeys(data.stocks.stocks) as Stocks[];
  });
};

export const useUpdateStockData = (
  productId: string | number,
  stockKind: string,
  date: Date,
  selectedDateString: string,
  storeroomId: string | number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void,
  mutate: (
    key: string,
    data: UpdatedProduct,
    shouldRevalidate?: boolean
  ) => void,
  stocksInProducts: Product[]
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().patch(
        `/api/v1/stocks/${productId}?stock_kind=${stockKind}&date=${moment(
          date
        ).format(
          'YYYY-MM-DD'
        )}&selected_date=${selectedDateString}&storeroom_id=${storeroomId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: (response) => {
        const updatedData =
          stockKind === '1'
            ? response.data.product
            : response.data.semi_product;

        const updatedProduct = humps.camelizeKeys(updatedData);
        const updatedProducts = stocksInProducts.map((product) => {
          if (
            'id' in updatedProduct &&
            product.id === (updatedProduct as { id: number }).id
          ) {
            return updatedProduct;
          }
          return product;
        });

        mutate(
          'filteredProducts',
          updatedProducts as unknown as UpdatedProduct,
          false
        );
        handleSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

// StockAmountDetailテーブルのレコード削除;
export const useDeleteStockMutation = (
  actualSemiProductOrProductStockId: number | undefined,
  stockKind: string,
  amountDetailId: number | null,
  selectedDateString: string,
  storeroomId: string | number,
  handleDeleteSuccess: () => void,
  mutate: (key: string, data: Todo, shouldRevalidate?: boolean) => void,
  stocksInProducts: Product[]
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/stocks/${actualSemiProductOrProductStockId}?stock_kind=${stockKind}&amount_detail_id=${amountDetailId}&date=${selectedDateString}&storeroom_id=${storeroomId}`
      );
    },
    {
      onSuccess: (response) => {
        const updatedData = response.data.product;
        const updatedProduct = humps.camelizeKeys(updatedData);
        const updatedProducts = stocksInProducts.map((product) => {
          if (
            'id' in updatedProduct &&
            product.id === (updatedProduct as { id: number }).id
          ) {
            return updatedProduct;
          }
          return product;
        });
        mutate('filteredProducts', updatedProducts, false);
        handleDeleteSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export const useAllStoreroomsStock = (
  date: Date,
  productId: string | number,
  stockKind: number
) => {
  return useQuery(
    ['allStoreroomsStocks', date, productId, stockKind],
    async () => {
      const { data } = await axiosInstance().get(
        `/api/v1/stocks/all_storerooms/${productId}?&date=${moment(date).format(
          'YYYY-MM-DD'
        )}&stock_kind=${stockKind}`
      );
      return humps.camelizeKeys(data) as unknown as AllStoreroomStocks;
    }
  );
};

// 前回情報の取得
export const useDuplicateLatestStock = (
  productId: number | string,
  storeroomId: number | string,
  date: Date,
  stockKind: number | string
) => {
  const { data } = useQuery(
    ['duplicate_stocks', productId, storeroomId, date, stockKind],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/stocks/${productId}/${storeroomId}?date=${moment(date).format(
          'YYYY-MM-DD'
        )}&stock_kind=${stockKind}`
      );

      return data;
    }
  );
  const duplicateLatestStock = humps.camelizeKeys(
    data?.data?.stocks?.amount_detail
  ) as DetailItem[];

  return { duplicateLatestStock };
};

// 予定在庫の取得
export const useDuplicateEstimatedStock = (
  productId: number | string,
  storeroomId: number | string,
  date: Date,
  stockKind: number | string
) => {
  const { data } = useQuery(
    [
      `duplicate_estimated_stocks${productId}`,
      productId,
      storeroomId,
      date,
      stockKind,
    ],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/estimated_stocks/${productId}/${storeroomId}?date=${moment(
          date
        ).format('YYYY-MM-DD')}&stock_kind=${stockKind}`
      );

      return data;
    }
  );
  const duplicateEstimatedStock = humps.camelizeKeys(
    data?.data?.stocks?.amount_detail
  ) as DetailItem[];

  return { duplicateEstimatedStock };
};

import styled from 'styled-components';
import { FixedSizeList } from 'react-window';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.beige};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-right: 0.8rem;
`;

export const TitleText = styled.p`
  padding-right: 0.7rem;
  padding-left: 1rem;
  font-size: medium;
  font-feature-settings: 'palt';
`;

export const SearchConditionList = styled.div`
  position: sticky;
  top: 0;
  padding: 1rem;
  background: ${(props) => props.theme.palette.beige};
  min-height: 125px;
`;

export const ProductList = styled.div``;

export const StoreroomPanel = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  width: 67%;
  height: 2.5rem;
  padding: 0 0.75rem;
  border-radius: 0.5rem;
  font-size: 16px;
  color: ${(props) => props.theme.palette.khaki};
  background: ${(props) => props.theme.palette.white};

  &:nth-of-type(1) {
    margin-top: 0;
  }
`;

export const StorageText = styled.p`
  margin-left: 0.75rem;
  font-weight: 400;
`;

export const ExpandMore = styled.p`
  margin: 0 0 0 auto;
`;

export const SelectStoreroomText = styled.div`
  padding: 1rem;
`;

export const NoProductsToShow = styled.div`
  width: 100vw;
  padding: 1rem;
  margin-left: 5%;
  font-weight: 700;
  & > div {
    font-weight: 300;
    font-size: 14px;
    padding-top: 1rem;
  }
`;

export const StyledFixedSizeList = styled(FixedSizeList)``;

export const CenteredCircularProgress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 240px);
`;

import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { Stocks } from '@lib/common/type';

export const useStocksForOneProduct = (
  productId: number | string,
  stockKind: number | string,
  storeroomId: number | string
) => {
  const { data, refetch } = useQuery(
    ['stocks', productId, stockKind, storeroomId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/stocks/${productId}?date=${moment().format(
          'YYYYMMDD'
        )}&stock_kind=${stockKind}&storeroom_id=${storeroomId}`
      );

      return data;
    }
  );
  const stocksForOneProduct = humps.camelizeKeys(
    data?.data.stocks.stocks
  ) as Stocks[];

  return { stocksForOneProduct, refetch, productName: data?.data.stocks.name };
};

export default useStocksForOneProduct;

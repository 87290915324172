import useSWR from 'swr';

export const SELECTED_STOREROOM_KEY = 'selectedStoreroom';
export const SELECTED_STOREROOM_NAME_KEY = 'selectedStoreroomName';

export const useSelectedStoreroom = () => {
  const { data: selectedStoreroomId, mutate: mutateId } = useSWR(
    SELECTED_STOREROOM_KEY,
    null,
    {
      fallbackData: '',
    }
  );

  const { data: selectedStoreroomName, mutate: mutateName } = useSWR(
    SELECTED_STOREROOM_NAME_KEY,
    null,
    {
      fallbackData: '',
    }
  );

  const setSelectedStoreroomId = (id: number | '') => {
    mutateId(id);
  };

  const setSelectedStoreroomName = (name: string) => {
    mutateName(name);
  };

  return {
    selectedStoreroomId,
    setSelectedStoreroomId,
    selectedStoreroomName,
    setSelectedStoreroomName,
  };
};

import React, { FC, useMemo } from 'react';
import { Todo } from '@lib/common/type';
import * as S from './TableNameRow.styles';
import { replaceContent } from '@lib/common/functions';

type Props = {
  index: number;
  style: React.CSSProperties;
  products: Todo;
  handleOpenProductDetail: (
    productId: number,
    productNameAbbreviation: string,
    productSafetyStockDays: string
  ) => void;
  selectedTypeIds: number[];
  lastOfProductProductionResults: boolean;
  lastOfDemandForecasts: boolean;
  lastOfShippings: boolean;
  lastOfSafetyStocksComponents: boolean;
  lastOfEstimatedProductStocks: boolean;
};

const TableNameRow: FC<Props> = ({
  index,
  style,
  products,
  handleOpenProductDetail,
  selectedTypeIds,
  lastOfProductProductionResults,
  lastOfDemandForecasts,
  lastOfShippings,
  lastOfSafetyStocksComponents,
  lastOfEstimatedProductStocks,
}: Props) => {
  const functionTypeObj = [
    { id: 0, type: '生産計画' },
    { id: 1, type: '生産実績' },
    { id: 2, type: '需要予測' },
    { id: 3, type: '出荷実績' },
    { id: 4, type: '理想在庫' },
    { id: 5, type: '予定在庫' },
    { id: 6, type: '実在庫' },
  ];

  // 表示名箇所lastセル判定
  const lastOfName = (id: number) => {
    if (selectedTypeIds.length === 0) {
      return true;
    } else if (id === 1 && lastOfProductProductionResults) {
      return true;
    } else if (id === 2 && lastOfDemandForecasts) {
      return true;
    } else if (id === 3 && lastOfShippings) {
      return true;
    } else if (id === 4 && lastOfSafetyStocksComponents) {
      return true;
    } else if (id === 5 && lastOfEstimatedProductStocks) {
      return true;
    } else if (id === 6) {
      return true;
    } else {
      return false;
    }
  };

  const product = useMemo(() => {
    if (!products || index >= products.length || !products[index]) {
      return <div style={style}>Loading...</div>;
    }
    return products[index];
  }, [products]);

  const productNames = useMemo(() => {
    return (
      <React.Fragment key={`${index}-${product.productCode}-product`}>
        <div style={style}>
          <S.ListSidebar className="list-sidebar">
            <S.ListLeftHeadProductCell
              className="list-left-product-cell"
              key={`${index}-${product.id}`}
              productIndex={index}
            >
              <S.ListTbody>
                <S.ListLeftHeadNameAndCode
                  key={`${index}-${product.id}-${product.productCode}`}
                  productIndex={index}
                  onClick={() =>
                    handleOpenProductDetail(
                      product.id,
                      product.nameAbbreviation
                        ? product.nameAbbreviation
                        : product.name,
                      !product.safetyStockDays
                        ? '3'
                        : String(product.safetyStockDays)
                    )
                  }
                >
                  {/* 商品名 */}
                  <S.ListProductNameAndCode>
                    <S.ListProductName>
                      {replaceContent(
                        product.nameAbbreviation
                          ? product.nameAbbreviation
                          : product.name,
                        12.5
                      )}
                    </S.ListProductName>
                    {/* 商品コード */}
                    {product.productCode}
                  </S.ListProductNameAndCode>
                </S.ListLeftHeadNameAndCode>
                <div className="type-name">
                  {functionTypeObj
                    .filter((value) => selectedTypeIds.includes(value.id))
                    .map((value, j) => {
                      return (
                        <S.ListLeftHeadTypeName
                          key={`${index}-${j}-${value.id}`}
                          typeIndex={j}
                          productIndex={index}
                          notDisplay={
                            !selectedTypeIds.includes(value.id) ? true : false
                          }
                          last={lastOfName(value.id)}
                        >
                          {value.type}
                        </S.ListLeftHeadTypeName>
                      );
                    })}
                </div>
              </S.ListTbody>
            </S.ListLeftHeadProductCell>
          </S.ListSidebar>
        </div>
      </React.Fragment>
    );
  }, []);

  return productNames;
};

export default TableNameRow;

import React, { useEffect } from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import * as S from './ProductProductionResultMainTable.styles';
import { ReactComponent as AddIcon } from '@assets/icons/add.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import { ReactComponent as DuplicateIcon } from '@assets/icons/duplicate.svg';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import { factoryLineBgColors } from '@lib/pc/common/type';
import { TABLE_HEADER } from './header';
import { seeFullComment } from '@lib/pc/common/functions';
import { Todo } from '@lib/common/type';
import { defaultEditData } from '@lib/pc/product_production_result/type';
// import BestBeforeDateInfo from '@components/modules/common/pc/BestBeforeDateInfo/BestBeforeDateInfo';

const valueOfLineBgColors = Object.values(factoryLineBgColors);

type Props = {
  selectedLineIndex: number;
  data: Todo;
  setEditData: Todo;
  setPopUp: (popUp: boolean) => void;
  setUseDuplicateFunc: (useDuplicateFunc: boolean) => void;
  setScanning: (scanning: boolean) => void;
  setRegisteredProductIds: (registeredProductIds: number[]) => void;
};

const ProductProductionResultMainTable = ({
  selectedLineIndex,
  data,
  setEditData,
  setPopUp,
  setUseDuplicateFunc,
  setScanning,
  setRegisteredProductIds,
}: Props) => {
  if (!data?.lines[selectedLineIndex])
    return (
      <S.CircularIconWrapperOuter>
        <S.CircularIconWrapper>
          <CircularProgress style={{ color: '#64b2f9' }} />
        </S.CircularIconWrapper>
      </S.CircularIconWrapperOuter>
    );

  const onClickDuplicate = (p: Todo, i: number) => {
    setEditData({
      lineName: data?.lines[selectedLineIndex].name,
      lineIndex: selectedLineIndex,
      piecesPerBox:
        data?.lines[selectedLineIndex].productionResultDetails[i]
          .piecesPerBox || 1,
      productsProcessId: p.productsProcessId,
      productId: p.productId,
      storeroomId: p.storeroomId,
      caseId: p.caseId,
      bestBeforeDate: p.bestBeforeDate,
      shippingDate: p.shippingDate,
      productionDateOfSemiProduct: p.productionDateOfSemiProduct,
      employeeIds: p.employeeIds,
      expiryDays: p.expiryDays,
      labelOfAmount: p.labelOfAmount,
      piece: null,
      amount: null,
      case: null,
      defectiveSemiProductAmount: null,
      checkTime: null,
      cart: null,
      detailId: null,
      plannedAmount: null,
      plannedCase: null,
      plannedPiece: null,
    });
    setPopUp(false);
    setUseDuplicateFunc(true);
  };

  useEffect(() => {
    const registeredProductIdsAtTable = data?.lines[
      selectedLineIndex
    ]?.productionResultDetails.map((item: Todo) => item['productId']);
    setRegisteredProductIds(registeredProductIdsAtTable);
  }, [data?.lines[selectedLineIndex]?.productionResultDetails.length]);

  return (
    <>
      <S.Wrapper bgColor={valueOfLineBgColors[selectedLineIndex % 10]}>
        <S.Content>
          <S.Table>
            <tbody>
              {/* テーブルヘッダー */}
              <S.THead bgColor={valueOfLineBgColors[selectedLineIndex % 10]}>
                {TABLE_HEADER.map((t, index: number) => (
                  <S.THeadCell
                    key={t.key + index}
                    width={t.width}
                    wordBreak={t.wordBreak}
                    colSpan={t.colSpan}
                  >
                    {t.key}
                  </S.THeadCell>
                ))}
              </S.THead>
              {data &&
                data?.lines[selectedLineIndex]?.productionResultDetails.map(
                  (p: Todo, index: number) => (
                    <React.Fragment
                      key={
                        index + p.detailId + selectedLineIndex + Math.random()
                      }
                    >
                      <S.TRow
                        key={index + p.detailId + Math.random()}
                        bgColor={valueOfLineBgColors[selectedLineIndex]}
                      >
                        {/* 編集 */}
                        <S.Icon
                          onClick={() => {
                            setEditData({
                              lineName: data?.lines[selectedLineIndex].name,
                              lineIndex: selectedLineIndex,
                              ...p,
                              piecesPerBox: p.piecesPerBox || 1,
                            });
                            setPopUp(false);
                            setUseDuplicateFunc(false);
                            setScanning(false);
                          }}
                        >
                          <PencilIcon />
                        </S.Icon>
                        {/* 製品名 */}
                        <S.TCell
                          rowSpan={2}
                          width="12.75rem"
                          marginRight={'12px'}
                          borderLeft
                          secondRow
                        >
                          {p.productName}
                        </S.TCell>
                        <S.TCell className="label">
                          予<br />定
                        </S.TCell>
                        <S.TCell
                          className="display-quantity display-quantity__count--case"
                          borderLeft
                          planned={true}
                        >
                          {/* 完成品 予定 ケース */}
                          <div className="display-quantity__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="num">{p.plannedCase}</span>
                                <span className="unit">ケース</span>
                              </div>
                              <div className="explanation">
                                x{p.piecesPerBox || 1}
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                            <div className="symbol">+</div>
                          </div>
                        </S.TCell>

                        <S.TCell
                          className="display-quantity display-quantity__count--quantity"
                          planned={true}
                        >
                          {/* 完成品 予定 個 */}
                          <div className="display-quantity__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="num">
                                  {p.plannedPiece ? p.plannedPiece : ''}
                                </span>
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                            <div className="symbol">=</div>
                          </div>
                        </S.TCell>
                        <S.TCell
                          className="display-quantity display-quantity__count--total"
                          planned={true}
                        >
                          {/* 完成品 予定 合計 */}
                          <div className="display-quantity__inner-wrap">
                            <div className="with-label__inner-wrap">
                              <div className="cell-content">
                                <div className="quantity">
                                  <span className="title">合計</span>
                                  <span className="num">
                                    {p.plannedAmount ? p.plannedAmount : ''}
                                  </span>
                                  <span className="unit">
                                    {p.labelOfAmount
                                      ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                      : '個'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </S.TCell>
                        {/* 半製品製造 */}
                        <S.TCell rowSpan={2} colSpan={1} borderLeft secondRow>
                          {p.productionDateOfSemiProduct &&
                            moment(p.productionDateOfSemiProduct).format(
                              'YY.MM.DD'
                            )}
                        </S.TCell>
                        {/* 出荷予定 */}
                        <S.TCell rowSpan={2} colSpan={1} borderLeft secondRow>
                          {p.shippingDate &&
                            moment(p.shippingDate).format('YY.MM.DD')}
                        </S.TCell>
                        {/* 賞味期限 */}
                        <S.TCell rowSpan={2} colSpan={1} borderLeft secondRow>
                          {p.bestBeforeDate &&
                            moment(p.bestBeforeDate).format('YY.MM.DD')}
                        </S.TCell>
                        {/* 時間 */}
                        <S.TCell
                          rowSpan={2}
                          className="time"
                          colSpan={1}
                          borderLeft
                          secondRow
                        >
                          {p.checkTime && moment(p.checkTime).format('LT')}
                        </S.TCell>
                        {/* 写真 */}
                        {/* <S.TCell colSpan={1} className="photo" borderLeft>
                          <BestBeforeDateInfo result={p} />
                        </S.TCell> */}
                        {/* 備考 */}
                        <S.TCell
                          rowSpan={2}
                          colSpan={1}
                          className="packaging-comment"
                          onClick={() => {
                            seeFullComment(index);
                          }}
                          borderLeft
                          secondRow
                        >
                          <div className="cell-content">{p.comment}</div>
                        </S.TCell>
                      </S.TRow>
                      {/* ----------2段目---------- */}
                      <S.TRow
                        key={index + p.detailId + Math.random()}
                        secondRow
                        bgColor={valueOfLineBgColors[selectedLineIndex]}
                        textColor={
                          p.plannedAmount > 0 &&
                          (p.amount === '' ||
                            p.amount === null ||
                            p.amount === 0)
                            ? true
                            : false
                        }
                      >
                        {/* 複製 */}
                        <S.Icon
                          onClick={() => {
                            onClickDuplicate(p, index);
                            setScanning(false);
                          }}
                          borderLeft
                        >
                          <DuplicateIcon />
                        </S.Icon>

                        <S.TCell className="label">
                          完<br />了
                        </S.TCell>
                        <S.TCell
                          className="display-quantity display-quantity__count--case"
                          borderLeft
                          textColor={
                            p.plannedAmount > 0 &&
                            (p.amount === '' ||
                              p.amount === null ||
                              p.amount === 0)
                              ? true
                              : false
                          }
                        >
                          {/* 完成品 ケース */}
                          <div className="display-quantity__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="num">{p.case}</span>
                                <span className="unit">ケース</span>
                              </div>
                              <div className="explanation">
                                x{p.piecesPerBox || 1}
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                            <div className="symbol">+</div>
                          </div>
                        </S.TCell>

                        <S.TCell className="display-quantity display-quantity__count--quantity">
                          {/* 完成品 個 */}
                          <div className="display-quantity__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="num">
                                  {p.piece ? p.piece : ''}
                                </span>
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                            <div className="symbol">=</div>
                          </div>
                        </S.TCell>
                        <S.TCell className="display-quantity display-quantity__count--total">
                          {/* 完成品 合計 */}
                          <div className="display-quantity__inner-wrap">
                            <div className="with-label__inner-wrap">
                              <div className="cell-content">
                                <div className="quantity">
                                  <span className="title">合計</span>
                                  <span className="num">
                                    {p.amount ? p.amount : ''}
                                  </span>
                                  <span className="unit">
                                    {p.labelOfAmount
                                      ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                      : '個'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </S.TCell>

                        {/* 写真 */}
                        {/* <S.TCell colSpan={1} className="photo" borderLeft>
                          <BestBeforeDateInfo result={p} />
                        </S.TCell> */}
                      </S.TRow>

                      <S.CommentBalloon
                        onClick={() => {
                          seeFullComment(index);
                        }}
                      >
                        <td
                          key={index + p.detailId + Math.random()}
                          id={`comment-full-${index}`}
                          colSpan={11}
                          className={'cell-content__balloon'}
                        >
                          <span className="comment-full__title">備考：</span>
                          <br />
                          {p.comment}
                        </td>
                      </S.CommentBalloon>
                    </React.Fragment>
                  )
                )}
              <tr>
                <td colSpan={11}>
                  <S.FlexTableDivRow
                    onClick={() => {
                      setEditData({
                        ...defaultEditData,
                        time: null,
                        lineName: data.lines[selectedLineIndex].name,
                        lineIndex: selectedLineIndex,
                      });
                      setPopUp(false);
                      setUseDuplicateFunc(false);
                      setScanning(false);
                    }}
                  >
                    <AddIcon />
                    追加
                  </S.FlexTableDivRow>
                </td>
              </tr>
            </tbody>
          </S.Table>
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default ProductProductionResultMainTable;

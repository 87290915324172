import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useStoreroom = (id: string | number) => {
  const { data, refetch } = useQuery('selected-employee', async () => {
    const data = await axiosInstance().get(`/api/v1/settings/storerooms/${id}`);
    return data;
  });

  const storeroom = humps.camelizeKeys(
    data?.data?.storeroom
  ) as unknown as Todo;
  return { storeroom, refetch };
};

export default useStoreroom;

import styled from 'styled-components';

export const Wrapper = styled.div`
  /* 個数紐付けリスト ヘッダー 入力エリア*/
  .material-piece-breakdown-list-header {
    margin-top: 24px;
    display: grid;
    grid-template-areas: 'piece-breakdown-list-header__inputs';
    &__inputs {
      grid-template-areas: 'close-btn product-name product-number-wrapper label-of-amount add-btn';
      grid-area: piece-breakdown-list-header__inputs;
      display: grid;
      grid-template-columns: 40px auto 230px 90px 80px;
      .product-name {
        grid-area: product-name;
        display: grid;
        dt {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
    .product-number-wrapper {
      grid-area: product-number-wrapper;
      width: auto;
      display: grid;
      justify-content: center;
      align-items: center;
      .product-number {
        grid-area: product-number;
        display: grid;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 16px;
        dt {
          text-align: left;
          padding-bottom: 8px;
        }
        dd > input {
          text-align: end;
          padding: 0.75rem;
        }
      }
    }
    .label-of-amount {
      margin-top: 42px;
      text-align: center;
    }
    .add-btn {
      grid-area: add-btn;
      display: grid;
      padding-top: 20px;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      padding-right: 19px;
      top: 0;
      button {
        width: 64px;
        display: block;
        &.disabled {
          background: #e0e0e0;
          border-color: #e0e0e0;
          color: #6b675c;
          cursor: initial;
        }
      }
    }
  }
  /* 個数紐付けリスト コンテンツ 表示エリア*/
  .piece-breakdown-list-contents {
    /* 各商品 ul */
    &__pieces {
    }
    /* 各商品 li */
    &__piece {
      min-height: 40px;
      display: grid;
      grid-template-columns: 40px auto 230px 90px;
      grid-template-rows: auto auto auto;
      grid-template-areas: 'close-btn product-name product-pieces-num label-of-amount';
      align-items: center;
      border-bottom: solid 1px #cdcdcd;
      /* closeボタン、editボタン */
      .close-btn,
      .edit-btn {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .edit-btn {
        margin-left: auto;
      }
      /* 商品画像 */
      .product-img {
        width: 40px;
        height: 40px;
      }
      /* 商品名 */
      .product-name {
        padding-left: 14px;
      }
      /* 数量 */
      .product-pieces-num {
        text-align: right;
        padding-left: 18px;
      }
      .label-of-amount {
        text-align: center;
      }
    }
    /* 合計 */
    &__sum {
      display: grid;
      grid-template-columns: 48px 48px auto 64px 88px;
      grid-template-areas: '. sum__title sum__number-of-types sum__sum sum__exp';
      align-items: center;
      padding: 16px 0;
      border-top: solid 1px #cdcdcd;
      margin-top: 2px;
      .sum__title {
        grid-area: sum__title;
      }
      .sum__number-of-types {
        grid-area: sum__number-of-types;
      }
      .sum__sum {
        grid-area: sum__sum;
      }
      .sum__exp {
        grid-area: sum__exp;
      }
    }
  }
`;

// 各ひも付け項目のタイトル
export const ConstituentTitle = styled.div`
  margin: 24px 0 4px 40px;
  font-weight: 700;
`;

export const ConstituentTitleLi = styled.div`
  min-height: 40px;
  display: grid;
  grid-template-columns: 40px auto 230px 90px;
  grid-template-areas: 'close-btn product-name product-pieces-num label-of-amount';
  align-items: center;
  .pieces-num {
    text-align: end;
  }
  .label-of-amount {
    text-align: center;
  }
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const PlusIcon = styled.div`
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

import { Todo } from '@lib/common/type';
import * as S from './StoreroomsStocksModal.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';

type props = {
  storeroomName: string;
  productKind: string;
  stocksName: string[];
  setStocksName: (stocksName: string[]) => void;
};

const StoreroomsStocksModal = ({
  storeroomName,
  productKind,
  stocksName,
  setStocksName,
}: props) => {
  const handleClose = () => {
    setStocksName([]);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderInfo>
          {productKind === 'Product' ? (
            <S.BlueBox>完成品</S.BlueBox>
          ) : productKind === 'SemiProduct' ? (
            <S.WhiteBox>半製品</S.WhiteBox>
          ) : productKind === 'RowMaterial' ? (
            <S.BlueBox>原材料</S.BlueBox>
          ) : (
            <S.WhiteBox>資材</S.WhiteBox>
          )}
          <S.StoreroomsName>{storeroomName}</S.StoreroomsName>
        </S.HeaderInfo>
        <S.ModalHeadCloseIcon>
          <CloseIcon onClick={handleClose} />
        </S.ModalHeadCloseIcon>
      </S.Header>

      <S.StockNamesWrapper>
        {stocksName &&
          stocksName.map((stock: Todo, idx: number) => {
            return <S.StocksRow key={idx}>{stock.name}</S.StocksRow>;
          })}
      </S.StockNamesWrapper>
    </S.Wrapper>
  );
};

export default StoreroomsStocksModal;

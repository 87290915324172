import useSWR from 'swr';

export const SEARCH_CHAR_KEY = 'searchChar';

export const useSearchChar = () => {
  const { data: searchChar, mutate } = useSWR(SEARCH_CHAR_KEY, null, {
    fallbackData: '',
  });

  const setSearchChar = (value: string) => {
    mutate(value);
  };

  return {
    searchChar,
    setSearchChar,
  };
};

import { FC } from 'react';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { useDeviceOrientationAndSize } from '@lib/common/functions';

type Props = {
  processes: Todo[] | undefined;
  value: number | undefined;
  setSelectedProcessId?: (selectedProcessId: number) => void;
  onChange?: (selectedCaseId: number, index?: number) => void;
  disabled?: boolean;
  setSelectedProcessName?: (selectedProcessName: string) => void;
};

const ProcessSelect: FC<Props> = ({
  processes,
  value,
  setSelectedProcessId,
  onChange,
  disabled,
  setSelectedProcessName,
}: Props) => {
  const { deviceType } = useDeviceOrientationAndSize();
  const isDesktop = deviceType === 'desktop';

  const handleChangeCase = (e: number) => {
    if (setSelectedProcessId) {
      setSelectedProcessId(e);
    }
  };

  const toReactSelectOptions = (
    options: { processId: number; processName: string }[]
  ) =>
    options.map(({ processId, processName }) => ({
      value: processId,
      label: processName,
    }));

  const reactSelectOptions = processes && toReactSelectOptions(processes);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '100%',
      height: '40px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      marginTop: '0px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '100px',
    }),
  };

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue ? currentValue : null}
      onChange={(option) => {
        option && handleChangeCase(option.value);
        option && onChange?.(option.value);
        option && setSelectedProcessName?.(option.label);
      }}
      styles={styles}
      isDisabled={disabled}
      isSearchable={isDesktop}
      components={{
        IndicatorSeparator: () => null,
      }}
      placeholder="工程の選択"
      noOptionsMessage={() => '工程を登録してください'}
    />
  );
};

export default ProcessSelect;

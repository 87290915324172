import ReactSelect from 'react-select';
import * as S from './StoreTypeSelect.styles';
import { Todo } from '@lib/common/type';

type props = {
  width: string;
  options: Todo;
  value: number;
  disabled?: boolean;
  error?: boolean;
  onChange: (value: number) => void;
};

const StoreTypeSelect = ({
  width,
  options,
  value,
  disabled,
  error,
  onChange,
}: props) => {
  const storeTypeObjects =
    options &&
    Object.values(options)?.map((value, key) => ({
      id: key,
      name: value,
    }));

  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const reactSelectOptions = toReactSelectOptions(storeTypeObjects);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  return (
    <S.Wrapper>
      <ReactSelect
        options={reactSelectOptions}
        value={currentValue ? currentValue : null}
        onChange={(option) => option && onChange(option.value)}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            width: width,
            height: '40px',
            borderRadius: '0.5rem',
            borderWidth: 0,
            outline: 'solid 1px rgba(0, 0, 0, 0.08)',
            backgroundColor: disabled ? '' : 'white',
            border: error ? `1px solid #C74141` : '',
            padding: '0 15px',
            cursor: 'pointer',
          }),
          menu: (baseStyles: Todo) => ({
            ...baseStyles,
            top: '34px',
            zIndex: 1002,
          }),
        }}
        components={{
          DropdownIndicator: () => <S.SelectIndicator />,
          IndicatorSeparator: () => null,
        }}
        isSearchable={false}
        isDisabled={disabled}
        placeholder="選択してください"
      />
    </S.Wrapper>
  );
};

export default StoreTypeSelect;

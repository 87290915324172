import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import * as S from './ProductsUsedList.styles';
import { Todo } from '@lib/common/type';

type Props = {
  products: Todo[];
  productKind: string;
};

const ProductsUsedList = ({ products, productKind }: Props) => {
  return (
    <S.Wrapper>
      {productKind === 'Product' ? (
        <S.ProductKind>完成品</S.ProductKind>
      ) : productKind === 'SemiProduct' ? (
        <S.SemiProductKind>半製品</S.SemiProductKind>
      ) : productKind === 'RowMaterial' ? (
        <S.SemiProductKind>原材料</S.SemiProductKind>
      ) : (
        <S.SemiProductKind>資材</S.SemiProductKind>
      )}
      <S.ContentsWrapper>
        {products?.map((p: Todo, i: number) => {
          return (
            <S.OneLine key={i}>
              <S.Code>
                {productKind === 'Product' ? p.productCode : p.semiProductCode}
              </S.Code>
              <S.NameAndUsageAmount>
                {p.name}
                <div>
                  {p.usageAmount}
                  {p?.labelOfAmount
                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                    : '個'}
                </div>
              </S.NameAndUsageAmount>
            </S.OneLine>
          );
        })}
      </S.ContentsWrapper>
    </S.Wrapper>
  );
};

export default ProductsUsedList;

import * as S from './ProcessNameChangeModal.styles';
import { Button } from '@components/elements/buttons/Button';
import { Heading } from '@components/elements/typographies/Heading';

type Props = {
  processNameAtModal: string;
  handleChangeProcessNameAtModal: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleUpdateProcessName: () => void;
  handleCancel: () => void;
};

const ProcessNameChangeModal = ({
  processNameAtModal,
  handleChangeProcessNameAtModal,
  handleUpdateProcessName,
  handleCancel,
}: Props) => {
  return (
    <S.Wrapper>
      <S.FlexBaseline>
        <Heading tag="span" text="工程名の変更" fs="24" fw="lg" />
      </S.FlexBaseline>
      <div className="product-number">
        <dd>
          <S.Input
            type="text"
            inputMode="text"
            name="pieces"
            id="pieces"
            autoComplete="off"
            align="left"
            padding="0 5px"
            value={processNameAtModal ? processNameAtModal : ''}
            onChange={handleChangeProcessNameAtModal}
          />
        </dd>
      </div>
      <S.ButtonContainer>
        <Button borderWidth={1} outlined={true} onClick={handleCancel}>
          キャンセル
        </Button>
        <Button
          borderWidth={1}
          onClick={() => {
            handleUpdateProcessName();
          }}
        >
          保存
        </Button>
      </S.ButtonContainer>
    </S.Wrapper>
  );
};

export default ProcessNameChangeModal;

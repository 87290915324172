import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';
import moment, { Moment } from 'moment';
import { useSWRConfig } from 'swr';

type InputParams = {
  amountDetail: Todo[];
  comment: string;
};

const useUpdateMaterialStocksMutation = (
  materialId: string | number,
  date: Date,
  selectedDate: Moment,
  storeroomId: string | number,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  const { mutate } = useSWRConfig();

  return useMutation(
    async (params: InputParams) => {
      const response = await axiosInstance().patch(
        `/api/v1/material_stocks/${materialId}?` +
          `&date=${moment(date).format('YYYY-MM-DD')}` +
          `&selected_date=${selectedDate.format('YYYY-MM-DD')}` +
          `&storeroom_id=${storeroomId}`,
        humps.decamelizeKeys({
          actualMaterialStock: {
            comment: params.comment ? params.comment : '',
            amount_detail: params.amountDetail,
          },
        })
      );
      return humps.camelizeKeys(response.data);
    },
    {
      onSuccess: (data) => {
        mutate(
          'filteredMaterials',
          (currentData: MaterialStock[]) => {
            if (!currentData) return currentData;

            return currentData.map((item) =>
              item.id === data.materialStock.id ? data.materialStock : item
            );
          },
          false
        );

        handleUpdateSuccess?.();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useUpdateMaterialStocksMutation;

import { useState } from 'react';
import * as S from './StoreroomContents.styles';
import { Button } from '@components/elements/buttons/Button';
import { Heading } from '@components/elements/typographies/Heading';
import { CircularProgress } from '@material-ui/core';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import { Todo } from '@lib/common/type';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import StoreTypeSelect from '../StoreTypeSelect/StoreTypeSelect';
import useAllStoreroomTypes from '@lib/pc/settings/manufacture_setting/useAllStoreroomTypes';
import StoreroomsStocksModal from '../StoreroomsStocksModal/StoreroomsStocksModal';
import useUpdateStoreroomMutation from '@lib/pc/settings/manufacture_setting/useUpdateStoreroom';
import { initStoreroomData } from '@lib/pc/settings/manufacture_setting/type';
import useCreateStoreroomMutation from '@lib/pc/settings/manufacture_setting/useCreateStoreroom';

type Props = {
  newData: Todo;
  setNewData: (newData: Todo) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  // setConfirmMsg: (confirmMsg: string) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  setStoreroomCreateEditModalActive: (
    storeroomCreateEditModalActive: boolean
  ) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  setMsgKind: (msgKind: string) => void;
  storeroomRefetch: () => void;
  storeroomsRefetch: () => void;
};

const StoreroomContents = ({
  newData,
  setNewData,
  updating,
  setUpdating,
  // setConfirmMsg,
  enableToSave,
  setEnableToSave,
  setStoreroomCreateEditModalActive,
  storeroomRefetch,
  storeroomsRefetch,
  setUpdatePopUp,
  setMsgKind,
}: Props) => {
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [selectedStoreTypeId, setSelectedStoreTypeId] = useState(
    newData?.storeroomType | 0
  );
  const [productKind, setProductKind] = useState('');
  const [stocksProductsName, setStocksProductsName] = useState<Todo[]>([]);

  const { allStoreroomTypes } = useAllStoreroomTypes();

  // 在庫室に保存されている製品の有無
  const isStoreroomsStocksAvailable =
    newData?.products?.length > 0 ||
    newData?.semiProducts?.length > 0 ||
    newData?.romMaterials?.length > 0 ||
    newData?.materials?.length > 0;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newData, [e.target.name]: e.target.value };
    setNewData(data);
    setEnableToSave(true);
  };

  const handleUpdateSuccess = () => {
    setUpdatePopUp(true);
    setUpdating(false);
    setEnableToSave(false);
    setStoreroomCreateEditModalActive(false);
    storeroomRefetch();
    storeroomsRefetch();
    setNewData(initStoreroomData);
    if (createMode) {
      setMsgKind('create');
    } else {
      setMsgKind('update');
    }
  };

  const handleChangeSelect = (e: number) => {
    setNewData({ ...newData, storeroomType: e });
    setSelectedStoreTypeId(e);
    setEnableToSave(true);
  };

  // 登録か保存か判定
  const createMode = newData?.id ? false : true;

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmitUpdate = () => {
    setUpdating(true);
    useUpdateStoreroom.mutate(newData);
  };

  const useUpdateStoreroom = useUpdateStoreroomMutation(
    newData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  const useCreateStoreroom = useCreateStoreroomMutation(
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    setUpdating(true);
    useCreateStoreroom.mutate(newData);
  };

  const handleClickCancel = () => {
    if (enableToSave) {
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setStoreroomCreateEditModalActive(false);
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    setStoreroomCreateEditModalActive(false);
    setStocksProductsName([]);
    setEnableToSave(false);
  };

  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  return (
    <>
      {newData ? (
        <>
          <S.HeadContainer>
            {createMode ? (
              <Heading tag="span" text="在庫室の登録" fs="32" fw="lg" />
            ) : (
              <Heading tag="span" text="在庫室の編集" fs="32" fw="lg" />
            )}
          </S.HeadContainer>
          <S.Wrapper>
            <S.ShippingGroupAreaDl>
              <dt>在庫室コード</dt>
              <dd>
                <S.Input
                  type="text"
                  inputMode="numeric"
                  name="code"
                  id="code"
                  autoComplete="off"
                  width={'25%'}
                  value={newData?.code ? newData?.code : ''}
                  onChange={handleChange}
                />
              </dd>
              <dt>在庫室名</dt>
              <dd>
                <S.Input
                  type="text"
                  inputMode="numeric"
                  name="name"
                  id="name"
                  autoComplete="off"
                  width={'25%'}
                  value={newData?.name ? newData?.name : ''}
                  onChange={handleChange}
                />
              </dd>
              <dt>在庫室タイプ</dt>
              <dd>
                <StoreTypeSelect
                  width={'100%'}
                  options={allStoreroomTypes}
                  value={selectedStoreTypeId}
                  onChange={handleChangeSelect}
                />
              </dd>
              {!createMode && isStoreroomsStocksAvailable && (
                <dt>在庫室に保存されている製品を確認</dt>
              )}
              <dd className="button-wrapper">
                {newData?.products?.length > 0 && (
                  <Button
                    children={'完成品'}
                    borderWidth={1}
                    width="157px"
                    onClick={() => {
                      setProductKind('Product');
                      setStocksProductsName(newData?.products);
                    }}
                    disabled={updating}
                  />
                )}
                {newData?.semiProducts?.length > 0 && (
                  <Button
                    children={'半製品'}
                    borderWidth={1}
                    width="157px"
                    outlined
                    onClick={() => {
                      setProductKind('SemiProduct');
                      setStocksProductsName(newData?.semiProducts);
                    }}
                    disabled={updating}
                  />
                )}
                {newData?.romMaterials?.length > 0 && (
                  <Button
                    children={'原材料'}
                    borderWidth={1}
                    width="157px"
                    onClick={() => {
                      setProductKind('RowMaterial');
                      setStocksProductsName(newData?.romMaterials);
                    }}
                    disabled={updating}
                  />
                )}
                {newData?.materials?.length > 0 && (
                  <Button
                    children={'資材'}
                    borderWidth={1}
                    width="157px"
                    outlined
                    onClick={() => {
                      setProductKind('Material');
                      setStocksProductsName(newData?.materials);
                    }}
                    disabled={updating}
                  />
                )}
              </dd>
            </S.ShippingGroupAreaDl>
          </S.Wrapper>
          <S.ButtonWrapper>
            <S.ButtonContainer>
              <Button
                children={'キャンセル'}
                borderWidth={1}
                outlined
                onClick={() => {
                  handleClickCancel();
                }}
                disabled={updating}
              />

              {createMode ? (
                <Button
                  children={updating ? '登録中...' : '登録'}
                  disabled={updating || !enableToSave}
                  onClick={handleSubmitCreate}
                />
              ) : (
                <Button
                  children={updating ? '保存中...' : '保存'}
                  disabled={updating || !enableToSave}
                  onClick={handleSubmitUpdate}
                />
              )}
            </S.ButtonContainer>
          </S.ButtonWrapper>
        </>
      ) : (
        <S.CenteredCircularProgress>
          <CircularProgress style={{ color: '#64b2f9' }} />
        </S.CenteredCircularProgress>
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {stocksProductsName?.length > 0 && (
        <StoreroomsStocksModal
          storeroomName={newData?.name}
          productKind={productKind}
          stocksName={stocksProductsName}
          setStocksName={setStocksProductsName}
        />
      )}
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default StoreroomContents;

import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useMutation } from 'react-query';
import moment from 'moment';
import { useSWRConfig } from 'swr';
import humps from 'humps';

// StockAmountDetailテーブルのレコード削除;
export const useDeleteMaterialStocksMutation = (
  amountDetailId: number | null,
  date: Date,
  selectedDate: moment.Moment,
  storeroomId: string,
  handleDeleteSuccess: () => void
) => {
  const { mutate } = useSWRConfig();

  return useMutation(
    async () => {
      const response = await axiosInstance().delete(
        `/api/v1/material_stocks/${amountDetailId}?` +
          `&date=${moment(date).format('YYYY-MM-DD')}` +
          `&selected_date=${selectedDate.format('YYYY-MM-DD')}` +
          `&storeroom_id=${storeroomId}`
      );
      return humps.camelizeKeys(response.data);
    },
    {
      onSuccess: (data) => {
        mutate(
          'filteredMaterials',
          (currentData: MaterialStock[]) => {
            if (!currentData) return currentData;

            return currentData.map((item) =>
              item.id === data.materialStock.id ? data.materialStock : item
            );
          },
          false
        );
        handleDeleteSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useDeleteMaterialStocksMutation;

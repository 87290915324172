import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 280px;
  cursor: pointer;
`;

export const SelectIndicator = styled.div`
  position: relative;

  &::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${(props) => props.theme.palette.blue};
    content: '';
    position: absolute;
    right: 12px;
    top: 0px;
    width: 0;
  }
`;

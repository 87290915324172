import styled from 'styled-components';

export const HeadContainer = styled.div`
  width: 100%;
  height: 88px;
  padding: 1.15rem 1.15rem 1.15rem 80px;
  background-color: ${(props) => props.theme.palette.white};
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 193px);
  padding: 16px 80px;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-y: scroll;
  font-feature-settings: 'palt';
`;

export const Input = styled.input<{
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0 20px;
  text-align: left;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ShippingGroupAreaDl = styled.dl`
  > dt {
    font-weight: 700;
    margin-bottom: 8px;
  }
  > dd {
    margin-bottom: 46px;
    &.button-wrapper {
      margin-top: 24px;
      display: flex;
      gap: 48px;
      cursor: pointer;
    }
  }
  .roles-container .roles-row {
    display: flex;
    flex-wrap: wrap;

    .roles-container .roles-list__lists {
      width: calc(50% - 10px); /* 例: 2列で、各アイテム間に余白を設ける場合 */
      margin: 5px;
    }
  }
  .line-assignments {
    margin-top: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
  width: 100vw;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  padding: 22px 0;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const CenteredCircularProgress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useAllStoreroomTypes = () => {
  const { data } = useQuery(['all_storeroom_types'], async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/storeroom/all_store_room_types`
    );
    return data;
  });
  const allStoreroomTypes = humps.camelizeKeys(data?.data) as Todo;

  return { allStoreroomTypes };
};

export default useAllStoreroomTypes;

import styled from 'styled-components';

export const Wrapper = styled.div<{
  bgColor?: string;
}>`
  width: 100vw;
  height: calc(100vh - 193px);
  padding: 16px 80px 250px;
  overflow: scroll;
  background-color: ${(props) => props.theme.palette.beige};
  .go-back {
    width: 160px;
    display: flex;
    padding-bottom: 32px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.blue};
    > span {
      padding-top: 8px;
      padding-left: 10px;
    }
  }
`;
export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  border-top: solid 1px rgba(0, 0, 0, 0.08);
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

/**
 * アイコン
 */
export const PlusIcon = styled.div`
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

// 各ひも付け項目のタイトル
export const ConstituentTitle = styled.div`
  margin: 24px 0 4px 40px;
  font-weight: 700;
`;

export const ConstituentTitleLi = styled.div`
  min-height: 40px;
  display: grid;
  grid-template-columns: 40px auto 230px 90px;
  grid-template-areas: 'close-btn product-name product-pieces-num label-of-amount';
  align-items: center;
  .pieces-num {
    text-align: end;
  }
  .label-of-amount {
    text-align: center;
  }
`;

/**
 * 各商品の情報
 */
export const ProductMasterEditContents = styled.div`
  .product-info {
    display: flex;

    /* 商品画像 **********/
    .product-img {
      grid-area: product-img;
      width: 60px;
      height: 60px;
      background-color: #ececec;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }
      }
    }
    .name {
      display: flex;
      align-items: center;
      padding-left: 11px;
      font-size: 24px;
    }
  }

  /* 工場・ライン **********/
  .plant-line-container {
    margin-top: 24px;
    margin-left: 40px;
    &__title {
      font-weight: bold;
    }
    /* 工場 */
    /* xボタン */
    .close-btn {
      padding: 8px;
    }
    /* 編集ボタン */
    .edit-btn {
      margin-left: auto;
      padding: 8px;
    }
  }

  /* 追加ボタン */
  .add-button {
    width: 200px;
    padding: 16px 8px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .label {
      margin-left: 8px;
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  /* 追加disabledボタン */
  .disabled-add-button {
    width: 200px;
    padding: 8px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    .label {
      margin-left: 8px;
      color: ${(props) => props.theme.palette.khaki};
    }
  }

  /* 個数の紐付け **********/
  .associated-number-of-pieces {
    padding: 24px 0 0 0;
    grid-area: associated-number-of-pieces;
    &__title {
      font-weight: bold;
    }
    /* 工程名 */
    .process-breakdown-list-header {
      margin: 24px 0 4px 40px;
      display: grid;
      grid-template-columns: 0 auto 82px 82px 110px;
      grid-template-areas: '. process-breakdown-list-header__inputs new-btn edit-btn rename-btn';
      align-items: center;
      &__inputs {
        grid-area: process-breakdown-list-header__inputs;
        display: grid;
        grid-template-columns: auto 0px;
        grid-template-areas: 'product-name product-number';
        .process-name {
          grid-area: product-name;
          > dt {
            font-weight: 700;
            margin-bottom: 8px;
          }
        }
      }
      .new-btn {
        grid-area: new-btn;
        display: grid;
        padding-top: 24px;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
        button {
          width: 64px;
          &.disabled {
            background: #e0e0e0;
            border-color: #e0e0e0;
            color: #6b675c;
            cursor: initial;
          }
        }
      }
      .edit-btn {
        grid-area: edit-btn;
        display: grid;
        padding-top: 24px;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
        margin-right: 19px;
        button {
          width: 64px;
          &.disabled {
            background: #e0e0e0;
            border-color: #e0e0e0;
            color: #6b675c;
            cursor: initial;
          }
        }
      }
      .rename-btn {
        grid-area: rename-btn;
        display: grid;
        padding-top: 24px;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
        margin-right: 19px;
        button {
          width: 105px;
          &.disabled {
            background: #e0e0e0;
            border-color: #e0e0e0;
            color: #6b675c;
            cursor: initial;
          }
        }
      }
    }
    /* 工場・ライン */
    .factory-line-list-header {
      margin: 24px 0 4px;
      display: grid;
      grid-template-columns: 2rem 55rem auto;
      grid-template-areas: '. factory-line-list-header__inputs';
      align-items: center;

      &__inputs {
        grid-area: factory-line-list-header__inputs;
        display: grid;
        grid-template-columns: auto 0px;
        grid-template-areas: 'product-name product-number';
        .product-name {
          grid-area: product-name;
        }
        .product-name,
        dt {
          margin-bottom: 8px;
        }
      }
    }
  }
  /* 個数紐付けリスト ヘッダー */
  .piece-breakdown-list-header {
    margin-top: 24px;
    display: grid;
    grid-template-areas: 'piece-breakdown-list-header__inputs';
    align-items: center;
    &__inputs {
      grid-template-areas: 'close-btn product-name product-number-wrapper label-of-amount add-btn';
      grid-area: 'piece-breakdown-list-header__inputs';
      display: grid;
      grid-template-columns: 40px auto 230px 90px 80px;
      .product-name {
        grid-area: product-name;
        display: grid;
        /* margin-left: 16px; */
        dt {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
    .product-number-wrapper {
      grid-area: product-number-wrapper;
      width: auto;
      display: grid;
      justify-content: center;
      align-items: center;
      .product-number {
        grid-area: product-number;
        display: grid;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 16px;
        dt {
          text-align: left;
          padding-bottom: 8px;
        }
        dd > input {
          text-align: end;
          padding: 0.75rem;
        }
      }
    }
    .label-of-amount {
      margin-top: 42px;
      text-align: center;
    }
    .add-btn {
      grid-area: add-btn;
      display: grid;
      padding-top: 20px;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      padding-right: 19px;
      top: 0;
      button {
        width: 64px;
        display: block;
        &.disabled {
          background: #e0e0e0;
          border-color: #e0e0e0;
          color: #6b675c;
          cursor: initial;
        }
      }
    }
  }
  /* 個数紐付けリスト コンテンツ */
  .piece-breakdown-list-contents {
    margin: 0 0 4px 40px;
    background-color: ${(props) => props.theme.palette.white};
    /* 各商品 ul */
    &__pieces {
    }
    /* 各商品 li */
    &__piece {
      min-height: 40px;
      display: grid;
      grid-template-columns: 40px auto 230px 90px;
      grid-template-rows: auto auto auto;
      grid-template-areas: 'close-btn product-name product-pieces-num label-of-amount';
      align-items: center;
      border-bottom: solid 1px #cdcdcd;
      /* closeボタン、editボタン */
      .close-btn,
      .edit-btn {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .edit-btn {
        margin-left: auto;
      }
      /* 商品画像 */
      .product-img {
        width: 40px;
        height: 40px;
      }
      /* 商品名 */
      .product-name {
        padding-left: 14px;
      }
      /* 数量 */
      .product-pieces-num {
        text-align: right;
        padding: 0.75rem;
        /* padding-right: 4px; */
      }
      .label-of-amount {
        text-align: center;
      }
    }
    /* 合計 */
    &__sum {
      display: grid;
      grid-template-columns: 48px 48px auto 64px 88px;
      grid-template-areas: '. sum__title sum__number-of-types sum__sum sum__exp';
      align-items: center;
      padding: 16px 0;
      border-top: solid 1px #cdcdcd;
      margin-top: 2px;
      .sum__title {
        grid-area: sum__title;
      }
      .sum__number-of-types {
        grid-area: sum__number-of-types;
      }
      .sum__sum {
        grid-area: sum__sum;
      }
      .sum__exp {
        grid-area: sum__exp;
      }
    }
  }
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ModeButton = styled.button<{
  newMode: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0;
  gap: 10px;
  background: ${(props) =>
    props.newMode ? '#e0e0e0' : props.theme.palette.primary.main};
  color: ${(props) =>
    props.newMode ? '#6b675c' : props.theme.palette.primary.contrastText};
  border: ${(props) =>
    props.newMode
      ? `2px solid #e0e0e0`
      : `2px solid ${props.theme.palette.primary.main}`};
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 8px;
  border-width: 2px;
`;

/* 工場 */
export const FactoryList = styled.li<{
  factoryPresence: boolean;
}>`
  display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.factoryPresence ? 'solid 1px #cdcdcd' : ''};
  height: ${(props) => (props.factoryPresence ? '56px' : '')};
  overflow: hidden;
  /* 工場名 */
  .plant-name {
    min-width: 280px;
  }
  .line-wrapper {
    overflow-x: scroll;
    display: flex;
    padding: 16px 0;
  }
  /* ライン */
  .plant-line-list__line {
    display: flex;
    overflow-x: scroll;
    &.line-list {
      padding: 0 8px;
      min-width: 150px;
    }
    .line-list__line {
      padding: 0 8px;
      display: block;
      width: auto;
    }
  }
`;

import useSWR from 'swr';

export const useSelectedTagIds = () => {
  const { data: selectedTagIds, mutate: setSelectedTagIds } = useSWR(
    'selectedTagIds',
    null,
    {
      fallbackData: [],
    }
  );

  return {
    selectedTagIds,
    setSelectedTagIds: (newTagIds: number[]) =>
      setSelectedTagIds(newTagIds, false),
  };
};

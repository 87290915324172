import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import * as S from './SalesManagementTable.styles';
import { ReactComponent as CalendarSmall } from '@assets/icons/calendar_small_blue.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import { ReactComponent as FilterListIcon } from '@assets/icons/mobile/filter_list.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import { Button } from '@components/elements/buttons/Button';
import SearchMenu from '@components/modules/pc/picking/SearchMenu/SearchMenu';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { SalesInformation, Todo } from '@lib/common/type';
import useDeleteSalesInformationMutation from '@lib/pc/sales_information/useDeleteSalesInformationMutation';
import { SalesInformationState } from '@lib/pc/sales_information/type';

type Props = {
  salesInformationList: SalesInformation[];
  refetch: () => void;
  setMsgKind: (msgKind: string) => void;
  expand: boolean;
  setExpand: (expand: boolean) => void;
  date: Date | null;
  setDate: (date: Date | null) => void;
  searchEmployee: string;
  setSearchEmployee: (searchEmployee: string) => void;
  searchCustomer: string;
  setSearchCustomer: (searchCustomer: string) => void;
  scrollPosition: number | null;
};

const SalesManagementTable = ({
  salesInformationList,
  refetch,
  setMsgKind,
  expand,
  date,
  setDate,
  searchEmployee,
  setSearchEmployee,
  searchCustomer,
  setSearchCustomer,
  scrollPosition,
}: Props) => {
  const { state } = useLocation<SalesInformationState>();
  const history = useHistory();
  const [confirmMsg, setConfirmMsg] = useState('');
  const [deleteId, setDeleteId] = useState<number | ''>('');
  // 営業検索modal表示
  const [searchSalesModalActive, setSearchSalesModalActive] = useState(false);
  // 得意先検索modal表示
  const [searchCustomerModalActive, setSearchCustomerModalActive] =
    useState(false);
  const [focused, setFocused] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // 件名用colspan
  const titleColSpan = 2;
  // 得意先用colspan
  const customerColSpan = 6;
  const windowWidth = window.innerWidth;

  const handleSetWordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchEmployee(e.target.value);
  };

  const handleSetCustomerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCustomer(e.target.value);
  };

  const handleClickDelete = (id: number, title: string) => {
    setDeleteId(id);
    setConfirmMsg(`「${title}」を本当に削除しますか？`);
  };

  const handleDeleteSuccess = () => {
    setConfirmMsg('');
    setDeleteId('');
    refetch();
    setMsgKind('delete');
  };

  const deleteSalesInformationMutation = useDeleteSalesInformationMutation(
    deleteId,
    handleDeleteSuccess,
    setErrMsg
  );

  const handleDeleteSalesInfo = () => {
    deleteSalesInformationMutation.mutate();
  };

  const toggleProductRemarksVisibility = async (id: Todo) => {
    const target_id = 'remarks-' + id;
    const element = document.getElementById(target_id);

    if (element) {
      if (element.classList.contains('remarks-popup-hidden')) {
        element.classList.remove('remarks-popup-hidden');
        element.classList.add('remarks-popup');
      } else {
        element.classList.add('remarks-popup-hidden');
        element.classList.remove('remarks-popup');
      }
    }
  };

  const hideProductRemarks = async (id: Todo) => {
    const target_id = 'remarks-' + id;
    const element = document.getElementById(target_id);

    if (element) {
      element.classList.add('remarks-popup-hidden');
      element.classList.remove('remarks-popup');
    }
  };

  const handleChangeDate = (date: Date) => {
    setDate(date);
  };

  const onClickLine = (informationId: number) => {
    history.push(`sales_information/edit/${informationId}`, {
      ...state,
      stateScrollPosition: scrollPosition,
      stateExpand: expand,
      stateSelectedEntryDate: date,
      stateSearchEmployee: searchEmployee,
      stateSearchCustomer: searchCustomer,
    });
  };

  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('table-contents')?.scrollTo(0, 0);
    } else {
      document
        ?.getElementById('table-contents')
        ?.scrollTo(0, state?.stateScrollPosition);
    }
  }, [state]);

  useEffect(() => {
    if (searchEmployee) {
      setSearchSalesModalActive(true);
    } else {
      setSearchSalesModalActive(false);
    }
  }, [searchEmployee]);

  useEffect(() => {
    if (searchCustomer) {
      setSearchCustomerModalActive(true);
    } else {
      setSearchCustomerModalActive(false);
    }
  }, [searchCustomer]);

  return (
    <>
      <S.TableWrapper id="table-contents">
        <S.Stable>
          <S.Sthead>
            <tr>
              <S.Th className="edit">編集</S.Th>
              <S.Th className="entry-date">
                <S.EntryHeader>
                  記入日
                  <span onClick={() => setDate(null)}>
                    {date ? 'クリア' : ''}
                  </span>
                </S.EntryHeader>
                <S.DatePickerCustom>
                  <SingleDatePicker
                    date={date ? moment(date) : null}
                    // isOutsideRange={(day) => day.isAfter(today)} //今日以降を選択させたくない場合
                    isOutsideRange={() => false}
                    onDateChange={(date) => {
                      if (date) {
                        handleChangeDate(date.toDate());
                      }
                    }}
                    numberOfMonths={1}
                    focused={focused}
                    onFocusChange={({ focused }) => setFocused(focused)}
                    showDefaultInputIcon={false}
                    hideKeyboardShortcutsPanel={true}
                    customCloseIcon={null}
                    noBorder
                    readOnly
                    customInputIcon={
                      <S.Date date={date}>
                        {date
                          ? `${date.getFullYear()}/${(date.getMonth() + 1)
                              .toString()
                              .padStart(2, '0')}/${date
                              .getDate()
                              .toString()
                              .padStart(2, '0')}`
                          : `未選択`}
                        {date ? <FilteredIcon /> : <CalendarSmall />}
                      </S.Date>
                    }
                    id="date_input"
                  />
                </S.DatePickerCustom>
              </S.Th>
              <S.Th>
                <S.IconWrapper
                  onClick={() => {
                    setSearchSalesModalActive(!searchSalesModalActive);
                  }}
                >
                  営業担当
                  {searchEmployee ? <FilteredIcon /> : <FilterListIcon />}
                </S.IconWrapper>
              </S.Th>
              <S.Th colSpan={titleColSpan}>件名</S.Th>
              <S.Th>
                <S.IconWrapper
                  onClick={() => {
                    setSearchCustomerModalActive(!searchCustomerModalActive);
                  }}
                >
                  得意先名
                  {searchCustomer ? <FilteredIcon /> : <FilterListIcon />}
                </S.IconWrapper>
              </S.Th>
              <S.Th className="product">{expand ? '商品名' : ''}</S.Th>
              <S.Th className="amount">{expand ? '1次見込数量' : ''}</S.Th>
              <S.Th className="amount">{expand ? '2次見込数量' : ''}</S.Th>
              <S.Th className="amount">{expand ? '確定数量' : ''}</S.Th>
              <S.Th className="shipping-date">
                {expand ? '出荷予定日' : ''}
              </S.Th>
              <S.Th>備考</S.Th>
              <S.Th>削除</S.Th>
            </tr>
          </S.Sthead>

          {/* 詳細タイトル */}
          {salesInformationList.map((information, index) => (
            <tbody key={index}>
              <S.TbodyHeader>
                <S.TbodyTh alignRight>
                  <S.PencilDeleteIcon
                    onClick={() => onClickLine(information.id)}
                  >
                    <PencilIcon />
                  </S.PencilDeleteIcon>
                </S.TbodyTh>
                <S.TbodyTh onClick={() => onClickLine(information.id)}>
                  {information.entryDate}
                </S.TbodyTh>
                <S.TbodyTh onClick={() => onClickLine(information.id)}>
                  <S.FlexCenter>{information.employee.name}</S.FlexCenter>
                </S.TbodyTh>
                <S.TbodyTh
                  colSpan={titleColSpan}
                  onClick={() => onClickLine(information.id)}
                >
                  {information.title}
                </S.TbodyTh>
                <S.TbodyTh
                  colSpan={customerColSpan}
                  onClick={() => onClickLine(information.id)}
                >
                  {information.customer.name}
                </S.TbodyTh>
                {/* 商品名 */}
                {/* 1次見込数量 */}
                {/* 2次見込数量 */}
                {/* 確定数量 */}
                {/* 出荷予定日 */}
                {/* 備考 */}
                <S.TbodyTh
                  className="comment"
                  onClick={(e) => {
                    toggleProductRemarksVisibility(information.id);
                    e.stopPropagation();
                  }}
                >
                  {information.comment}
                </S.TbodyTh>
                <S.TbodyTh>
                  <S.PencilDeleteIcon
                    onClick={() =>
                      handleClickDelete(information.id, information.title)
                    }
                  >
                    <DeleteIcon />
                  </S.PencilDeleteIcon>
                </S.TbodyTh>
              </S.TbodyHeader>
              <S.TrOfRemarks
                className="remarks-popup-hidden"
                key={`remarks-${information.id}`}
                id={`remarks-${information.id}`}
                onClick={(e) => {
                  hideProductRemarks(information.id);
                  e.stopPropagation();
                }}
                maxWidth={`${windowWidth}px`}
              >
                <td className="remarks" colSpan={13}>
                  <span style={{ fontWeight: 'bold' }}>備考：</span>
                  {information.comment}
                </td>
              </S.TrOfRemarks>

              {/* 詳細内容 */}
              {information.details.map((detail, index) => (
                <S.Tr key={index} expand={expand}>
                  {/* 編集 */}
                  <S.Td></S.Td>
                  {/* 記入日 */}
                  <S.Td></S.Td>
                  {/* 営業担当 */}
                  <S.Td></S.Td>
                  {/* 件名 */}
                  <S.Td colSpan={titleColSpan}></S.Td>
                  {/* 得意先 */}
                  <S.Td></S.Td>
                  {/* 商品名 */}
                  <S.Td>{detail.productName}</S.Td>
                  <S.Td alignRight>
                    {detail.primaryEstimatedAmount
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </S.Td>
                  <S.Td alignRight>
                    {detail.secondaryEstimatedAmount
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </S.Td>
                  <S.Td alignRight>
                    {detail.fixedAmount
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </S.Td>
                  {/* 出荷予定日 */}
                  <S.Td className="shipping-date">{detail.shippingDate}</S.Td>
                  <S.Td></S.Td>
                </S.Tr>
              ))}
            </tbody>
          ))}
        </S.Stable>
      </S.TableWrapper>
      {deleteId && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>{confirmMsg}</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button
              padding="md"
              outlined
              onClick={() => {
                setConfirmMsg('');
                setDeleteId(0);
              }}
            >
              キャンセル
            </Button>
            <Button
              padding="md"
              icon={<DeleteIcon />}
              outlined
              onClick={() => handleDeleteSalesInfo()}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {searchSalesModalActive && (
        <SearchMenu
          title={'営業担当/コード'}
          searchParam={searchEmployee}
          handleClose={() => setSearchSalesModalActive(false)}
          handleChange={handleSetWordSearch}
          left={'10rem'}
          top={'137px'}
          deleteSearchParam={setSearchEmployee}
        />
      )}
      {searchCustomerModalActive && (
        <SearchMenu
          title={'得意先名/コード'}
          searchParam={searchCustomer}
          handleClose={() => setSearchCustomerModalActive(false)}
          handleChange={handleSetCustomerSearch}
          left={'33rem'}
          top={'137px'}
          deleteSearchParam={setSearchCustomer}
        />
      )}
      {errMsg && (
        <ErrorMsgPopUp
          errMsg={errMsg}
          handleClose={() => setErrMsg('')}
          fromPc={true}
        />
      )}

      {(searchSalesModalActive || searchCustomerModalActive) && (
        <Overlay
          handleClick={() => {
            setSearchSalesModalActive(false);
            setSearchCustomerModalActive(false);
          }}
        />
      )}
    </>
  );
};

export default SalesManagementTable;

import styled from 'styled-components';

export const ProductListContainer = styled.div<{
  isBothProductsKindExist: boolean;
}>`
  margin-top: 32px;
  width: ${(props) => (props.isBothProductsKindExist ? '50%' : '100%')};
  margin-top: 16px;
`;

export const ProductListWrapper = styled.div`
  margin-top: 16px;
`;

export const TRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #e5e1dd;
  display: flex;
  min-width: 100vw;
  z-index: 2;
  padding: 13px 8px;
  height: 45px;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const TCell = styled.div`
  font-size: 16px;
  font-weight: 700;
  &.factory-name {
    min-width: 220px;
  }
`;

export const ProcessNameWrapper = styled.div<{ processName: boolean }>`
  padding: 5px 10px 7px;
  width: 80px;
  height: 32px;
  font-size: 19px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.processName
      ? props.theme.palette.primary.main
      : props.theme.palette.white};
  color: ${(props) =>
    props.processName
      ? props.theme.palette.primary.contrastText
      : props.theme.palette.primary.main};
  border: ${(props) =>
    props.processName
      ? 'none'
      : `1px solid ${props.theme.palette.primary.main}`};
`;

export const TableContents = styled.div`
  overflow: scroll;
  /* width: 100vw; */
  /* height: calc(100vh - 280px); */
  /* padding-bottom: 130px; */
  position: relative;
`;

export const ProductListArray = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 500;
  padding: 13px 16px;
  height: 45px;
  border-bottom: 1px solid #cdcdcd;
  background-color: ${(props) => props.theme.palette.white};
`;

export const ProductOrMaterialName = styled.div`
  width: 100%;
  padding: 16px 16px 0 16px;
  font-weight: 700;
  background-color: ${(props) => props.theme.palette.white};
`;

export const ProcessName = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 700;
  padding: 16px 8px;
  height: 45px;
  /* background-color: #f0f4ff;  ピンク */
  /* cursor: pointer; */
  & > div {
    padding: 13px 8px;
  }
`;

export const ListUl = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.palette.white};
  max-height: calc(100vh - 191px);
  overflow: auto;
`;

export const LineNameRow = styled.div`
  padding: 16px 40px 16px 200px;
  font-size: 16px;
  border-bottom: 1px solid #e5e1dd;
  /* cursor: pointer; */
  &.hover-effect {
    &:hover {
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      border: solid 1px #e5e1dd;
    }
  }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Todo } from '@lib/common/type';
import moment from 'moment';

export const isSameDayWithOutTime = (d1: Date, d2: Date) => {
  return moment(d1).isSame(d2, 'day');
};

export const generateDateArray = (num: number, date = moment()) => {
  return [...Array(num * 2 - 1)].map((_, i) => {
    const today = new Date();
    const d = moment(date).toDate();
    d.setDate(d.getDate() + (i + 1 - num));
    return {
      productionDate: d,
      isToday: isSameDayWithOutTime(d, today),
    };
  });
};

export const generateDateArrayForModal = (num: number, date = moment()) => {
  return [...Array(num + 1)].map((_, i) => {
    const selectedDay = new Date(moment(date).toDate());
    const d = moment(date).toDate();
    d.setDate(1 + i);
    return {
      productionDate: d,
      isToday: isSameDayWithOutTime(d, selectedDay),
    };
  });
};

export const generateMonthlyDateArray = (
  monthlyDateNum: number,
  date = moment(),
  index: number,
  plusIndex: number
) => {
  return [...Array(monthlyDateNum + plusIndex)].map((_, i) => {
    const d = moment(date).toDate();
    d.setDate(1 + i);
    return {
      productionDate: d,
      isToday: i === index && i <= monthlyDateNum,
    };
  });
};

export const addComma = (val: number | null) => {
  if (val === null) return;

  return ('' + val).replace(/(\d)(?=(\d\d\d)+$)/g, '$1,');
};

export const fixNumberAtPlaces = (value: number | null, places: number) => {
  if (value) {
    return addComma(Number(value.toFixed(places)));
  } else {
    return;
  }
};

export const numberWithDecimalPlaces = (
  value: number | undefined,
  places: number
) => {
  if (value) {
    return fixNumberAtPlaces(Number(value), places);
  } else {
    return;
  }
};

export const stringWithDecimalPlaces = (
  value: string | undefined,
  places: number
) => {
  if (value) {
    return fixNumberAtPlaces(Number(value), places);
  } else {
    return;
  }
};

// グラフ横のY軸
export const extractMaxOfAmountOrSafetyStocksForBarDisplay = (data: any) => {
  const newPackagedData = data.map((p: any, i: number) => ({
    x: i + 1,
    y: Math.max(p.amount, p.safetyStocks, 10),
  }));

  return newPackagedData;
};

// 在庫数合計
export const extractAmountForBarDisplay = (data: any) => {
  const newPackagedData = data.map((p: any, i: number) => ({
    x: i + 1,
    y: p.amount ? p.amount : 0,
  }));

  return newPackagedData;
};

// 払出状況のグラフ横のY軸
export const extractTotalAmountForBarDisplay = (data: any) => {
  if (data) {
    const newPackagedData = data.map((p: any, i: number) => ({
      x: i,
      y: Math.max(p.totalAmount ? p.totalAmount : 0, 0),
    }));

    return newPackagedData;
  } else {
    return [{ x: 0, y: 0 }];
  }
};

// 理想在庫数
export const extractSafetyStocksForBarDisplay = (data: any[]) => {
  const newPackagedData = data.map((a: any, i: number) => {
    return {
      id: i,
      x: i + 1,
      y: a.safetyStocks ? a.safetyStocks : 0,
    };
  });

  return newPackagedData;
};

// 在庫払出数合計
export const convertStockAllocationBarData = (data: any) => {
  if (data) {
    const totals = data.map((p: any, i: number) => ({
      x: i + 1,
      y: p.totalAmount ? Number(p.totalAmount) : 0,
    }));

    return totals;
  } else {
    return [{ x: 0, y: 0 }];
  }
};

export const changeWidth = (num: number) => {
  return 70 * (num + 1);
};

export const findMinAmountForStockStatusGraph = (data: Todo) => {
  const allAmounts: number[] = [
    ...data.estimatedStocks?.map((item: Todo) => item.amount),
    ...data.stocks.map((item: Todo) => item.safetyStocks),
    ...data.stocks.map((item: Todo) => item.amount),
  ];

  const min = Math.min(...allAmounts);

  // dataが[]場合nullのmin「Infinity」になりますので、ガードする
  if (isFinite(min)) return min;
  else return 0;
};

// グラフ左のY軸の最大値を求める
export const findMaxAmountForStockStatusGraph = (data: Todo) => {
  const allAmounts: number[] = [
    ...data.estimatedStocks.map((item: Todo) => item.amount),
    ...data.stocks.map((item: Todo) => item.safetyStocks),
    ...data.stocks.map((item: Todo) => item.amount),
  ];

  const max = Math.max(...allAmounts);

  // dataが[]場合nullのmax「Infinity」になりますので、ガードする
  if (isFinite(max)) return max;
  else return 0;
};

export const findMinAmountForMaterialAllocationGraph = (data: Todo) => {
  const allAmounts: number[] = [...data.map((item: Todo) => item.totalAmount)];

  const min = Math.min(...allAmounts);

  // dataが[]場合nullのmin「Infinity」になりますので、ガードする
  if (isFinite(min)) return min;
  else return 0;
};

// グラフ左のY軸の最大値を求める
export const findMaxAmountForMaterialAllocationGraph = (data: Todo) => {
  const allAmounts: number[] = [...data.map((item: Todo) => item.totalAmount)];

  const max = Math.max(...allAmounts);

  // dataが[]場合nullのmax「Infinity」になりますので、ガードする
  if (isFinite(max)) return max;
  else return 0;
};

export const findMinAmountForStockAllocationGraph = (data: Todo) => {
  // これは現在同じ構造です
  return findMinAmountForMaterialAllocationGraph(data);
};

export const findMaxAmountForStockAllocationGraph = (data: Todo) => {
  // これは現在同じ構造です
  return findMaxAmountForMaterialAllocationGraph(data);
};

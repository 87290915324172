import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  justify-content: space-between;
`;

export const HeadContainer = styled.div`
  width: 100%;
  height: 70px;
  padding: 1.15rem 1.15rem 1.15rem 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompanyName = styled.div`
  display: flex;
  > span {
    font-weight: 700;
    width: 220px;
  }
`;

export const TRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #e5e1dd;
  display: flex;
  min-width: 100vw;
  z-index: 2;
  padding: 0 20px;
  height: 45px;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const TCell = styled.div`
  font-size: 16px;
  font-weight: 700;
  &.factory-name {
    min-width: 220px;
  }
`;

export const TableContents = styled.div`
  overflow: scroll;
  width: 100vw;
  height: calc(100vh - 280px);
  padding-bottom: 130px;
  position: relative;
`;

export const FactoryName = styled.h2`
  padding: 13px 20px;
  font-size: 16px;
  border-bottom: 1px solid #e5e1dd;
  font-weight: 500;
  height: 45px;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const ListUl = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.palette.white};
  max-height: calc(100vh - 191px);
  overflow: auto;
  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
`;

export const LineNameRow = styled.div`
  padding: 8px 40px;
  font-size: 16px;
  border-bottom: 1px solid #e5e1dd;
  cursor: pointer;
  &.hover-effect {
    &:hover {
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      border: solid 1px #e5e1dd;
    }
  }
`;

export const LineName = styled.div`
  margin-left: 200px;
  margin: 6px 0 6px 200px;
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { ModalPaper } from '@components/modules/common/pc/ModalPaper';
import {
  extractSafetyStocksForBarDisplay,
  extractMaxOfAmountOrSafetyStocksForBarDisplay,
  extractAmountForBarDisplay,
  generateDateArray,
  isSameDayWithOutTime,
  findMinAmountForStockStatusGraph,
  findMaxAmountForStockStatusGraph,
  numberWithDecimalPlaces,
} from '@lib/pc/stock_status/functions';
import * as S from './StockStatusGraphWithTable.styles';

//TODO: type
type Props = {
  selectedDate: moment.Moment;
  data: any;
};

const StockStatusGraphWithTable: FC<Props> = ({
  selectedDate,
  data,
}: Props) => {
  const [openIndex, setOpenIndex] = useState<number[]>([]);
  const today = new Date();

  const handleClickAccordion = (index: number) => {
    openIndex.includes(index)
      ? setOpenIndex(openIndex.filter((i) => i !== index))
      : setOpenIndex([...openIndex, index]);
  };

  const [chartWidth, setChartWidth] = useState(window.innerWidth - 112);

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth - 112);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const minYCoordinate = findMinAmountForStockStatusGraph(data);
  const tmpMaxY = findMaxAmountForStockStatusGraph(data);
  const maxYCoordinate = minYCoordinate == 0 && tmpMaxY == 0 ? 1 : tmpMaxY;

  return (
    <ModalPaper heading1="在庫状況の分析">
      <S.ModalHeader>
        <S.FlexContainer>
          <S.FlexContainer>
            <S.DotDividerWrapper>
              <S.DotDivider />
            </S.DotDividerWrapper>
            理想在庫数 (当日を含めて{data.safetyStockDays}
            日分の出荷予測数を満たす数量)
          </S.FlexContainer>
        </S.FlexContainer>
        <br />
        <S.FlexContainer>
          <S.FlexContainer>
            <S.ColorBox bgColor="#F6C1B1" />
            予定在庫数
          </S.FlexContainer>
        </S.FlexContainer>
        <br />
        <S.FlexContainer>
          <S.ColorBox bgColor="#ABD5F3" />
          実在庫数
        </S.FlexContainer>
      </S.ModalHeader>
      <S.GraphContainer>
        <S.ScrollableContainer>
          <S.LeftLabelContainer>
            <S.LeftGraphLabelContainer>
              <VictoryChart
                domainPadding={{ x: 50 }}
                width={120}
                height={320}
                minDomain={{ y: minYCoordinate }}
                maxDomain={{ y: maxYCoordinate }}
                padding={{ top: 50, bottom: 0, right: 0, left: 0 }}
                containerComponent={<VictoryContainer responsive={false} />}
              >
                <VictoryAxis
                  width={130}
                  height={400}
                  dependentAxis
                  offsetX={120}
                  crossAxis={false}
                  standalone
                  style={{
                    axisLabel: {
                      fontSize: 16,
                      padding: 30,
                      zIndex: 100,
                    },
                  }}
                />
                <VictoryGroup groupComponent={<g display="none" />} offset={20}>
                  {/* グラフ左のY軸の値 */}
                  <VictoryBar
                    barWidth={16}
                    data={extractMaxOfAmountOrSafetyStocksForBarDisplay(
                      data.stocks
                    )}
                    style={{ data: { display: 'none' } }}
                  />
                </VictoryGroup>
              </VictoryChart>
            </S.LeftGraphLabelContainer>
          </S.LeftLabelContainer>
          <VictoryChart
            height={320}
            width={chartWidth}
            minDomain={{ y: minYCoordinate }}
            maxDomain={{ y: maxYCoordinate }}
            domainPadding={{ x: chartWidth / 11 / 2 }}
            padding={{ top: 50, bottom: 0, right: 0, left: 90 }}
            containerComponent={<VictoryContainer responsive={false} />}
          >
            <VictoryAxis
              dependentAxis // Y
              style={{
                grid: { stroke: '#E7E2DD' },
              }}
            />
            <VictoryGroup>
              <VictoryBar
                alignment="end"
                barWidth={16}
                style={{ data: { fill: '#ABD5F3' } }} // 青
                data={extractAmountForBarDisplay(data.stocks).map((point) => ({
                  ...point,
                  x: point.x,
                }))}
              />
              <VictoryBar
                alignment="start"
                barWidth={16}
                style={{ data: { fill: '#F6C1B1' } }} // 赤
                data={extractAmountForBarDisplay(data.estimatedStocks).map(
                  (point) => ({
                    ...point,
                    x: point.x,
                  })
                )}
              />
            </VictoryGroup>
            <VictoryGroup
              data={extractSafetyStocksForBarDisplay(data.stocks).map(
                (point) => ({
                  ...point,
                  x: point.x,
                })
              )}
              color="#106CAE"
            >
              <VictoryLine
                style={{
                  data: {
                    stroke: '#0A68F4',
                    strokeDasharray: '4, 2',
                    strokeWidth: 2,
                  },
                }}
              />
              <VictoryScatter
                size={3}
                style={{
                  data: {
                    fill: '#FFFFFF',
                    stroke: '#106CAE',
                    strokeWidth: 3,
                  },
                }}
              />
            </VictoryGroup>
          </VictoryChart>
          <S.TableWrapper>
            <S.TableRow>
              <S.TableAccordionHeadCell bgColor="transparent" />
              {generateDateArray(6, selectedDate).map((day: any, i: any) => {
                return (
                  <S.TableHeadCell
                    key={`date-${i}`}
                    isToday={isSameDayWithOutTime(day.productionDate, today)}
                    dayOfWeek={moment(day.productionDate).format('ddd')}
                  >
                    <div>
                      {moment(day.productionDate).format('MM/DD')}
                      <span>{moment(day.productionDate).format('(ddd)')}</span>
                    </div>
                  </S.TableHeadCell>
                );
              })}
            </S.TableRow>
            <S.TableRow>
              <S.TableAccordionHeadCell bgColor="#F1EFEA">
                理想在庫数
              </S.TableAccordionHeadCell>
              {data.stocks &&
                data.stocks.map((p: any, i: number) => {
                  return (
                    <S.TableCell
                      key={`safety-stocks-${i}`}
                      isToday={isSameDayWithOutTime(p.date, today)}
                    >
                      {p.safetyStocks
                        ? numberWithDecimalPlaces(p.safetyStocks, 2)
                        : '-'}
                    </S.TableCell>
                  );
                })}
            </S.TableRow>
            <S.AccordionTableHeadRow onClick={() => handleClickAccordion(0)}>
              <S.TableAccordionHeadCell bgColor="#F6C1B1">
                予定在庫数
              </S.TableAccordionHeadCell>
              {data.estimatedStocks &&
                data.estimatedStocks.map((p: any, i: number) => {
                  return (
                    <S.TableCell
                      key={`ideal-inventory-stocks-${i}`}
                      isToday={isSameDayWithOutTime(p.date, today)}
                    >
                      {p.amount ? numberWithDecimalPlaces(p.amount, 2) : '-'}
                    </S.TableCell>
                  );
                })}
            </S.AccordionTableHeadRow>
            <S.TableRow>
              <S.TableAccordionHeadCell bgColor="#ABD5F3">
                実在庫数
              </S.TableAccordionHeadCell>
              {data.stocks &&
                data.stocks.map((p: any, i: number) => {
                  return (
                    <S.TableCell
                      key={`total-amount-${i}`}
                      isToday={isSameDayWithOutTime(
                        moment(p.date).toDate(),
                        today
                      )}
                      lastCell
                    >
                      {p.amount ? numberWithDecimalPlaces(p.amount, 2) : '-'}
                    </S.TableCell>
                  );
                })}
            </S.TableRow>
          </S.TableWrapper>
        </S.ScrollableContainer>
      </S.GraphContainer>
    </ModalPaper>
  );
};

export default StockStatusGraphWithTable;

import React, { useEffect, useState } from 'react';
import * as S from './EmployeeContents.styles';
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';
import { ReactComponent as PlusBlueIcon } from '@assets/icons/mobile/plus_blue.svg';
import { Button } from '@components/elements/buttons/Button';
import { Heading } from '@components/elements/typographies/Heading';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import FactoryLineModal from '@components/modules/pc/settings/common/FactoryLineModal/FactoryLineModal';
import {
  Employee,
  initEmployeeData,
  RolesInfo,
} from '@lib/pc/settings/manufacture_setting/type';
import { Factory, FactoryLine, Todo } from '@lib/common/type';
import useUpdateEmployeeMutation from '@lib/pc/settings/manufacture_setting/useUpdateEmployee';
import useCreateEmployee from '@lib/pc/settings/manufacture_setting/useCreateEmployee';
import useFactories from '@lib/pc/settings/product_master_edit/useFactories';

type Props = {
  employee: Todo;
  newData: Employee;
  setNewData: (newData: Todo) => void;
  rolesInfo: RolesInfo[];
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  refetch?: () => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  setMsgKind: (msgKind: string) => void;
  setEmployeeModalActive: (employeeModalActive: boolean) => void;
  setSelectedEmployee: (selectedEmployee: Todo) => void;
};

const EmployeeContents = ({
  employee,
  newData,
  setNewData,
  rolesInfo,
  updating,
  setUpdating,
  enableToSave,
  setEnableToSave,
  refetch,
  setUpdatePopUp,
  setMsgKind,
  setEmployeeModalActive,
  setSelectedEmployee,
}: Props) => {
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  // 工場ライン編集
  const [selectedLines, setSelectedLines] = useState<FactoryLine[]>(
    newData?.factoryLines ?? []
  );
  // 製造者に紐づく工場
  const [factories, setFactories] = useState<Factory[]>([]);
  const [factoryLineModalActive, setFactoryLineModalActive] = useState(false);
  const { data: allFactories } = useFactories();

  // 登録か保存か判定
  const createMode = employee ? false : true;

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newData, [e.target.name]: e.target.value };
    setNewData(data);
    setEnableToSave(true);
  };

  const handleUpdateSuccess = () => {
    refetch && refetch();
    setUpdatePopUp(true);
    setUpdating(false);
    setEnableToSave(false);
    setEmployeeModalActive(false);
    setNewData(initEmployeeData);
    reRender();
    if (createMode) {
      setMsgKind('create');
    } else {
      setMsgKind('update');
    }
  };

  const handleCheckbox = (roleId: number, role: string) => {
    if (
      newData?.roleIds.includes(roleId) &&
      role === '生産実績' &&
      selectedLines.length > 0
    ) {
      setErrMsg(
        '生産実績での担当ラインが選択されているため、チェックを外せません。'
      );
    } else if (newData?.roleIds.includes(roleId)) {
      // 含まれていたら外す
      const newRoleIds = newData?.roleIds.filter((num) => num !== roleId);
      setNewData({ ...newData, roleIds: newRoleIds });
    } else {
      newData?.roleIds.push(roleId);
    }
    reRender();
    setEnableToSave(true);
  };

  const updateEmployeeMutation = useUpdateEmployeeMutation(
    newData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmitUpdate = () => {
    setUpdating(true);
    updateEmployeeMutation.mutate(newData);
  };

  const createEmployee = useCreateEmployee(handleUpdateSuccess, setErrMsg);

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    setUpdating(true);
    createEmployee.mutate(newData);
  };

  const theme = createTheme({
    palette: {
      primary: cyan,
    },
  });

  const handleClickCancel = () => {
    if (enableToSave) {
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setEmployeeModalActive(false);
      setSelectedEmployee(initEmployeeData);
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    setEmployeeModalActive(false);
    setEnableToSave(false);
    setSelectedEmployee(initEmployeeData);
  };

  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    if (employee) {
      setNewData(employee?.employee);
    } else {
      setNewData(initEmployeeData);
    }
  }, [employee?.roleIds?.length]);

  useEffect(() => {
    const selectedLineIds = selectedLines?.map((line) => line.id);
    setNewData({ ...newData, factoryLineIds: selectedLineIds });
  }, [selectedLines]);

  useEffect(() => {
    setSelectedLines(newData?.factoryLines);
  }, [newData?.factoryLines?.length]);

  return (
    <>
      <S.HeadContainer>
        {createMode ? (
          <Heading tag="span" text="従業員の登録" fs="32" fw="lg" />
        ) : (
          <Heading tag="span" text="従業員の編集" fs="32" fw="lg" />
        )}
      </S.HeadContainer>
      <S.Wrapper>
        <S.ShippingGroupAreaDl>
          <dt>従業員コード</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="code"
              id="code"
              autoComplete="off"
              width={'25%'}
              value={newData?.code ? newData?.code : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>従業員名</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="name"
              id="name"
              autoComplete="off"
              width={'25%'}
              value={newData?.name ? newData?.name : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>メールアドレス</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="email"
              id="email"
              autoComplete="off"
              width={'50%'}
              value={newData?.email ? newData?.email : ''}
              onChange={handleChange}
            />
          </dd>
          {!createMode && (
            <>
              <dt>担当業務</dt>
              <div className="roles-row">
                <S.RolesNameWrapper>
                  {rolesInfo &&
                    rolesInfo.map((role: RolesInfo, i) => {
                      return (
                        <S.EachRole key={i + role?.role}>
                          <S.RolesTitle key={i + role?.role + newData?.name}>
                            {role?.role}
                          </S.RolesTitle>
                          <S.RolesCheck key={i + role?.role}>
                            <ThemeProvider theme={theme}>
                              <Checkbox
                                id="checkboxId"
                                color="primary"
                                checked={
                                  newData?.roleIds
                                    ? newData?.roleIds?.includes(role?.roleId)
                                    : false
                                }
                                onChange={() => {
                                  setEnableToSave(true);
                                  handleCheckbox(role.roleId, role.role);
                                }}
                              />
                            </ThemeProvider>
                          </S.RolesCheck>
                        </S.EachRole>
                      );
                    })}
                </S.RolesNameWrapper>
              </div>
            </>
          )}
          {/* 編集モードかつ担当業務の生産実績にチェックがある場合のみ表示 */}
          {!createMode &&
            newData?.roleIds?.includes(
              rolesInfo?.find((role) => role.role === '生産実績')?.roleId ?? -1
            ) && (
              <>
                <dt className="line-assignments">生産実績 担当ライン</dt>
                {/* 工場・ライン */}
                <div className="plant-line-container">
                  <ul className="plant-line-list">
                    {/* 製造者の工場全てから検索する */}
                    {allFactories &&
                      allFactories.map((factory) => {
                        const selectedLinesForFactory = selectedLines?.filter(
                          (line: { factoryId: string | number }) =>
                            line.factoryId === factory.id
                        );
                        const hasSelectedLines =
                          selectedLinesForFactory?.length > 0;

                        return (
                          <React.Fragment key={factory.id}>
                            {hasSelectedLines && (
                              <S.FactoryList
                                className="factory-list"
                                factoryPresence={hasSelectedLines}
                              >
                                {hasSelectedLines && (
                                  <div className="plant-name">
                                    {factory.name}
                                  </div>
                                )}
                                <div className="line-wrapper">
                                  {selectedLinesForFactory.map(
                                    (line: FactoryLine) => (
                                      <div key={line.id}>
                                        <ul className="plant-line-list__line line-list">
                                          <li className="line-list__line">
                                            {line.name}
                                          </li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </S.FactoryList>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </ul>
                  <S.SelectFactoryLine
                    onClick={() => {
                      setFactoryLineModalActive(true);
                      setEnableToSave(true);
                    }}
                  >
                    <S.PlusIcon>
                      <PlusBlueIcon />
                    </S.PlusIcon>
                    <span className="label">工場・ラインを選択</span>
                  </S.SelectFactoryLine>
                </div>
              </>
            )}
        </S.ShippingGroupAreaDl>
      </S.Wrapper>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button
            children={'キャンセル'}
            borderWidth={1}
            outlined
            onClick={() => {
              handleClickCancel();
            }}
            disabled={updating}
          />

          {createMode ? (
            <Button
              children={updating ? '登録中...' : '登録'}
              disabled={updating || !enableToSave}
              onClick={handleSubmitCreate}
            />
          ) : (
            <Button
              children={updating ? '保存中...' : '保存'}
              disabled={updating || !enableToSave}
              onClick={handleSubmitUpdate}
            />
          )}
        </S.ButtonContainer>
      </S.ButtonWrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {factoryLineModalActive && (
        <FactoryLineModal
          selectedLines={selectedLines}
          setSelectedLines={setSelectedLines}
          factories={factories || []}
          setFactories={setFactories}
          handleClose={() => setFactoryLineModalActive(false)}
        />
      )}

      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default EmployeeContents;

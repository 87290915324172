import useSWR from 'swr';

export const META_CURRENT_PAGE_KEY = 'metaCurrentPage';
export const META_TOTAL_PAGES_KEY = 'metaTotalPages';

export const useMetaPagination = () => {
  const { data: metaCurrentPage, mutate: mutateCurrentPage } = useSWR(
    META_CURRENT_PAGE_KEY,
    null,
    {
      fallbackData: 0,
    }
  );

  const { data: metaTotalPages, mutate: mutateTotalPages } = useSWR(
    META_TOTAL_PAGES_KEY,
    null,
    {
      fallbackData: 0,
    }
  );

  const setMetaCurrentPage = (page: number) => {
    mutateCurrentPage(page);
  };

  const setMetaTotalPages = (total: number) => {
    mutateTotalPages(total);
  };

  return {
    metaCurrentPage,
    setMetaCurrentPage,
    metaTotalPages,
    setMetaTotalPages,
  };
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-x: hidden;
  margin-bottom: 200px;
  z-index: 4;
`;

export const HeadContainer = styled.div`
  width: 100%;
  height: 88px;
  padding: 1.15rem 1.15rem 1.15rem 80px;
  background-color: ${(props) => props.theme.palette.white};
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  padding: 16px 80px 200px 80px;
  & > div.title {
    font-weight: bold;
    margin-top: 32px;
  }
  & > div.no-employee {
    margin-top: 16px;
  }
`;

export const FactoryLineContent = styled.div`
  display: flex;
  align-items: center;
  & > div {
    width: 30%;
  }
  & > div > dt {
    font-weight: bold;
  }
  & > div > dd {
    margin-top: 16px;
  }
`;

export const EmployeeListContainer = styled.div`
  & > div.title {
    font-weight: bold;
  }
`;

{
  /* 完成品工程と半製品工程のwrapper */
}
export const ProductAndSemiProductWrapper = styled.div<{
  isBothProductsKindExist: boolean;
}>`
  display: ${(props) => (props.isBothProductsKindExist ? 'flex' : 'block')};
  gap: 32px;
`;

export const EmployeeList = styled.div<{ titleText?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  height: 45px;
  font-weight: ${(props) => (props.title ? '700' : '500')};
  padding: 13px 8px;
  margin-top: ${(props) => (props.title ? '16px' : '0')};
  background-color: ${(props) =>
    props.title ? '' : props.theme.palette.white};
  border-bottom: 1px solid #cdcdcd;
  & > div {
    width: 30%;
  }
  &.name {
    margin-top: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
  width: 100vw;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  padding: 22px 0;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

import { FC, useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { generateDateArray, addComma } from '@lib/pc/stock_status/functions';
import { PRODUCT_KIND, Todo } from '@lib/common/type';
import { SelectedProductData } from '@lib/pc/stock_status/type';
import { ListOnItemsRenderedProps, FixedSizeList } from 'react-window';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { CircularProgress } from '@material-ui/core';
import useSWR, { useSWRConfig } from 'swr';
import InfiniteLoader from 'react-window-infinite-loader';
import humps from 'humps';

type Props = {
  selectedStoreroomId: string | number;
  selectedDate: moment.Moment;
  selected: boolean;
  handleSelectedProduct: (d: SelectedProductData) => void;
  handleOpenProductDetail: (item: Todo) => void;
  setTagListModalActive?: () => void;
  rightSlideSidebarActive: boolean;
  viewEstimated: boolean;
  caseUnitDisplay: boolean;
  onClickCancel: () => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedStoreroomId,
  selectedDate,
  selected,
  handleSelectedProduct,
  handleOpenProductDetail,
  rightSlideSidebarActive,
  viewEstimated,
  caseUnitDisplay,
  onClickCancel,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);
  const [hasNextPage, setHasNextPage] = useState(true); // To track if there are more items to load
  const [isNextPageLoading, setIsNextPageLoading] = useState(false); // Track if the next page is loading
  const [currentPage, setCurrentPage] = useState(0);
  const [metaCurrentPage, setMetaCurrentPage] = useState(0);
  const [metaTotalPages, setMetaTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { mutate } = useSWRConfig();
  const { data: filteredProducts } = useSWR('filteredProducts');
  const selectedDateString = moment(selectedDate).format('YYYYMMDD');
  const [listHeight, setListHeight] = useState(window.innerHeight - 218);

  const handleSearch = (e: Todo) => {
    setSearchWord(e.target.value);
  };

  const handleClickCell = (p: Todo, idx: number) => {
    handleSelectedProduct({
      productId: p.id,
      productName: p.name,
      productKind: p.productKind,
      type: p.productKind === PRODUCT_KIND.PRODUCT ? '完' : '半',
      ExpiryDays: p.expiryDays,
      date: p.stocks[idx].date,
      data: p.stocks[idx].amountDetail,
      comment: p.stocks[idx].comment,
      piecesPerBox: p.piecesPerBox ? p.piecesPerBox : 1,
      stocks: p.stocks,
      estimatedStocks: p.estimatedStocks,
      stockDateLabel: p.stockDateLabel,
      labelOfAmount: p.labelOfAmount,
    });
  };

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const today = `${year}-${month}-${day}`;

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  const fetchData = useCallback(
    async (currentPage: number) => {
      try {
        const response = await axiosInstance().get(
          `/api/v1/stocks`, // Adjust the endpoint as needed
          {
            params: {
              date: selectedDateString,
              storeroom_id: selectedStoreroomId,
              page: currentPage + 1,
              search: searchWord,
              tag_ids: selectedTags.map((item: Todo) => item['id']),
              per_page: 50,
            },
          }
        );
        const newItems = humps.camelizeKeys(response?.data?.products);
        setMetaCurrentPage(response?.data?.meta.current_page);
        setMetaTotalPages(response?.data?.meta.total_pages);

        // Store filteredProducts in SWR global state
        mutate('filteredProducts', newItems, false);

        return newItems;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [searchWord, selectedTags, selectedStoreroomId, selectedDateString]
  );

  const loadMoreItems = useCallback(async () => {
    if (!isInitialLoadComplete.current) return;

    if (isNextPageLoading || !hasNextPage) return;

    setIsNextPageLoading(true);
    if (metaCurrentPage < metaTotalPages) {
      const newProducts: Todo[] = (await fetchData(currentPage)) || [];

      if (newProducts?.length === 0) {
        setHasNextPage(false); // No more items to load
      } else {
        mutate(
          'filteredProducts',
          [...(filteredProducts || []), ...newProducts],
          false
        );
        setCurrentPage((prev) => prev + 1); // Move to the next page
      }
    }

    setIsNextPageLoading(false);
  }, [
    currentPage,
    hasNextPage,
    isNextPageLoading,
    metaCurrentPage,
    metaTotalPages,
    filteredProducts,
    fetchData,
  ]);

  const isItemLoaded = (index: number) =>
    index < (filteredProducts || []).length;

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isInitialLoadComplete = useRef(false);

  const fetchFilteredProducts = async () => {
    fetchData(0);
    setCurrentPage(1);
  };

  const clearAll = () => {
    setHasNextPage(true);
    setIsNextPageLoading(false);
    setCurrentPage(0);
    mutate('filteredProducts', [], false);
  };

  useEffect(() => {
    fetchFilteredProducts();

    timerRef.current = setTimeout(() => {
      isInitialLoadComplete.current = true;
    }, 1500);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isInitialLoadComplete.current) return;

    setIsLoading(true);
    clearAll();
    fetchFilteredProducts();
  }, [selectedTags, selectedStoreroomId, selectedDateString]);

  useEffect(() => {
    if (!isInitialLoadComplete.current) return;

    setIsLoading(true);
    clearAll();
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      fetchFilteredProducts();
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchWord]);

  useEffect(() => {
    const updateHeight = () => {
      setListHeight(window.innerHeight - 218);
    };

    // Initial setup
    updateHeight();

    // Add resize event listener
    window.addEventListener('resize', updateHeight);

    // Cleanup
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <>
      <S.StickyWrapper>
        <S.ListLeftHeadCell
          invalid={rightSlideSidebarActive}
          onClick={() => setTagMenuActive(!tagMenuActive)}
          isBlue={searchWord !== '' || selectedTagIds.length > 0}
          style={{ background: '#F5F2E6', justifyContent: 'center' }}
        >
          <span className="filter-product">
            {searchWord !== '' || selectedTagIds.length > 0 ? (
              <>
                <FilteredIcon />
                <div className="filter_text">商品の絞込み中</div>
              </>
            ) : (
              <>
                <Filter
                  isBlue={searchWord !== '' || selectedTagIds.length > 0}
                />
                <div className="filter_text">商品の絞込</div>
              </>
            )}
          </span>
        </S.ListLeftHeadCell>
        <S.ListDateRow>
          {generateDateArray(6, selectedDate).map((p, i) => {
            return (
              <S.ListHeadCell
                key={i}
                highLighten={i === 5}
                dayOfWeek={moment(p.productionDate).format('ddd')}
              >
                {moment(p.productionDate).format('MM/DD(ddd)')}
              </S.ListHeadCell>
            );
          })}
        </S.ListDateRow>
      </S.StickyWrapper>
      <S.ListWrapper>
        {!isLoading &&
        (searchWord !== '' || selectedTagIds.length > 0) &&
        filteredProducts.length === 0 ? (
          <S.NoProductsToShow>
            製品が見つかりませんでした。
            <div>条件を変更して再度検索してください。</div>
          </S.NoProductsToShow>
        ) : filteredProducts === undefined || filteredProducts.length === 0 ? (
          <S.CenteredCircularProgress>
            <CircularProgress />
          </S.CenteredCircularProgress>
        ) : (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={
              hasNextPage
                ? filteredProducts.length + 1
                : filteredProducts.length
            }
            loadMoreItems={loadMoreItems}
            threshold={15}
          >
            {({
              onItemsRendered,
              ref,
            }: {
              onItemsRendered: (props: ListOnItemsRenderedProps) => void;
              ref: React.Ref<FixedSizeList | null>;
            }) => (
              <S.StyledFixedSizeList
                height={listHeight}
                itemCount={filteredProducts.length}
                itemSize={52}
                width={250}
                onItemsRendered={onItemsRendered}
                ref={ref}
              >
                {({ index, style }) => (
                  <S.StyledDiv style={style}>
                    <S.ListSidebar>
                      <S.ListLeftHeadCell
                        key={index}
                        onClick={() =>
                          handleOpenProductDetail(filteredProducts[index])
                        }
                        index={index}
                      >
                        <S.ListLeftHeadName>
                          {filteredProducts[index].name_abbreviation
                            ? filteredProducts[index].name_abbreviation
                            : filteredProducts[index].name}
                          <S.ProductKindDiv
                            productKind={filteredProducts[index].productKind}
                          >
                            {filteredProducts[index].productKind === 1
                              ? '完'
                              : '半'}
                          </S.ProductKindDiv>
                        </S.ListLeftHeadName>
                        <S.ListLeftHeadMeta>
                          <span>{filteredProducts[index].productCode}</span>
                          <div>
                            最終入力日
                            <span>
                              {filteredProducts[index].stockLastInputAt &&
                                moment(
                                  filteredProducts[index].stockLastInputAt
                                ).format('YYYY/MM/DD')}
                            </span>
                          </div>
                        </S.ListLeftHeadMeta>
                      </S.ListLeftHeadCell>
                    </S.ListSidebar>
                    <S.ListBody className={selected ? 'move-to-left' : ''}>
                      <S.ListRow key={index}>
                        {generateDateArray(6).map((d: Todo, idx: number) => {
                          const todayOrNot =
                            today ===
                            filteredProducts[index].stocks[idx].date.match(
                              /(\d{4}-\d{2}-\d{2})/
                            )[0];
                          if (caseUnitDisplay) {
                            const caseAmount = filteredProducts[index].stocks[
                              idx
                            ].caseAmount
                              ? filteredProducts[index].stocks[idx].caseAmount
                              : filteredProducts[index].estimatedStocks[idx]
                                  .caseAmount;
                            return (
                              <S.ListCellContainer
                                todaysEstimated={
                                  (filteredProducts[index].stocks[idx]
                                    .caseAmount === null ||
                                    filteredProducts[index].stocks[idx]
                                      .caseAmount === 0) &&
                                  (filteredProducts[index].estimatedStocks[idx]
                                    .amount === 0 ||
                                    filteredProducts[index].estimatedStocks[idx]
                                      .amount) &&
                                  todayOrNot
                                }
                                estimated={
                                  (filteredProducts[index].stocks[idx]
                                    .caseAmount === null ||
                                    filteredProducts[index].stocks[idx]
                                      .caseAmount === 0) &&
                                  (filteredProducts[index].estimatedStocks[idx]
                                    .amount === 0 ||
                                    filteredProducts[index].estimatedStocks[idx]
                                      .amount)
                                }
                                invalid={rightSlideSidebarActive}
                                key={idx}
                                highLighten={idx === 5}
                                withComment={
                                  filteredProducts[index].stocks[idx].comment
                                }
                                onClick={() => {
                                  handleClickCell(filteredProducts[index], idx);
                                }}
                                index={index}
                                columnIndex={idx}
                              >
                                {addComma(
                                  caseAmount === 0 ? 0 : caseAmount || ''
                                )}
                              </S.ListCellContainer>
                            );
                          } else if (
                            viewEstimated &&
                            filteredProducts[index].stocks[idx].amount === null
                          ) {
                            return (
                              <S.ListCellContainer
                                todaysEstimated={
                                  (filteredProducts[index].estimatedStocks[idx]
                                    .amount === 0 ||
                                    filteredProducts[index].estimatedStocks[idx]
                                      .amount) &&
                                  todayOrNot
                                }
                                estimated
                                invalid={rightSlideSidebarActive}
                                key={idx}
                                highLighten={idx === 5}
                                withComment={
                                  filteredProducts[index].stocks[idx].comment
                                }
                                onClick={() => {
                                  handleClickCell(filteredProducts[index], idx);
                                }}
                                index={index}
                                columnIndex={idx}
                              >
                                {addComma(
                                  filteredProducts[index].estimatedStocks[idx]
                                    .amount === 0
                                    ? 0
                                    : filteredProducts[index].estimatedStocks[
                                        idx
                                      ].amount || ''
                                )}
                              </S.ListCellContainer>
                            );
                          } else {
                            return (
                              <S.ListCellContainer
                                invalid={rightSlideSidebarActive}
                                key={idx}
                                highLighten={idx === 5}
                                withComment={
                                  filteredProducts[index].stocks[idx].comment
                                }
                                onClick={() => {
                                  handleClickCell(filteredProducts[index], idx);
                                }}
                                index={index}
                                columnIndex={idx}
                              >
                                {addComma(
                                  filteredProducts[index].stocks[idx].amount
                                )}
                              </S.ListCellContainer>
                            );
                          }
                        })}
                      </S.ListRow>
                    </S.ListBody>
                  </S.StyledDiv>
                )}
              </S.StyledFixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </S.ListWrapper>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT}
        />
      )}
      {(tagMenuActive || rightSlideSidebarActive) && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
            onClickCancel();
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;

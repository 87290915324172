import ReactSelect from 'react-select';
import * as S from './CommonConstituentPartsSelect.styles';

type SelectProps = {
  width: string;
  options: { id: number; name: string; code: string; labelOfAmount: string }[];
  value: number | null;
  disabled?: boolean;
  error?: boolean;
  onChange: (e: number, s: string, labelOfAmount: string) => void;
  checkCustomerSelectedOrNot?: () => void;
};

const CommonConstituentPartsSelect = ({
  width,
  options,
  value,
  disabled,
  error,
  onChange,
  checkCustomerSelectedOrNot,
}: SelectProps) => {
  const toReactSelectOptions = (
    options: {
      id: number;
      name: string;
      code: string;
      labelOfAmount: string;
    }[]
  ) =>
    options.map(({ id, name, code, labelOfAmount }) => ({
      value: id,
      label: name.replace(/\s/g, '_'),
      code: code,
      labelOfAmount: labelOfAmount,
    }));

  const reactSelectOptions = toReactSelectOptions(options);
  const currentValue = reactSelectOptions.find((o) => o.value === value);

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue ? currentValue : null}
      onChange={(option) =>
        option && onChange(option.value, option.label, option.labelOfAmount)
      }
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          width: width,
          height: '40px',
          borderRadius: '0.5rem',
          borderWidth: 0,
          outline: 'solid 1px rgba(0, 0, 0, 0.08)',
          backgroundColor: disabled ? '' : 'white',
          border: error ? `1px solid #C74141` : '',
        }),
      }}
      components={{
        DropdownIndicator: () => <S.SelectIndicator />,
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
      isDisabled={disabled}
      onMenuOpen={() =>
        checkCustomerSelectedOrNot && checkCustomerSelectedOrNot()
      }
      placeholder="選択してください"
    />
  );
};

export default CommonConstituentPartsSelect;

import { WithHeader } from '@components/templates/WithHeader';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './StoreroomCreateEditModal.styles';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import { useEffect, useState } from 'react';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import StoreroomContents from '../StoreroomContents/StoreroomContents';
import { Storeroom } from '@lib/pc/settings/manufacture_setting/type';
import useStoreroom from '@lib/pc/settings/manufacture_setting/useStoreroom';

type Props = {
  selectedStoreroom: Storeroom;
  setStoreroomCreateEditModalActive: (
    storeroomCreateEditModalActive: boolean
  ) => void;
  storeroomsRefetch: () => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  setMsgKind: (msgKind: string) => void;
};

const StoreroomCreateEditModal = ({
  selectedStoreroom,
  setStoreroomCreateEditModalActive,
  storeroomsRefetch,
  setUpdatePopUp,
  setMsgKind,
}: Props) => {
  const [newData, setNewData] = useState(selectedStoreroom);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { storeroom: storeroomQuery, refetch: storeroomRefetch } =
    selectedStoreroom?.id
      ? useStoreroom(selectedStoreroom.id)
      : { storeroom: null, refetch: () => Promise.resolve() };

  const storeroom = storeroomQuery;

  // 確認メッセージOK
  const handleOk = () => {
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    storeroomRefetch();
    storeroom && setNewData(storeroom);
  }, [storeroom?.id, storeroom?.products?.length]);

  return (
    <>
      <WithHeader>
        <S.Wrapper>
          <DndProvider backend={HTML5Backend}>
            {/* 在庫室 */}
            <StoreroomContents
              newData={newData}
              setNewData={setNewData}
              enableToSave={enableToSave}
              setEnableToSave={setEnableToSave}
              updating={updating}
              setUpdating={setUpdating}
              // setConfirmMsg={setConfirmMsg}
              setStoreroomCreateEditModalActive={
                setStoreroomCreateEditModalActive
              }
              storeroomRefetch={storeroomRefetch}
              storeroomsRefetch={storeroomsRefetch}
              setUpdatePopUp={setUpdatePopUp}
              setMsgKind={setMsgKind}
            />
          </DndProvider>
        </S.Wrapper>
      </WithHeader>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default StoreroomCreateEditModal;

import * as S from './ProcessesMaterialList.styles';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { ReactComponent as PlusBlueIcon } from '@assets/icons/mobile/plus_blue.svg';
import { MATERIAL_TYPE_LABEL } from '@lib/common/type';
import { Material } from '@lib/pc/settings/product_master_setting/type';
import {
  MATERIAL,
  ROW_MATERIAL,
} from '@lib/pc/settings/product_master_edit/type';

type Props = {
  label: string;
  materialsArray: Material[];
  setConfirmMsg: (confirmMsg: string) => void;
  setDeleteItem: (deleteItem: string) => void;
  setDeleteNo: (deleteNo: number) => void;
  setModalActiveFrom: (modalActiveFrom: string) => void;
};

const ProcessesMaterialList = ({
  label,
  materialsArray,
  setConfirmMsg,
  setDeleteItem,
  setDeleteNo,
  setModalActiveFrom,
}: Props) => {
  const modalActiveFrom =
    label === MATERIAL_TYPE_LABEL.MATERIAL ? MATERIAL : ROW_MATERIAL;

  // 資材・原材料削除ボタン
  const onClickMaterialDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteNo(no);
    if (label === MATERIAL_TYPE_LABEL.MATERIAL) {
      setDeleteItem('material');
    } else if (label === MATERIAL_TYPE_LABEL.ROW_MATERIAL) {
      setDeleteItem('rawMaterial');
    }
  };

  return (
    <S.Wrapper>
      {/* タイトル */}
      <S.ConstituentTitle>
        <S.ConstituentTitleLi>
          <div className="close-btn"></div>
          <div className="product-name">{label}</div>
          <div className="pieces-num">数量</div>
          <div className="label-of-amount">単位</div>
        </S.ConstituentTitleLi>
      </S.ConstituentTitle>
      {/* 資材・原材料個数内訳リスト */}
      <div className="piece-breakdown-list-contents">
        <ul className="piece-breakdown-list-contents__pieces">
          {materialsArray &&
            materialsArray.map((m: Material, index: number) => {
              return (
                <li
                  className="piece-breakdown-list-contents__piece"
                  key={m?.id + index + Math.random()}
                >
                  <div
                    className="close-btn"
                    onClick={() => onClickMaterialDelButton(index, m?.name)}
                    key={m?.name + index}
                  >
                    <CircleCloseIcon />
                  </div>
                  <div className="product-name" key={m?.name + Math.random()}>
                    {m?.name}
                  </div>
                  <div
                    className="product-pieces-num"
                    key={m?.name + m.usageAmount + Math.random()}
                  >
                    {m?.usageAmount}
                  </div>
                  <div className="label-of-amount">
                    {m?.labelOfAmount ? m?.labelOfAmount : '個'}
                  </div>
                </li>
              );
            })}
        </ul>
        {/* 追加modal画面表示箇所 */}
        <div
          className="add-button"
          onClick={() => setModalActiveFrom(modalActiveFrom)}
        >
          <S.PlusIcon>
            <PlusBlueIcon />
          </S.PlusIcon>
          <span className="label">{label}を追加</span>
        </div>
      </div>
    </S.Wrapper>
  );
};

export default ProcessesMaterialList;

import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './ManufactureEditRoot.styles';
import ManufactureEditWrapper from '@components/modules/pc/settings/manufacture_setting/ManufactureEditWrapper/ManufactureEditWrapper';
import useEmployeesForTable from '@lib/pc/settings/manufacture_setting/useEmployeesForTable';
import useManufacture from '@lib/pc/settings/manufacture_setting/useManufacture';
import useFactoryLines from '@lib/pc/settings/product_master_edit/useFactoryLines';
import useFactories from '@lib/pc/settings/product_master_edit/useFactories';

export const ManufactureEditRoot = () => {
  const { manufactureInfo } = useManufacture();
  const { employeesInfo, refetch } = useEmployeesForTable();
  const { data: allFactories } = useFactories();
  const { data: allFactoryLines } = useFactoryLines();

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <S.FlexBaseline>
            <Heading tag="span" text="会社情報管理" fs="32" fw="lg" />
          </S.FlexBaseline>
        </S.HeadContainer>
        <ManufactureEditWrapper
          manufactureInfo={manufactureInfo}
          employeesInfo={employeesInfo}
          allFactories={allFactories}
          allFactoryLines={allFactoryLines}
          refetch={refetch}
        />
      </S.Wrapper>
    </WithHeader>
  );
};

import styled from 'styled-components';

export const GraphControlHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.palette.beige};
  margin-bottom: 2.5rem;
  > div {
    width: 80%;
  }
`;

export const DescHeader = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.khaki};
  line-height: 21px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const GraphContainer = styled.div`
  min-height: 500px;

  display: flex;
`;

export const LeftHeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const LeftLabelContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: right;
`;

export const LeftGraphLabelContainer = styled.div`
  position: absolute;
  left: 0;
  top: 13%;
  min-height: 280px;
  width: 152px;
  background: ${(props) => props.theme.palette.beige};
  z-index: 2;
`;

export const ColorBox = styled.div<{
  bgColor: string;
}>`
  height: 1rem;
  width: 1.5rem;
  background: ${(props) => props.bgColor};
`;

export const GraphLeftLabelContainer = styled.div`
  display: flex;
  align-items: unset;
  gap: 0.25rem;
  min-width: 160px;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  > span {
    margin-left: 4px;
    color: ${(props) => props.theme.palette.baseBlack};
    font-weight: bold;
  }
`;

export const LeftGraphTextContainer = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.baseBlack};
  font-weight: bold;
  font-size: 14px;
  gap: 0.5rem;
  line-height: 14px;

  > span {
    font-size: 10px;
    font-weight: 400;
  }
`;

export const GraphLeftLabelYAxisContainer = styled.div`
  width: 50px;
`;

export const ScrollableContainer = styled.div`
  // max-width: 720px;
  overflow-x: scroll;

  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div``;

export const TableHeadCell = styled.div<{
  isToday?: boolean;
  dayOfWeek?: string;
}>`
  width: calc((100vw - 312px) / 11);
  height: 44px;
  font-size: 1rem;
  font-weight: ${(props) => (props.isToday ? '700' : '')};
  padding: 8px 10px;
  text-align: center;
  vertical-align: middle;
  border-bottom: ${(props) =>
    !props.isToday ? ` 2px solid ${props.theme.palette.beige}` : ''};
  &:nth-child(odd) {
    background: #fff;
  }
  background: ${(props) =>
    props.isToday ? `${props.theme.palette.blue} !important` : ''};
  color: ${(props) => (props.isToday ? `${props.theme.palette.white}` : '')};

  > div {
    display: flex;
    align-items: baseline;
    justify-content: center;
    color: ${(props) =>
      props.dayOfWeek === '土' && !props.isToday
        ? '#1046cf'
        : props.dayOfWeek === '日' && !props.isToday
        ? '#c74141'
        : ''};
  }

  span {
    font-size: 12px;
  }
`;
export const TableCell = styled.div<{
  marginTop?: boolean;
  isToday?: boolean;
  color?: string;
  isMax?: boolean;
}>`
  width: calc((100vw - 312px) / 11);
  height: 44px;
  font-size: 1rem;
  padding: 8px 10px;
  padding-top: ${(props) => (props.marginTop ? '10px' : '8px')};
  text-align: center;
  color: ${(props) => props.color};
  vertical-align: middle;
  border-inline: ${(props) =>
    props.isToday ? `2px solid ${props.theme.palette.blue}` : ''};
  border-bottom: ${(props) =>
    props.isToday
      ? props.marginTop
        ? `2px solid ${props.theme.palette.blue}`
        : ''
      : `2px solid ${props.theme.palette.beige}`};
  background-color: ${(props) => (props.isMax ? '#ABD5F3 !important' : '')};
  font-weight: ${(props) => (props.isMax ? 'bold' : '')};
  &:nth-child(odd) {
    background: #fff;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > span {
    font-size: 12px;
  }
`;

export const LeftTableEmptyCell = styled.div`
  padding-top: 5px;
  width: 200px;
  height: 25px;
`;

export const TableRow = styled.div`
  display: inline-flex;
`;

export const LeftTableHeadCell = styled.div<{
  bg: string;
  color?: string;
  marginTop?: boolean;
}>`
  width: 200px;
  height: 42px;
  font-size: 0.75rem;
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.baseBlack};
  font-weight: bold;
  text-align: left;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > span {
    font-weight: ${(props) => (props.color === '#0A68F4' ? 'bold' : '400')};
    font-size: 12px;
    margin-left: 8px;
    margin-bottom: 2px;
    font-weight: 700;
  }
  > span:nth-child(2) {
    font-weight: bold;
  }
  margin-block: ${(props) => (props.marginTop ? '' : `1px`)};

  background: ${(props) =>
    props.bg ? props.bg : props.theme.palette.baseBlack};
`;

export const CircularIconWrapper = styled.div<{
  top: string;
}>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : '')};
  left: 50%;
`;

export const TableAccordionHeadCell = styled.div<{
  bgColor: string;
  color?: string;
}>`
  padding: 0.5rem;
  text-align: center;
  word-break: keep-all;
  width: 5.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.baseBlack};
  border-bottom: 1px solid ${(props) => props.theme.palette.beige};
  background-color: ${(props) => props.bgColor};
`;
